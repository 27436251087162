import { Constants } from "@/enums/Constants";
import { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { DateHelper } from "@/helpers/DateHelper";
import { DatePeriodComparisonType } from "@/enums/DatePeriodComparisonType";

export class ReportingApiController extends ApiControllerCore {
  protected apiEndpoint: string;

  constructor() {
    super();

    this.apiEndpoint = Constants.ApiEndpoints.REPORTING;
  }

  public async getProductsReport(from: Date, to: Date, datePeriodComparisonType: DatePeriodComparisonType): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(
      `${this.apiEndpoint}/products?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}&isComparison=${
        datePeriodComparisonType === DatePeriodComparisonType.WITH_PREVIOUS_PERIOD
      }`
    );
  }

  public async getOrdersReport(from: Date, to: Date, datePeriodComparisonType: DatePeriodComparisonType): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(
      `${this.apiEndpoint}/orders?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}&isComparison=${
        datePeriodComparisonType === DatePeriodComparisonType.WITH_PREVIOUS_PERIOD
      }`
    );
  }

  public async getUsersReport(from: Date, to: Date, datePeriodComparisonType: DatePeriodComparisonType): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(
      `${this.apiEndpoint}/users?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}&isComparison=${
        datePeriodComparisonType === DatePeriodComparisonType.WITH_PREVIOUS_PERIOD
      }`
    );
  }

  public async getSecurityReport(from: Date, to: Date, datePeriodComparisonType: DatePeriodComparisonType): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(
      `${this.apiEndpoint}/security?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}&isComparison=${
        datePeriodComparisonType === DatePeriodComparisonType.WITH_PREVIOUS_PERIOD
      }`
    );
  }
}
