<template>
  <a class="primary-button" :class="{ loading: loadingProp, disabled: disabledProp }" :style="{ backgroundColor: colorProp }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
      preserveAspectRatio="xMidYMid slice"
      class="svg-stripes"
      viewBox="0 0 500 500"
    >
      <g fill="#ffffff" transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,0,0)">
        <rect width="39.78371006591931" height="1414.213562373095" x="39.28371047973633 " y="689.0644226074219 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="117.85112762451172" y="843.9344787597656 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="196.41854858398438" y="1048.4215393066406"></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="274.9859619140625 " y="437.7733459472656 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="353.5533752441406 " y="797.1950378417969 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="432.1208190917969 " y="558.7951354980469 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="510.688232421875  " y="1026.1332092285156"></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="589.2556762695312 " y="363.5896301269531 "></rect>
        <rect width="39.78371006591931" height="1414.213562373095" x="667.8230590820312 " y="586.1991882324219 "></rect>
      </g>
    </svg>

    <div class="text" :style="{ backgroundColor: colorProp }">
      <i class="pi pi-spin pi-spinner"></i>
      <span>{{ labelProp }}</span>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, toRef } from "vue";

/**
 * Fancy FF button component.
 */
export default defineComponent({
  name: "FFButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const labelProp = toRef(props, "label");
    const loadingProp = toRef(props, "loading");
    const disabledProp = toRef(props, "disabled");
    const colorProp = toRef(props, "color");

    return {
      labelProp,
      loadingProp,
      disabledProp,
      colorProp,
    };
  },
});
</script>

<style scoped lang="scss">
.primary-button {
  position: relative;
  background-color: $ff-primary-red;
  border: 0;
  color: $ff-primary-white;
  padding: 15px 20px;
  font-family: "TradeGothicNext", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    &:not(&.disabled) svg rect {
      transform: translateY(-1414.213562373095px);
    }
  }

  &.loading {
    .text {
      span {
        padding-right: 0px;
        margin-left: 0px;
      }

      i {
        opacity: 1;
      }
    }
  }

  .text {
    padding: 5px;
    background: var(--primary-theme-color);
    z-index: 1;
    width: 100%;

    span {
      transition: padding 200ms ease, margin 200ms ease;

      padding-right: 10px;
      margin-left: -10px;
    }
  }

  i {
    opacity: 0;
    margin-right: 10px;
  }

  svg {
    position: absolute;
    overflow: visible;
    transform: scale(70%);
    width: 100%;
    height: 100%;

    rect {
      transition: transform 500ms ease;

      transform: translateY(0px);
    }
  }
}
</style>
