import { UserDto } from "@/dtos/UserDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { UserModel } from "@/models/UserModel";
import { OrderListViewModel, UserListViewModel, UserViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";

export class UserApiController extends BaseApiController<UserModel, UserDto, UserViewModel, UserListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.USERS);
  }

  public getOrdersByUserId(userId: number): Promise<AxiosResponse<OrderListViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/${userId}/orders`);
  }
}
