import { CouponDto } from "@/dtos/CouponDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { CouponModel } from "@/models/CouponModel";
import { CouponListViewModel, CouponViewModel } from "@/viewModels/ViewModels";

export class CouponApiController extends BaseApiController<CouponModel, CouponDto, CouponViewModel, CouponListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.COUPONS);
  }
}
