import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { CouponModel } from "./CouponModel";
import { OrderProductModel } from "./OrderProductModel";
import { UserModel } from "./UserModel";
import { IOrderModel } from "./interfaces/IOrderModel";
import { TypeHelper } from "@/helpers/TypeHelper";
import { DeliveryStatus } from "@/enums/DeliveryStatus";

export class OrderModel extends BaseModel implements IOrderModel {
  public userId: number;
  public couponId?: number | null;
  public orderDate: Date;
  public orderProducts: OrderProductModel[];
  public orderNumber: string | null;
  public isCanceled: boolean;
  public cancelationDate?: Date | null;
  public receivedSachets: number;
  public deliveryStatus: DeliveryStatus;
  public trackingNumber?: string | null;
  public notes?: string | null;
  public shippingDate?: Date | null;
  public isMigrated: boolean;
  public migrationId?: number | null;
  public migrationDate?: Date | null;

  // used for creation.
  public hasNewsletter: boolean;

  public user: UserModel;
  public coupon: CouponModel | null;

  constructor() {
    super();

    this.userId = -1;
    this.couponId = null;
    this.orderDate = new Date();
    this.orderProducts = [];
    this.orderNumber = null;
    this.isCanceled = false;
    this.cancelationDate = null;
    this.receivedSachets = 0;
    this.deliveryStatus = DeliveryStatus.NONE;
    this.trackingNumber = null;
    this.notes = null;
    this.hasNewsletter = false;
    this.shippingDate = null;
    this.isMigrated = false;
    this.migrationId = null;
    this.migrationDate = null;

    this.user = new UserModel();
    this.coupon = null;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "orderDate",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
    {
      key: "shippingDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "cancelationDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "migrationDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "user",
      cast: (x) => TypeHelper.mappers.UserModel(x),
    },
    {
      key: "coupon",
      cast: (x) => TypeHelper.mappers.CouponModel(x),
    },
    {
      key: "orderProducts",
      cast: (x) => x.map((y: OrderProductModel) => TypeHelper.mappers.OrderProductModel(y)),
    },
  ];
}
