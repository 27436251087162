<template>
  <div class="inner">
    <div v-if="isValidOrderConfirmed">
      <h2><Translate text="STOREFRONT_ORDERCONFIRMED" /></h2>
      <p><Translate text="STOREFRONT_ORDERCONFIRMED_THANKYOU" /></p>
      <p>
        <Translate text="STOREFRONT_ORDERCONFIRMED_ORDER_NUMBER" />
        <span
          ><router-link :to="`/profile/orders/${orderId}`" class="clickable">{{ orderReference }}</router-link></span
        >
      </p>
      <p><Translate text="STOREFRONT_ORDERCONFIRMED_DESCRIPTION" /></p>

      <Message class="info" severity="info" :closable="false"><Translate text="STOREFRONT_ORDERCONFIRMED_ORDER_NUMBER_HINT" /></Message>

      <div class="checkout-buttons">
        <FFButton @click="goToLandingPage()" :label="Translator.translate('STOREFRONT_ORDERCONFIRMED_BACK_TO_LANDINGPAGE')" color="var(--ff-primary-black)"></FFButton>
      </div>
    </div>
    <div v-else>
      <p><Translate text="STOREFRONT_ORDERCONFIRMED_NOTHING_ORDERED" /></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductCartEntry from "@/components/storefront/landingpage/products/ProductCartEntry.vue";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { useOrderStore } from "@/stores/orderStore";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontOrderConfirmed",
  components: {
    ProductCartEntry,
    ProductPrice,
    FFButton,
    Translate,
  },
  methods: {
    goToLandingPage(): void {
      this.$router.push("/");
    },
  },
  setup() {
    const orderStore = useOrderStore();

    const orderReference = orderStore.orderReference;
    const orderId = orderStore.orderId;
    const isValidOrderConfirmed = orderReference !== null;

    // if the order is valid, render the full order-completed page, but reset the orderReference after.
    if (isValidOrderConfirmed) {
      orderStore.orderReference = null;
    }

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_ORDERCONFIRMED")),
    });

    return {
      orderReference,
      orderId,
      isValidOrderConfirmed,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
p {
  span {
    font-weight: bold;
  }
}

.checkout-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
}

:deep(.p-message) {
  margin-top: 30px;

  &.info {
    margin-top: 30px;

    .p-message-text {
      font-weight: bold;
    }
  }

  .p-message-wrapper {
    padding: 10px 20px;
  }
}
</style>
