import { IColorTheme } from "@/dtos/shared/IColorTheme";
import { ExportType } from "./ExportType";

export const Constants = {
  ApiEndpoints: {
    ADDRESSES: "/addresses",
    USERS: "/users",
    USER_PROFILE: "/users/profile",
    USER_STATUS: "/users/status",
    USER_STATUS_CART: "/users/status/cart",
    USER_STATUS_CART_PRODUCTS: "/users/status/cart/products",
    ORDERS: "/orders",
    TRANSLATIONS: "/translations",
    SLIDERS: "/sliders",
    COUNTRIES: "/countries",
    LOG_ENTRIES: "/logentries",
    CONTACT_FORM_ENTRIES: "/contactformentries",
    COUPONS: "/coupons",
    PRODUCTS: "/products",
    PRODUCTCATEGORIES: "/productcategories",
    SIMILARPRODUCTS: "/similarproducts",
    REPORTING: "/reporting",
  },
  StatusCodes: {
    SUCCESS: 200,
  },
  MimeTypes: [
    {
      key: ExportType.PDF,
      mimeType: "application/pdf",
      defaultFileEnding: "pdf",
    },
    {
      key: ExportType.EXCEL,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      defaultFileEnding: "xlsx",
    },
  ],
  ColorThemes: [
    {
      name: "eucalyptus",
      packageColor: "var(--ff-blue-stripe-package)",
      labelColor: "var(--ff-blue-stripe-label)",
      textColor: "black",
    },
    {
      name: "mint",
      packageColor: "var(--ff-mint-package)",
      labelColor: "var(--ff-mint-label)",
      textColor: "black",
    },
    {
      name: "cinnamon",
      packageColor: "var(--ff-cinnamon-stripe-package)",
      labelColor: "var(--ff-cinnamon-stripe-label)",
      textColor: "black",
    },
    {
      name: "cherry",
      packageColor: "var(--ff-cherry-stripe-package)",
      labelColor: "var(--ff-cherry-stripe-label)",
      textColor: "white",
    },
    {
      name: "citrus",
      packageColor: "var(--ff-citrus-stripe-package)",
      labelColor: "var(--ff-citrus-stripe-label)",
      textColor: "black",
    },
    {
      name: "cassis",
      packageColor: "var(--ff-purple-stripe-package)",
      labelColor: "var(--ff-purple-stripe-label)",
      textColor: "white",
    },
    {
      name: "black",
      packageColor: "var(--ff-black-stripe-package)",
      labelColor: "var(--ff-black-stripe-label)",
      textColor: "white",
    },
    {
      name: "red",
      packageColor: "var(--ff-primary-red)",
      labelColor: "var(--ff-fisherman-s-friend-red-70)",
      textColor: "white",
    },
    {
      name: "spearmint",
      packageColor: "var(--ff-spearmint-stripe-package)",
      labelColor: "var(--ff-spearmint-stripe-label)",
      textColor: "white",
    },
    {
      name: "honey-lemon",
      packageColor: "var(--ff-honey-stripe-package)",
      labelColor: "var(--ff-honey-stripe-label)",
      textColor: "black",
    },
    {
      name: "raspberry",
      packageColor: "var(--ff-raspberry-stripe-package)",
      labelColor: "var(--ff-raspberry-stripe-label)",
      textColor: "white",
    },
  ] as IColorTheme[],
  Breakpoints: {
    mobile: 860,
    tablet: 1440,
    desktop: 1920,
  },
  LocalStorage: {
    NecessaryCookiesAccepted: "necessaryCookiesAccepted",
    OptionalCookiesAccepted: "optionalCookiesAccepted",
  },
};
