<template>
  <div id="storefront">
    <StoreFrontNavigation></StoreFrontNavigation>

    <div class="content" :class="{ 'is-admin': isAdmin }">
      <router-view></router-view>
    </div>

    <StoreFrontFooter></StoreFrontFooter>

    <StoreFrontCookieBanner></StoreFrontCookieBanner>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import { Translator } from "@/helpers/Translator";
import StoreFrontNavigation from "@/components/storefront/StoreFrontNavigation.vue";
import StoreFrontFooter from "@/components/storefront/StoreFrontFooter.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import FFProgressSpinner from "@/components/storefront/FFProgressSpinner.vue";
import StoreFrontCookieBanner from "@/components/storefront/StoreFrontCookieBanner.vue";

export default defineComponent({
  name: "StoreFront",
  components: {
    StoreFrontNavigation,
    StoreFrontFooter,
    StoreFrontCookieBanner,
    FFProgressSpinner,
  },
  setup() {
    const userStatusStore = useUserStatusStore();
    const isAdmin = computed(() => userStatusStore.isAdmin);

    useHead({
      title: () => Translator.translate("STOREFRONT_TITLE"),
      meta: () => [
        {
          name: "description",
          content: Translator.translate("STOREFRONT_DESCRIPTION"),
        },
        {
          property: "og:description",
          content: Translator.translate("STOREFRONT_DESCRIPTION"),
        },
        {
          property: "twitter:description",
          content: Translator.translate("STOREFRONT_DESCRIPTION"),
        },
      ],
    });

    return {
      isAdmin,
    };
  },
});
</script>

<style lang="scss">
.breadcrumb {
  text-transform: lowercase;
  font-variant: small-caps;
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: 40px;
  text-decoration: none !important;
  cursor: pointer;

  &:hover {
    span {
      text-decoration: underline;
    }
    i {
      text-decoration: none;
    }
  }
}

.p-toast {
  button {
    margin-top: 10px;
  }
}

.content {
  padding-top: 305px /* 50px header + 50px header + 190px header + 15px margin */;
  padding-bottom: 40px;
  font-size: 1.2rem;

  &.is-admin {
    padding-top: 355px /* + extra 50px header */;
  }

  @media (max-width: $breakpoint-tablet) {
    padding-top: 390px /* 50px header + 50px header + 275px header + 15px margin */;

    &.is-admin {
      padding-top: 390px;
    }
  }

  .inner {
    position: relative;
    padding: 100px 15%;

    @media (max-width: $breakpoint-mobile) {
      padding: 50px 20px 100px 20px;
    }

    form {
      .form-row {
        width: 100%;
      }
    }
  }

  .p-datatable-tbody {
    tr {
      &:hover {
        background: $ff-storefront-light;
      }
    }
  }
}

h1,
h2,
h3 {
  font-family: "GoodPro", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 4rem;
  line-height: calc(3.6rem);

  @media (max-width: $breakpoint-mobile) {
    font-size: 3rem;
  }
}

.link,
a {
  text-decoration: none;
  color: $ff-primary-black;

  &:hover {
    text-decoration: underline;
  }
}
</style>
