<template>
  <div class="inner">
    <div class="products" v-if="!productsDto.isLoading">
      <template v-for="(product, index) of productsDto.list" :key="product.model.id">
        <ProductDisplay :dto="product" :is-scroll-animation="index > 0"></ProductDisplay>
      </template>
      <p v-if="productsDto.list.length === 0"><Translate text="STOREFRONT_PRODUCTS_NO_PRODUCTS_AVAILABLE" /></p>
    </div>
    <FFProgressSpinner v-else></FFProgressSpinner>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import HomeSlider from "@/components/storefront/landingpage/HomeSlider.vue";
import { useProductStore } from "@/stores/productStore";
import { ProductListDto } from "@/dtos/ProductDtos";
import HighlightProductReel from "@/components/storefront/landingpage/HighlightProductReel.vue";
import ProductDisplay from "@/components/storefront/landingpage/products/ProductDisplay.vue";
import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";
import routerMixins from "@/mixins/routerMixins";
import Translate from "@/components/shared/Translate.vue";
import FFProgressSpinner from "@/components/storefront/FFProgressSpinner.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";
import { Translator } from "@/helpers/Translator";

export default defineComponent({
  name: "StoreFrontProducts",
  components: {
    HomeSlider,
    HighlightProductReel,
    ProductDisplay,
    Translate,
    FFProgressSpinner,
  },
  setup() {
    const productStore = useProductStore();
    const route = useRoute();

    const productsDto = ref(new ProductListDto([]));

    const fetchActiveProducts = (to: RouteLocationNormalizedLoaded, isInitialLoad: boolean) => {
      // before reloading products, stop image requests (careful: this stops all requests!).
      if (!isInitialLoad && window.stop !== undefined) {
        window.stop();
      }

      // reload products.
      if (to.name === "StoreFront Products") {
        let categoryId = null;
        categoryId = routerMixins.tryGetQuery(to.query.category, () => {});
        if (isNaN(categoryId)) {
          categoryId = null;
        }

        productStore.getActiveProducts(productsDto.value, categoryId);
      }
    };

    // load products when route changes on same page (clicking on a product category filter in navigation).
    watch(route, (to) => fetchActiveProducts(to, false));

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_NAVIGATION_ALL_PRODUCTS")),
    });

    // make initial fetch.
    onMounted(() => {
      fetchActiveProducts(route, true);
    });

    return {
      productsDto,
    };
  },
});
</script>

<style scoped lang="scss">
.products {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 100px;
  column-gap: 75px;

  > div {
    width: calc(33.3% - 50px);

    @media (max-width: $breakpoint-tablet) {
      width: calc(50% - 38px); /*37.5px rounded up */
    }
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}
</style>
