<template>
  <div class="title-panel">
    <h2>Produkt erstellen</h2>
  </div>

  <ProductsCreateEdit :dto="dto"></ProductsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ProductDto } from "@/dtos/ProductDtos";
import { ProductModel } from "@/models/ProductModel";
import ProductsCreateEdit from "@/components/controlpanel/products/ProductsCreateEdit.vue";

/**
 * View that renders the the "create" form for products.
 */
export default defineComponent({
  name: "ProductsCreate",
  setup() {
    // create empty instance.
    const dto = ref(new ProductDto(new ProductModel()));

    // set id so it's not undefined.
    dto.value.model.id = -1;

    return {
      dto,
    };
  },
  components: { ProductsCreateEdit },
});
</script>

<style scoped lang="scss"></style>
