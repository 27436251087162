import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createVNode(_component_Translate, { text: "STOREFRONT_PRIVACYPROTECTION_PAGETITLE" })
    ]),
    _createVNode(_component_Translate, { text: "STOREFRONT_PRIVACYPROTECTION_PAGECONTENT" })
  ]))
}