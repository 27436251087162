import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { ContactFormEntryListViewModel, ContactFormEntryViewModel } from "@/viewModels/ViewModels";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";

export class ContactFormEntryApiController extends BaseApiController<ContactFormEntryModel, ContactFormEntryDto, ContactFormEntryViewModel, ContactFormEntryListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.CONTACT_FORM_ENTRIES);
  }
}
