import { AddressType } from "@/enums/AddressType";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { LogEntryType } from "@/enums/LogEntryType";
import { LoginReturnCode } from "@/enums/LoginReturnCode";
import { RegisterReturnCode } from "@/enums/RegisterReturnCode";
import { ReturnCode } from "@/enums/ReturnCode";
import { SortDirection } from "@/enums/SortDirection";
import { StatusCode } from "@/enums/StatusCode";

export class DropdownOption<T> {
  public value: T;
  public text: string;

  constructor(value: T, text: string) {
    this.value = value;
    this.text = text;
  }
}

/**
 * Used for helping with enum keys and values.
 */
export const EnumHelper = {
  /* -------------------------------------------
  Gets a StatusCode enum value by string key.
  ------------------------------------------- */
  getStatusCode(enumKey: any): StatusCode {
    return StatusCode[enumKey as unknown as keyof typeof StatusCode];
  },

  /* -------------------------------------------
  Gets a SortDirection enum value by string key.
  ------------------------------------------- */
  getSortDirection(enumKey: any): SortDirection {
    return SortDirection[enumKey as unknown as keyof typeof SortDirection];
  },

  /* -------------------------------------------
  Gets a ReturnCode enum value by string key.
  ------------------------------------------- */
  getReturnCode(enumKey: any): ReturnCode {
    return ReturnCode[enumKey as unknown as keyof typeof ReturnCode];
  },

  /* -------------------------------------------
  Gets a RegisterReturnCode enum value by string key.
  ------------------------------------------- */
  getRegisterReturnCode(enumKey: any): RegisterReturnCode {
    return RegisterReturnCode[enumKey as unknown as keyof typeof RegisterReturnCode];
  },

  /* -------------------------------------------
  Gets a LoginReturnCode enum value by string key.
  ------------------------------------------- */
  getLoginReturnCode(enumKey: any): LoginReturnCode {
    return LoginReturnCode[enumKey as unknown as keyof typeof LoginReturnCode];
  },

  /* -------------------------------------------
  Gets a LoginReturnCode enum value by string key.
  ------------------------------------------- */
  createDropdownOptionsFromEnum<T>(enumType: any): DropdownOption<T>[] {
    return Object.values(enumType)
      .filter((value) => typeof value === "number")
      .map((value) => value as T)
      .map((value) => new DropdownOption(value, enumType[value]));
  },

  /* -------------------------------------------
  Assemble the dropdown options for the DeliveryStatus enum.
  ------------------------------------------- */
  createDropdownOptionsForDeliveryStatus(): DropdownOption<DeliveryStatus>[] {
    const deliveryStatusOptions = [
      {
        text: "kein Filter",
        value: DeliveryStatus.NONE,
      },
      {
        text: "In Bearbeitung",
        value: DeliveryStatus.UNPROCESSED,
      },
      {
        text: "Teilweise versendet",
        value: DeliveryStatus.PARTIALLY_DELIVERED,
      },
      {
        text: "Versendet",
        value: DeliveryStatus.DELIVERED,
      },
      {
        text: "Storniert",
        value: DeliveryStatus.CANCELED,
      },
    ] as DropdownOption<DeliveryStatus>[];

    return deliveryStatusOptions;
  },

  /* -------------------------------------------
  Assemble the dropdown options for the AddressType enum.
  ------------------------------------------- */
  createDropdownOptionsForAddressType(): DropdownOption<AddressType>[] {
    const deliveryStatusOptions = [
      {
        text: "kein Filter",
        value: AddressType.NONE,
      },
      {
        text: "Lieferadresse",
        value: AddressType.SHIPPING,
      },
      {
        text: "Rechnungsadresse",
        value: AddressType.BILLING,
      },
    ] as DropdownOption<AddressType>[];

    return deliveryStatusOptions;
  },
};
