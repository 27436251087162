<template>
  <div class="inner">
    <router-link to="/profile" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_PROFILE" /></span>
    </router-link>

    <h2><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD" /></h2>
    <div v-if="form.isReady()">
      <form @submit.prevent="" @change="validator.validateForm(form as ChangePasswordFormData, dto)">
        <Panel v-if="isChangedSuccessfully" :header="Translator.translate('STOREFRONT_FORMS_CHANGEPASSWORD_HEADER')">
          <Message severity="success" :closable="false">
            <p>
              <strong><Translate text="STOREFRONT_MESSAGES_CONTACTFORM_SUCCESS_TITLE" /></strong>
            </p>
            <Translate text="STOREFRONT_MESSAGES_CONTACTFORM_SUCCESS_DESCRIPTION" />
          </Message>
        </Panel>
        <Panel v-else :header="Translator.translate('STOREFRONT_FORMS_CHANGEPASSWORD_HEADER')">
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card"></i>
              <Password
                type="password"
                id="oldPassword"
                :disabled="dto.isLoading"
                v-model="form.oldPassword"
                v-on:keyup.enter="onSave()"
                :feedback="false"
                toggleMask
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'oldPassword') }"
              />
              <label for="oldPassword"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_OLDPASSWORD" /></label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'oldPassword')"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_OLDPASSWORD_VALIDATION" /></small>
          </div>
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card"></i>
              <Password
                type="password"
                id="newPassword1"
                :disabled="dto.isLoading"
                v-model="form.newPassword1"
                v-on:keyup.enter="onSave()"
                :feedback="false"
                toggleMask
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'newPassword1') }"
              />
              <label for="newPassword1"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_NEWPASSWORD" /></label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'newPassword1')"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_NEWPASSWORD_VALIDATION" /></small>
          </div>
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card"></i>
              <Password
                type="password"
                id="newPassword2"
                :disabled="dto.isLoading"
                v-model="form.newPassword2"
                v-on:keyup.enter="onSave()"
                :feedback="false"
                toggleMask
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'newPassword2') }"
              />
              <label for="newPassword2"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_NEWPASSWORD_REPEAT" /></label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'newPassword2')"><Translate text="STOREFRONT_FORMS_CHANGEPASSWORD_NEWPASSWORD_REPEAT_VALIDATION" /></small>
          </div>

          <small class="required-explanation">* <Translate text="STOREFRONT_FORMS_REQUIRED" /></small>
        </Panel>

        <div v-if="dto.hasError">
          <Message v-if="dto.hasError" severity="error">
            <p class="m-0">
              <strong><Translate text="STOREFRONT_MESSAGES_CHANGEPASSWORD_FAILED_TITLE" /></strong>
            </p>
            {{ customError || Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_FAILED_DESCRIPTION") }}
          </Message>
        </div>
      </form>

      <div class="button-panel">
        <div></div>
        <div>
          <Button type="button" :label="Translator.translate('STOREFRONT_GENERAL_CANCEL')" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" :label="Translator.translate('STOREFRONT_GENERAL_SAVE')" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
        </div>
      </div>
    </div>

    <div v-else-if="dto.isLoading">
      <LoadingSkeleton></LoadingSkeleton>
    </div>

    <div v-else>
      <Message severity="error"><Translate text="STOREFRONT_MESSAGES_GENERIC_ERROR" /></Message>
      <div>
        <Button type="button" :label="Translator.translate('STOREFRONT_NAVIGATION_BACK_TO_PROFILE')" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { Validator } from "@/helpers/Validator";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import AddressPanel from "@/components/storefront/users/AddressPanel.vue";
import { UserDto } from "@/dtos/UserDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { UserModel } from "@/models/UserModel";
import { ChangePasswordFormData } from "@/dtos/data/ChangePasswordFormData";
import { ChangePasswordData } from "@/dtos/data/ChangePasswordData";
import { UserViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { StatusCode } from "@/enums/StatusCode";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontUserProfileChangePassword",
  components: {
    FFButton,
    LoadingSkeleton,
    AddressPanel,
    DateDisplay,
    Translate,
  },
  methods: {
    async onSave() {
      const userProfileStore = useUserProfileStore();

      this.customError = "";
      this.form.shouldValidate = true;

      // validate before changing password.
      this.validator.validateForm(this.form as ChangePasswordFormData, this.dto).then(() => {
        // send PUT request and show toast if successful.
        userProfileStore
          .changePassword(this.dto, this.form)
          .then(async () => {
            // show success message.
            this.showToast({
              life: 5000,
              severity: "success",
              summary: Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_SUCCESS_TITLE"),
              detail: Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_SUCCESS_DESCRIPTION"),
            });

            this.formHelper.returnToList();
          })
          .catch((error: AxiosResponse<UserViewModel>) => {
            switch (error.data.statusCode) {
              case StatusCode.USER_OLD_PASSWORD_DOES_NOT_MATCH:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_DOES_NOT_MATCH_OLD_PASSWORD");
                break;
              case StatusCode.USER_NEW_PASSWORDS_DO_NOT_MATCH:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_PASSWORDS_DO_NOT_MATCH");
                break;
              case StatusCode.USER_NEW_PASSWORD_DOES_NOT_MEET_COMPLEXITY_RULES:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_CHANGEPASSWORD_COMPLEXITY_NOT_MET");
                break;
            }
          });
      });
    },
  },
  setup() {
    const toast = useToast();
    const userStatusStore = useUserStatusStore();

    const validator = new Validator<ChangePasswordData>();
    const formHelper = new FormHelper("/profile");

    const dto = ref<InstanceType<typeof UserDto>>(ObjectHelper.copyExistingPropsFromTo(userStatusStore.userDto, new UserDto(new UserModel())));

    // set up as form state as reactive object.
    const form = ref(new ChangePasswordFormData(userStatusStore.userDto));

    const isChangedSuccessfully = ref(false);
    const customError = ref("");

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_CHANGEPASSWORD")),
    });

    return {
      dto,
      customError,
      form,
      formHelper,
      validator,
      isChangedSuccessfully,
      Translator,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.button-panel {
  display: flex;
  justify-content: space-between;

  button:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
