<template>
  <ErrorPage v-if="hasErrors"></ErrorPage>
  <UnderConstruction v-else-if="isUnderConstruction"></UnderConstruction>
  <FFProgressSpinner v-else-if="!isStateRestored" style="margin-top: 20%"></FFProgressSpinner>
  <router-view v-else />

  <DebugDisplay></DebugDisplay>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import DebugDisplay from "@/components/shared/DebugDisplay.vue";
import { useTranslationStore } from "@/stores/translationStore";
import { usePrimeVue } from "primevue/config";
import { ColorSetter } from "./helpers/ColorSetter";
import { Language } from "./enums/Language";
import { useUserStatusStore } from "./stores/userStatusStore";
import FFProgressSpinner from "./components/storefront/FFProgressSpinner.vue";
import ErrorPage from "./views/storefront/ErrorPage.vue";
import Translate from "./components/shared/Translate.vue";
import UnderConstruction from "./views/storefront/UnderConstruction.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    DebugDisplay,
    FFProgressSpinner,
    Translate,
    ErrorPage,
    UnderConstruction,
  },
  async created() {
    ColorSetter.setGlobalFishermansFriendColor();
  },
  setup() {
    const route = useRoute();
    const primevue = usePrimeVue();
    const userStatusStore = useUserStatusStore();
    const translationStore = useTranslationStore();

    const changeToGerman = () => {
      // change system language. This is only used for calendars.
      if (primevue.config.locale !== undefined) {
        primevue.config.locale.dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        primevue.config.locale.dayNamesMin = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
        primevue.config.locale.monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        primevue.config.locale.monthNamesShort = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
      }
    };
    const changeToFrench = () => {
      // change system language. This is only used for calendars.
      if (primevue.config.locale !== undefined) {
        primevue.config.locale.dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        primevue.config.locale.dayNamesMin = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];
        primevue.config.locale.monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        primevue.config.locale.monthNamesShort = ["jan.", "fév.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."];
      }
    };

    const setSystemLanguage = () => {
      // select language for system translations.
      switch (translationStore.currentLanguage) {
        case Language.FR:
          changeToFrench();
          break;
        default:
        case Language.DE:
          changeToGerman();
          break;
      }
    };

    // set initial language.
    setSystemLanguage();

    // create watch for current language.,
    watch(() => translationStore.currentLanguage, setSystemLanguage);

    const isLogin = computed(() => route.name === "Login");
    const isStateLoading = computed(() => userStatusStore.userDto.isLoading || translationStore.listDto.isLoading);
    const isStateRestored = computed(() => userStatusStore.isUserStatusRestored && translationStore.isTranslationsRestored);
    const isUnderConstruction = computed(() => !isLogin.value && userStatusStore.isUnderConstruction && !userStatusStore.isAdmin);

    const hasErrors = computed(() => (!userStatusStore.isUserStatusRestored && userStatusStore.userDto.hasError) || (!translationStore.isTranslationsRestored && translationStore.listDto.hasError));

    return {
      setSystemLanguage,
      isStateLoading,
      isStateRestored,
      isUnderConstruction,
      hasErrors,
    };
  },
});
</script>

<style lang="scss">
#webpack-dev-server-client-overlay {
  display: none !important;
}
</style>
