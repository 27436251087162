<template>
  <div ref="productDisplayRef" class="product-display animated" v-animateonscroll.once="{ enterClass: 'custom-fade-in-down' }">
    <div class="display">
      <a @click="goToDetails()">
        <h2>
          {{ model.name() }} <em>{{ model.subname() }}</em>
        </h2>
      </a>
      <a @click="goToDetails()">
        <img
          class="animated animation-delay-100"
          :src="imageListDto.list.length > 0 ? imageListDto.list[0].model.imageData : require('@/assets/img/placeholder.jpg')"
          v-animateonscroll.once="{ enterClass: 'custom-scale-out' }"
        />
      </a>

      <ProductPrice :value="model.price"></ProductPrice>

      <FFButton :label="Translator.translate('STOREFRONT_PRODUCTS_ADD_TO_CART')" @click="addToCart()" :loading="isAddingToCart"></FFButton>

      <a @click="goToDetails()" class="mt-4">
        <h2><Translate text="STOREFRONT_PRODUCTS_WANT_TO_KNOW_MORE" /></h2>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, toRefs, watchEffect } from "vue";
import colorMixins from "@/mixins/colorMixins";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useToast } from "primevue/usetoast";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "../../FFButton.vue";
import { ProductImageListDto } from "@/dtos/ProductDtos";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useProductStore } from "@/stores/productStore";
import { ProductModel } from "@/models/ProductModel";
import { CustomToastMessageOptions } from "@/dtos/data/CustomToastMessageOptions";

/**
 * Component that renders a whole Product in the fancy FF way.
 */
export default defineComponent({
  name: "SimilarProductDisplay",
  props: {
    model: {
      type: ProductModel,
      required: true,
    },
    colorTheme: {
      type: String,
      required: true,
    },
  },
  components: { ProductPrice, FFButton, LoadingSkeleton, Translate },
  methods: {
    addToCart(): void {
      const userStatusStore = useUserStatusStore();

      this.isAddingToCart = true;

      const currentAmount = userStatusStore.getAmountInCart(this.model.id);

      userStatusStore
        .updateProductCartAmount(this.model.id, currentAmount + 1)
        .then(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 3000,
            severity: "success",
            summary: "Produkt hinzugefügt",
            detail: "Das Produkt wurde dem Warenkorb hinzugefügt.",
            hasButton: true,
            buttonAction: () => this.$router.push("/cart"),
            buttonLabel: Translator.translate("STOREFRONT_CHECKOUT_TO_CART"),
          });
        })
        .catch(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 6000,
            severity: "error",
            summary: "Fehlgeschlagen",
            detail: "Das Produkt konnte dem Warenkorb nicht hinzugefügt werden. Versuchen Sie es später erneut.",
          });
        });
    },
    async goToDetails(): Promise<void> {
      await this.$router.push("/products/" + this.model.id);
    },
  },
  setup(props) {
    const productStore = useProductStore();
    const toast = useToast();

    // get entity from props.
    const { model } = toRefs(props);
    const imageListDto = ref(new ProductImageListDto([]));

    // use local loading state, so that each product can be added separately.
    const isAddingToCart = ref(false);

    // set css colors based on selected theme.
    const productDisplayRef = ref(null);
    watchEffect(() => {
      if (productDisplayRef.value && model.value.colorTheme) {
        const productDisplayElement = productDisplayRef.value as HTMLElement;
        productDisplayElement.style.setProperty("--primary-theme-color", colorMixins.getPackageColorFromTheme(props.colorTheme));
        productDisplayElement.style.setProperty("--secondary-theme-color", colorMixins.getLabelColorFromTheme(props.colorTheme));
      }
    });

    const showToast = (toastOptions: CustomToastMessageOptions) => {
      toast.add(toastOptions);
    };

    onMounted(() => {
      productStore.getProductImages(model.value.id, imageListDto.value);
    });

    return {
      model,
      isAddingToCart,
      productDisplayRef,
      showToast,
      Translator,
      imageListDto,
    };
  },
});
</script>

<style scoped lang="scss">
.product-display {
  position: relative;
  width: 100%;

  .display {
    width: 100%;
    background: $ff-primary-white;
    color: $ff-primary-black;
    height: 100%;
    padding: 5%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    a {
      cursor: pointer;
      color: $ff-primary-black;

      &:hover {
        text-decoration: none;
      }
    }

    h2 {
      margin: 10px 0;
    }

    .price {
      margin: 10px 0;
    }

    img {
      width: 90%;
      height: 270px;
      object-fit: contain;
    }

    .primary-button {
      width: 100%;
      color: $ff-primary-white;
      background: var(--primary-theme-color);
    }
  }
}
</style>
