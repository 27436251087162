import { BaseApiController } from "@/api/BaseApiController";
import { ListDto } from "@/dtos/base/ListDto";
import { ModelDto } from "@/dtos/base/ModelDto";
import { BaseModel } from "@/models/BaseModel";
import { ListViewModel } from "@/viewModels/base/ListViewModel";
import { SingleViewModel } from "@/viewModels/base/SingleViewModel";
import { AxiosResponse } from "axios";
import { ObjectHelper } from "./ObjectHelper";
import { TypeMapping } from "../dtos/shared/TypeMapping";
import { ExportType } from "@/enums/ExportType";
import { Constants } from "@/enums/Constants";
import { DownloadHelper } from "./DownloadHelper";

/**
 * A proxy class that wraps a common API class.
 */
export class StoreApiProxy<
  TModel extends BaseModel,
  TDto extends ModelDto<TModel>,
  TListDto extends ListDto<TDto>,
  TViewModel extends SingleViewModel<TModel>,
  TListViewModel extends ListViewModel<TModel>
> {
  protected apiController: BaseApiController<TModel, TDto, TViewModel, TListViewModel>;
  protected listDtoMappings: TypeMapping[];
  protected dtoMappings: TypeMapping[];

  constructor(apiController: BaseApiController<TModel, TDto, TViewModel, TListViewModel>, listDtoMappings: TypeMapping[], dtoMappings: TypeMapping[]) {
    this.apiController = apiController;
    this.listDtoMappings = listDtoMappings;
    this.dtoMappings = dtoMappings;
  }

  /* -------------------------------------------
  Sends a GET request (multiple) of the current api controller.
  ------------------------------------------- */
  public async apiGetMultiple(listDto: TListDto): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .get(listDto)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, this.listDtoMappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Sends a GET request (multiple, active only) of the current api controller.
  ------------------------------------------- */
  public async apiGetMultipleActive(listDto: TListDto): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .getActive()
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, this.listDtoMappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Sends a GET request (single) of the current api controller.
  ------------------------------------------- */
  public async apiGetSingle(dto: TDto, id: number): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .getSingle(id)
        .then((success: AxiosResponse<TViewModel>) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Sends a GET request (single) of the current api controller.
  ------------------------------------------- */
  public async apiGetSingleStatus(dto: TDto, id: number | string): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .getSingleStatus(id)
        .then((success: AxiosResponse<TViewModel>) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Sends a GET request (single, active only) of the current api controller.
  ------------------------------------------- */
  public async apiGetSingleActive(dto: TDto, id: number): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .getSingleActive(id)
        .then((success: AxiosResponse<TViewModel>) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request of the current api controller.
  ------------------------------------------- */
  public async apiUpdate(dto: TDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .update(dto.model.id, dto.model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a POST request of the current api controller.
  ------------------------------------------- */
  public async apiCreate(dto: TDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .create(dto.model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request of the current api controller.
  ------------------------------------------- */
  public async apiDelete(dto: TDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .delete(dto.model.id)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a POST request of the current api controller.
  ------------------------------------------- */
  public async apiExport(listDto: TListDto, exportType: ExportType): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.apiController
        .export(listDto, exportType)
        .then((response) => {
          const mimeType = Constants.MimeTypes.find((e) => e.key === exportType);
          if (mimeType) {
            const blob = new Blob([response.data], { type: mimeType.mimeType });
            const filename = DownloadHelper.getFIlenameFromContentDispositionHeader(response.headers["content-disposition"], mimeType.defaultFileEnding);
            DownloadHelper.downloadContent(blob, filename);
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }
}
