import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IProductModel } from "./interfaces/IProductModel";
import { ProductCategoryModel } from "./ProductCategoryModel";
import { ProductImageModel } from "./ProductImageModel";
import { useTranslationStore } from "@/stores/translationStore";
import { Language } from "@/enums/Language";
import { TypeHelper } from "@/helpers/TypeHelper";

export class ProductModel extends BaseModel implements IProductModel {
  public isActive: boolean;
  public articleNumber: string;
  public nameDe: string;
  public nameFr: string;
  public subnameDe?: string | null;
  public subnameFr?: string | null;
  public logisticsName: string;
  public descriptionDe: string;
  public descriptionFr: string;
  public price: number;
  public stock: number;
  public colorTheme: string;
  public isHighlight: boolean;
  public productImages: ProductImageModel[];
  public productCategories: ProductCategoryModel[];
  public similarProducts: ProductModel[];
  public isMigrated: boolean;
  public migrationId?: number | null;
  public migrationTranslationId?: number | null;
  public migrationDate?: Date | null;

  constructor() {
    super();

    this.isActive = true;
    this.articleNumber = "";
    this.nameDe = "";
    this.nameFr = "";
    this.subnameDe = null;
    this.subnameFr = null;
    this.logisticsName = "";
    this.descriptionDe = "";
    this.descriptionFr = "";
    this.price = 0;
    this.stock = 0;
    this.colorTheme = "";
    this.isHighlight = false;
    this.productImages = [];
    this.productCategories = [];
    this.similarProducts = [];
    this.isMigrated = false;
    this.migrationDate = null;
    this.migrationId = null;
    this.migrationTranslationId = null;
  }

  public fullName(): string {
    const name = this.name();
    const subname = this.subname();
    return subname !== null ? name + " " + subname : name;
  }
  public name(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.nameDe : this.nameFr;
  }
  public subname(): string | null | undefined {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.subnameDe : this.subnameFr;
  }
  public description(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.descriptionDe : this.descriptionFr;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "productImages",
      cast: (x) => x.map((y: ProductImageModel) => TypeHelper.mappers.ProductImageModel(y)),
    },
    {
      key: "similarProducts",
      cast: (x) => x.map((y: ProductModel) => TypeHelper.mappers.ProductModel(y)),
    },
    {
      key: "migrationDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
  ];
}
