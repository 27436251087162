import { ModelDto } from "./base/ModelDto";
import { SliderModel } from "@/models/SliderModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class SliderDto extends ModelDto<SliderModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.SliderModel(x),
    },
  ];
}
class SliderListDto extends ListDto<SliderDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.SliderDto(y)),
    },
  ];
}

export { SliderDto, SliderListDto };
