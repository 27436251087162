import { OrderDto, OrderProductDto } from "@/dtos/OrderDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { OrderModel } from "@/models/OrderModel";
import { OrderListViewModel, OrderViewModel } from "@/viewModels/ViewModels";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { OrderProductModel } from "@/models/OrderProductModel";
import Axios, { AxiosResponse } from "axios";
import { ExportType } from "@/enums/ExportType";
import { DownloadHelper } from "@/helpers/DownloadHelper";

export class OrderApiController extends BaseApiController<OrderModel, OrderDto, OrderViewModel, OrderListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.ORDERS);
  }

  /* -------------------------------------------
    Update an order product amount delivered.
    ------------------------------------------- */
  public updateOrderProduct(orderId: number, dto: OrderProductDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return super
        ._internalPut(`${this.apiEndpoint}/${orderId}/orderproducts/${dto.model.id}`, dto.model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, OrderProductModel.mappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
    Send the "sachets received" email to the user.
    ------------------------------------------- */
  public sendSachetsReceivedMail(dto: OrderDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return super
        ._internalGet(`${this.apiEndpoint}/${dto.model.id}/deliverynotification`)
        .then((success) => resolve())
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
    Update an order product amount delivered.
    ------------------------------------------- */
  public exportReceipt(orderId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return Axios.get(`${this.apiEndpoint}/${orderId}/receipt`, {
        responseType: "arraybuffer",
      })
        .then((response: AxiosResponse<Blob>) => {
          const mimeType = Constants.MimeTypes.find((e) => e.key === ExportType.PDF);
          if (mimeType) {
            const blob = new Blob([response.data], { type: mimeType.mimeType });
            const filename = DownloadHelper.getFIlenameFromContentDispositionHeader(response.headers["content-disposition"], mimeType.defaultFileEnding);
            DownloadHelper.downloadContent(blob, filename);
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
