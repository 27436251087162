<template>
  <template v-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </template>
  <template v-else>
    <div
      ref="productDisplayRef"
      class="product-display animated"
      v-animateonscroll.once="{ enterClass: isScrollAnimation ? 'custom-fade-in-down' : '' }"
      :class="{ 'out-of-stock': isOutOfStock, 'custom-fade-in-down': !isScrollAnimation }"
    >
      <div v-if="isOutOfStock" class="out-of-stock-display-inner">
        <span class="out-of-stock-banner"><Translate text="STOREFRONT_PRODUCTS_SOLD_OUT" /></span>
      </div>
      <div class="product-display-inner">
        <div class="display">
          <div class="stripes background-stripes"></div>
          <a @click="goToDetails()">
            <h2>
              {{ dto.model.name() }} <em>{{ dto.model.subname() }}</em>
            </h2>
          </a>
          <a @click="goToDetails()">
            <img
              class="animated animation-delay-100"
              :src="imageListDto.list.length > 0 ? imageListDto.list[0].model.imageData : require('@/assets/img/placeholder.jpg')"
              v-animateonscroll.once="{ enterClass: 'custom-scale-out' }"
            />
          </a>

          <ProductPrice :value="dto.model.price"></ProductPrice>

          <FFButton
            :label="Translator.translate(isOutOfStock ? 'STOREFRONT_PRODUCTS_OUT_OF_STOCK' : 'STOREFRONT_PRODUCTS_ADD_TO_CART')"
            @click="addToCart()"
            :loading="dto.isLoading || isAddingToCart"
            :disabled="isOutOfStock"
          ></FFButton>

          <a @click="goToDetails()" class="mt-4">
            <h2><Translate text="STOREFRONT_PRODUCTS_WANT_TO_KNOW_MORE" /></h2>
          </a>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, watchEffect } from "vue";
import colorMixins from "@/mixins/colorMixins";
import { ProductDto, ProductImageListDto } from "@/dtos/ProductDtos";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useToast } from "primevue/usetoast";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "../../FFButton.vue";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useProductStore } from "@/stores/productStore";
import { CustomToastMessageOptions } from "@/dtos/data/CustomToastMessageOptions";

/**
 * Component that renders a whole Product in the fancy FF way.
 */
export default defineComponent({
  name: "ProductDisplay",
  props: {
    dto: {
      type: ProductDto,
      required: true,
    },
    isScrollAnimation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: { LoadingSkeleton, ProductPrice, FFButton, Translate },
  methods: {
    addToCart() {
      const userStatusStore = useUserStatusStore();

      this.isAddingToCart = true;

      const currentAmount = userStatusStore.getAmountInCart(this.dto.model.id);

      userStatusStore
        .updateProductCartAmount(this.dto.model.id, currentAmount + 1)
        .then(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 3000,
            severity: "success",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_DESCRIPTION"),
            hasButton: true,
            buttonAction: () => this.$router.push("/cart"),
            buttonLabel: Translator.translate("STOREFRONT_CHECKOUT_TO_CART"),
          });
        })
        .catch(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 6000,
            severity: "error",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_DESCRIPTION"),
          });
        });
    },

    goToDetails() {
      this.$router.push("/products/" + this.dto.model.id);
    },
  },
  setup(props) {
    const productStore = useProductStore();
    const toast = useToast();

    // get entity from props.
    const { dto } = toRefs(props);
    const imageListDto = ref(new ProductImageListDto([]));

    // use local loading state, so that each product can be added separately.
    const isAddingToCart = ref(false);

    // set css colors based on selected theme.
    const productDisplayRef = ref(null);
    watchEffect(() => {
      if (productDisplayRef.value && dto.value.model.colorTheme) {
        const productDisplayElement = productDisplayRef.value as HTMLElement;
        productDisplayElement.style.setProperty("--primary-theme-color", colorMixins.getPackageColorFromTheme(dto.value.model.colorTheme));
        productDisplayElement.style.setProperty("--secondary-theme-color", colorMixins.getLabelColorFromTheme(dto.value.model.colorTheme));
      }
    });

    const showToast = (toastOptions: CustomToastMessageOptions) => {
      toast.add(toastOptions);
    };

    const isOutOfStock = computed(() => dto.value.model.stock <= 0);

    onMounted(() => {
      productStore.getProductImages(dto.value.model.id, imageListDto.value);
    });

    return {
      dto,
      isAddingToCart,
      productDisplayRef,
      isOutOfStock,
      showToast,
      Translator,
      imageListDto,
    };
  },
});
</script>

<style scoped lang="scss">
.product-display {
  position: relative;
  width: 100%;

  .out-of-stock-display-inner {
    position: relative;
    --offset: 10%;

    @media (max-width: $breakpoint-mobile) {
      --offset: 5%;
    }

    margin-top: var(--offset);
    margin-left: var(--offset);
    width: calc(100% - var(--offset));
    overflow: hidden;
    height: 100%;
    position: absolute;

    .out-of-stock-banner {
      z-index: 1;
      background: $ff-primary-red;
      padding: 10px 0;
      text-align: center;
      position: absolute;
      color: $ff-primary-white;
      width: 84%; // trust me :D
      transform: translate(50%, -100%) rotate(45deg);
      transform-origin: 0 100%;
    }
  }

  &.out-of-stock {
    .product-display-inner {
      opacity: 0.4;
    }
  }

  .product-display-inner {
    width: 100%;
    height: 100%;

    .background-stripes {
      background-color: var(--primary-theme-color);
    }

    .display {
      --offset: 10%;

      @media (max-width: $breakpoint-mobile) {
        --offset: 5%;
      }

      margin-top: var(--offset);
      margin-left: var(--offset);
      width: calc(100% - var(--offset));
      background: var(--secondary-theme-color);
      color: $ff-primary-white;
      height: 100%;
      padding: 5%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;

      a {
        cursor: pointer;
        color: $ff-primary-white;

        &:hover {
          text-decoration: none;
        }
      }

      h2 {
        margin: 10px 0;
      }

      .price {
        margin: 10px 0;
      }

      img {
        width: 90%;
        height: 270px;
        object-fit: contain;
      }

      .primary-button {
        width: 100%;
        background: var(--primary-theme-color);
      }
    }
  }
}
</style>
