import { SliderDto } from "@/dtos/SliderDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { SliderModel } from "@/models/SliderModel";
import { SliderListViewModel, SliderViewModel } from "@/viewModels/ViewModels";

export class SliderApiController extends BaseApiController<SliderModel, SliderDto, SliderViewModel, SliderListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.SLIDERS);
  }
}
