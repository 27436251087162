<template>
  <router-link to="/login" class="breadcrumb">
    <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_LOGIN" /></span>
  </router-link>

  <h2><Translate text="STOREFRONT_FORMS_REGISTER" /></h2>

  <form v-if="isRegistrationStartedSuccessfully" @submit.prevent="" @change="validator.validateForm(form as RegisterFormData, dto)">
    <Message severity="success" :closable="false"><Translate text="STOREFRONT_MESSAGES_REGISTER_SUCCESS" /></Message>

    <FFButton :label="Translator.translate('STOREFRONT_FORMS_REGISTER_TO_LOGIN')" @click="goToLogin()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>
  </form>

  <form v-else @submit.prevent="" @change="validator.validateForm(form as RegisterFormData, dto)">
    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <InputText
          type="text"
          id="username"
          name="username"
          autocomplete="username"
          :disabled="dto.isLoading"
          v-model="form.username"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'username') }"
        />
        <label for="username"><Translate text="STOREFRONT_FORMS_REGISTER_USERNAME" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'username')"><Translate text="STOREFRONT_FORMS_REGISTER_USERNAME_VALIDATION" /></small>
    </div>

    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <InputText
          type="text"
          id="displayName"
          name="displayName"
          autocomplete="name"
          :disabled="dto.isLoading"
          v-model="form.displayName"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'displayName') }"
        />
        <label for="displayName"><Translate text="STOREFRONT_FORMS_REGISTER_DISPLAYNAME" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'displayName')"><Translate text="STOREFRONT_FORMS_REGISTER_DISPLAYNAME_VALIDATION" /></small>
    </div>

    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-lock"></i>
        <Password
          type="password"
          id="password"
          name="password"
          :inputProps="{ autocomplete: 'new-password' }"
          :disabled="dto.isLoading"
          v-model="form.password"
          :feedback="false"
          toggleMask
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'password') }"
        />
        <label for="password"><Translate text="STOREFRONT_FORMS_REGISTER_PASSWORD" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'password')"><Translate text="STOREFRONT_FORMS_REGISTER_PASSWORD_VALIDATION" /></small>
    </div>

    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-lock"></i>
        <Password
          type="password"
          id="passwordRepeat"
          name="passwordRepeat"
          :inputProps="{ autocomplete: 'new-password' }"
          :disabled="dto.isLoading"
          v-model="form.passwordRepeat"
          v-on:keyup.enter="register()"
          :feedback="false"
          toggleMask
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'passwordRepeat') }"
        />
        <label for="passwordRepeat"><Translate text="STOREFRONT_FORMS_REGISTER_PASSWORD_REPEAT" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'passwordRepeat')"><Translate text="STOREFRONT_FORMS_REGISTER_PASSWORD_REPEAT_VALIDATION" /></small>
    </div>

    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <Checkbox id="hasNewsletter" :disabled="dto.isLoading" v-model="form.hasNewsletter" :binary="true" />
        <label for="hasNewsletter"><Translate text="STOREFRONT_FORMS_REGISTER_NEWSLETTER" /></label>
      </span>
    </div>

    <FFButton label="Registrieren" @click="register()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>

    <Message v-if="dto.hasError" severity="error">{{ customError || Translator.translate("STOREFRONT_MESSAGES_GENERIC_ERROR") }}</Message>
  </form>
</template>

<script lang="ts">
import { UserDto } from "@/dtos/UserDtos";
import { RegisterData } from "@/dtos/data/RegisterData";
import { RegisterFormData } from "@/dtos/data/RegisterFormData";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Validator } from "@/helpers/Validator";
import { UserModel } from "@/models/UserModel";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { defineComponent, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { useTranslationStore } from "@/stores/translationStore";
import { AxiosResponse } from "axios";
import { RegisterViewModel } from "@/viewModels/RegisterViewModel";
import { RegisterReturnCode } from "@/enums/RegisterReturnCode";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

/**
 * The shared register panel component.
 */
export default defineComponent({
  name: "Register",
  components: { FFButton, Translate },
  methods: {
    async register(): Promise<void> {
      const userStatusStore = useUserStatusStore();

      this.form.shouldValidate = true;

      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as RegisterFormData, this.dto).then((success) => {
        userStatusStore
          .register(this.dto, this.form)
          .then(() => {
            this.isRegistrationStartedSuccessfully = true;
          })
          .catch((error: AxiosResponse<RegisterViewModel>) => {
            switch (error.data.registerReturnCode) {
              case RegisterReturnCode.INVALID_VALUES:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTER_INVALID_VALUES");
                break;
              case RegisterReturnCode.USERNAME_ALREADY_EXISTS:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTER_USERNAME_ALREADY_EXISTS");
                break;
              case RegisterReturnCode.ALREADY_LOGGED_IN:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTER_ALREADY_LOGGED_IN");
                break;
              case RegisterReturnCode.PASSWORDS_DO_NOT_MATCH:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTER_PASSWORDS_DO_NOT_MATCH");
                break;
              case RegisterReturnCode.PASSWORD_DOES_NOT_MEET_COMPLEXITY_RULES:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTER_PASSWORDS_COMPLEXITY_NOT_MET");
                break;
            }
          });
      });
    },

    goToLogin(): void {
      this.$router.push("/login");
    },
  },
  setup() {
    const translationStore = useTranslationStore();
    const validator = new Validator<RegisterData>();
    const formHelper = new FormHelper("/login");

    const dto = ref(new UserDto(new UserModel()));
    const form = ref(new RegisterFormData(dto.value));
    const isRegistrationStartedSuccessfully = ref(false);

    const customError = ref("");

    form.value.language = translationStore.currentLanguage;

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_REGISTER")),
    });

    return {
      validator,
      formHelper,
      form,
      dto,
      isRegistrationStartedSuccessfully,
      customError,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-checkbox-box) {
  align-self: center;

  &:hover {
    border-color: var(--ff-random-package-color);
  }

  &.p-focus {
    box-shadow: 0 0 0 0.2rem var(--ff-random-package-color);
    border-color: var(--ff-random-package-color);
  }

  &.p-highlight {
    border-color: var(--ff-random-package-color);
    background: var(--ff-random-package-color);
  }
}
</style>
