import { ModelDto } from "./base/ModelDto";
import { CouponModel } from "@/models/CouponModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class CouponDto extends ModelDto<CouponModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.CouponModel(x),
    },
  ];
}
class CouponListDto extends ListDto<CouponDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.CouponDto(y)),
    },
  ];
}

export { CouponDto, CouponListDto };
