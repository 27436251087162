import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d6c86bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price" }
const _hoisted_2 = { class: "small" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: _normalizeClass({ 'with-total': _ctx.showTotal })
    }, _toDisplayString(_ctx.value), 3),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_Translate, { text: "STOREFRONT_CURRENCIES_SACHETS" })
      ]),
      (_ctx.showTotal)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, [
            _createVNode(_component_Translate, { text: "STOREFRONT_PRODUCTS_TOTAL" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}