import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw, RouterScrollBehavior } from "vue-router";
import { useUserStatusStore } from "@/stores/userStatusStore";
import StripedContentWrapper from "@/views/shared/StripedContentWrapper.vue";
import { useTranslationStore } from "@/stores/translationStore";
import { LanguageHelper } from "@/helpers/LanguageHelper";
import { LocalStorageHelper } from "@/helpers/LocalStorageHelper";
import { pageview } from "vue-gtag";

import Login from "../views/shared/Login.vue";
import Register from "../views/shared/Register.vue";
import ForgotPassword from "../views/shared/ForgotPassword.vue";
import ForgotPasswordComplete from "../views/shared/ForgotPasswordComplete.vue";
import RegisterComplete from "@/views/shared/RegisterComplete.vue";
import NewPassword from "@/views/shared/NewPassword.vue";
import NotVerified from "@/views/shared/NotVerified.vue";

import StoreFront from "../views/storefront/StoreFront.vue";
import StoreFrontLandingPage from "../views/storefront/LandingPage.vue";
import StoreFrontHowDoesItWork from "../views/storefront/HowDoesItWork.vue";
import StoreFrontPrivacyProtection from "../views/storefront/PrivacyProtection.vue";
import StoreFrontPageNotFound from "../views/storefront/PageNotFound.vue";
import StoreFrontContactForm from "../views/storefront/ContactForm.vue";
import StoreFrontProducts from "../views/storefront/products/Products.vue";
import StoreFrontProductDetail from "../views/storefront/products/ProductDetail.vue";
import StoreFrontCart from "../views/storefront/checkout/Cart.vue";
import StoreFrontCheckout from "../views/storefront/checkout/Checkout.vue";
import StoreFrontOrderConfirmed from "../views/storefront/checkout/OrderConfirmed.vue";
import StoreFrontUserProfile from "../views/storefront/users/UserProfile.vue";
import StoreFrontUserProfileOrderDetail from "../views/storefront/users/UserProfileOrderDetail.vue";
import StoreFrontUserProfileEdit from "../views/storefront/users/UserProfileEdit.vue";
import StoreFrontUserProfileEditAddresses from "../views/storefront/users/UserProfileEditAddresses.vue";
import StoreFrontUserProfileChangePassword from "../views/storefront/users/UserProfileChangePassword.vue";

import ControlPanel from "../views/controlpanel/ControlPanel.vue";
import ControlPanelDashboard from "../views/controlpanel/Dashboard.vue";
import ControlPanelTranslations from "../views/controlpanel/translations/Translations.vue";
import ControlPanelTranslationsList from "../views/controlpanel/translations/TranslationsList.vue";
import ControlPanelTranslationsEdit from "../views/controlpanel/translations/TranslationsEdit.vue";
import ControlPanelSliders from "../views/controlpanel/sliders/Sliders.vue";
import ControlPanelSlidersList from "../views/controlpanel/sliders/SlidersList.vue";
import ControlPanelSlidersEdit from "../views/controlpanel/sliders/SlidersEdit.vue";
import ControlPanelSlidersCreate from "../views/controlpanel/sliders/SlidersCreate.vue";
import ControlPanelUsers from "../views/controlpanel/users/Users.vue";
import ControlPanelUsersList from "../views/controlpanel/users/UsersList.vue";
import ControlPanelUsersEdit from "../views/controlpanel/users/UsersEdit.vue";
import ControlPanelOrders from "../views/controlpanel/orders/Orders.vue";
import ControlPanelOrdersList from "../views/controlpanel/orders/OrdersList.vue";
import ControlPanelOrdersEdit from "../views/controlpanel/orders/OrdersEdit.vue";
import ControlPanelProducts from "../views/controlpanel/products/Products.vue";
import ControlPanelProductsList from "../views/controlpanel/products/ProductsList.vue";
import ControlPanelProductsEdit from "../views/controlpanel/products/ProductsEdit.vue";
import ControlPanelProductsCreate from "../views/controlpanel/products/ProductsCreate.vue";
import ControlPanelCoupons from "../views/controlpanel/coupons/Coupons.vue";
import ControlPanelCouponsList from "../views/controlpanel/coupons/CouponsList.vue";
import ControlPanelCouponsEdit from "../views/controlpanel/coupons/CouponsEdit.vue";
import ControlPanelCouponsCreate from "../views/controlpanel/coupons/CouponsCreate.vue";
import ControlPanelCountries from "../views/controlpanel/countries/Countries.vue";
import ControlPanelCountriesList from "../views/controlpanel/countries/CountriesList.vue";
import ControlPanelProductCategories from "../views/controlpanel/productCategories/ProductCategories.vue";
import ControlPanelProductCategoriesList from "../views/controlpanel/productCategories/ProductCategoriesList.vue";
import ControlPanelLogEntries from "../views/controlpanel/logEntries/LogEntries.vue";
import ControlPanelLogEntriesEdit from "../views/controlpanel/logEntries/LogEntriesEdit.vue";
import ControlPanelLogEntriesList from "../views/controlpanel/logEntries/LogEntriesList.vue";
import ControlPanelContactFormEntries from "../views/controlpanel/contactFormEntries/ContactFormEntries.vue";
import ControlPanelContactFormEntriesEdit from "../views/controlpanel/contactFormEntries/ContactFormEntriesEdit.vue";
import ControlPanelContactFormEntriesList from "../views/controlpanel/contactFormEntries/ContactFormEntriesList.vue";
import { string } from "yup";

/**
 * Defines all frontend routes and routing behaviours.
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "StoreFront",
    component: StoreFront,
    children: [
      {
        path: "",
        name: "StoreFront LandingPage",
        component: StoreFrontLandingPage,
      },
      {
        path: "profile",
        name: "StoreFront UserProfile",
        meta: { requireUserPermission: true },
        component: StoreFrontUserProfile,
      },
      {
        path: "profile/orders/:id",
        name: "StoreFront UserProfile OrderDetail",
        meta: { requireUserPermission: true },
        component: StoreFrontUserProfileOrderDetail,
      },
      {
        path: "profile/edit",
        name: "StoreFront UserProfile Edit",
        meta: { requireUserPermission: true },
        component: StoreFrontUserProfileEdit,
      },
      {
        path: "profile/edit/addresses",
        name: "StoreFront UserProfile EditAddresses",
        meta: { requireUserPermission: true },
        component: StoreFrontUserProfileEditAddresses,
      },
      {
        path: "profile/edit/password",
        name: "StoreFront UserProfile ChangePassword",
        meta: { requireUserPermission: true },
        component: StoreFrontUserProfileChangePassword,
      },
      {
        path: "cart",
        name: "StoreFront Cart",
        component: StoreFrontCart,
      },
      {
        path: "checkout",
        name: "StoreFront Checkout",
        component: StoreFrontCheckout,
      },
      {
        path: "order-confirmed",
        name: "StoreFront Order Confirmed",
        component: StoreFrontOrderConfirmed,
      },
      {
        path: "products",
        name: "StoreFront Products",
        component: StoreFrontProducts,
      },
      {
        path: "products/:id",
        name: "StoreFront ProductDetail",
        component: StoreFrontProductDetail,
      },
      {
        path: "how-does-it-work",
        name: "StoreFront HowDoesItWork",
        component: StoreFrontHowDoesItWork,
      },
      {
        path: "privacy-protection",
        name: "StoreFront PrivacyProtection",
        component: StoreFrontPrivacyProtection,
      },
      {
        path: "contact",
        name: "StoreFront ContactForm",
        component: StoreFrontContactForm,
      },
      {
        path: "/:pathMatch(.*)*",
        name: "StoreFront PageNotFound",
        component: StoreFrontPageNotFound,
      },
    ],
  },
  {
    path: "/controlpanel",
    name: "ControlPanel",
    meta: { requireAdminPermission: true },
    component: ControlPanel,
    children: [
      {
        path: "",
        name: "ControlPanel Dashboard",
        meta: { requireAdminPermission: true },
        component: ControlPanelDashboard,
      },
      {
        path: "translations",
        name: "ControlPanel Translations",
        meta: { requireAdminPermission: true },
        component: ControlPanelTranslations,
        children: [
          {
            path: "",
            name: "ControlPanel Translations List",
            meta: { requireAdminPermission: true },
            component: ControlPanelTranslationsList,
          },
          {
            path: ":id",
            name: "ControlPanel Translations Edit",
            meta: { requiresAdmin: true },
            component: ControlPanelTranslationsEdit,
          },
        ],
      },
      {
        path: "sliders",
        name: "ControlPanel Sliders",
        meta: { requireAdminPermission: true },
        component: ControlPanelSliders,
        children: [
          {
            path: "",
            name: "ControlPanel Sliders List",
            meta: { requireAdminPermission: true },
            component: ControlPanelSlidersList,
          },
          {
            path: ":id",
            name: "ControlPanel Sliders Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelSlidersEdit,
          },
          {
            path: "create",
            name: "ControlPanel Sliders Create",
            meta: { requireAdminPermission: true },
            component: ControlPanelSlidersCreate,
          },
        ],
      },
      {
        path: "users",
        name: "ControlPanel Users",
        meta: { requireAdminPermission: true },
        component: ControlPanelUsers,
        children: [
          {
            path: "",
            name: "ControlPanel Users List",
            meta: { requireAdminPermission: true },
            component: ControlPanelUsersList,
          },
          {
            path: ":id",
            name: "ControlPanel Users Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelUsersEdit,
          },
        ],
      },
      {
        path: "orders",
        name: "ControlPanel Orders",
        meta: { requireAdminPermission: true },
        component: ControlPanelOrders,
        children: [
          {
            path: "",
            name: "ControlPanel Orders List",
            meta: { requireAdminPermission: true },
            component: ControlPanelOrdersList,
          },
          {
            path: ":id",
            name: "ControlPanel Orders Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelOrdersEdit,
          },
        ],
      },
      {
        path: "productCategories",
        name: "ControlPanel ProductCategories",
        meta: { requireAdminPermission: true },
        component: ControlPanelProductCategories,
        children: [
          {
            path: "",
            name: "ControlPanel ProductCategories List",
            meta: { requireAdminPermission: true },
            component: ControlPanelProductCategoriesList,
          },
        ],
      },
      {
        path: "products",
        name: "ControlPanel Products",
        meta: { requireAdminPermission: true },
        component: ControlPanelProducts,
        children: [
          {
            path: "",
            name: "ControlPanel Products List",
            meta: { requireAdminPermission: true },
            component: ControlPanelProductsList,
          },
          {
            path: ":id",
            name: "ControlPanel Products Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelProductsEdit,
          },
          {
            path: "create",
            name: "ControlPanel Products Create",
            meta: { requireAdminPermission: true },
            component: ControlPanelProductsCreate,
          },
        ],
      },
      {
        path: "coupons",
        name: "ControlPanel Coupons",
        meta: { requireAdminPermission: true },
        component: ControlPanelCoupons,
        children: [
          {
            path: "",
            name: "ControlPanel Coupons List",
            meta: { requireAdminPermission: true },
            component: ControlPanelCouponsList,
          },
          {
            path: ":id",
            name: "ControlPanel Coupons Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelCouponsEdit,
          },
          {
            path: "create",
            name: "ControlPanel Coupons Create",
            meta: { requireAdminPermission: true },
            component: ControlPanelCouponsCreate,
          },
        ],
      },
      {
        path: "countries",
        name: "ControlPanel Countries",
        meta: { requireAdminPermission: true },
        component: ControlPanelCountries,
        children: [
          {
            path: "",
            name: "ControlPanel Countries List",
            meta: { requireAdminPermission: true },
            component: ControlPanelCountriesList,
          },
        ],
      },
      {
        path: "logentries",
        name: "ControlPanel LogEntries",
        meta: { requireAdminPermission: true },
        component: ControlPanelLogEntries,
        children: [
          {
            path: "",
            name: "ControlPanel LogEntries List",
            meta: { requireAdminPermission: true },
            component: ControlPanelLogEntriesList,
          },
          {
            path: ":id",
            name: "ControlPanel LogEntries Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelLogEntriesEdit,
          },
        ],
      },
      {
        path: "contactformentries",
        name: "ControlPanel ContactFormEntries",
        meta: { requireAdminPermission: true },
        component: ControlPanelContactFormEntries,
        children: [
          {
            path: "",
            name: "ControlPanel ContactFormEntries List",
            meta: { requireAdminPermission: true },
            component: ControlPanelContactFormEntriesList,
          },
          {
            path: ":id",
            name: "ControlPanel ContactFormEntries Edit",
            meta: { requireAdminPermission: true },
            component: ControlPanelContactFormEntriesEdit,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    name: "StripedWrapper",
    component: StripedContentWrapper,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "password-reset",
        name: "ForgotPasswordComplete",
        component: ForgotPasswordComplete,
      },
      {
        path: "verification",
        name: "RegisterComplete",
        component: RegisterComplete,
      },
      {
        path: "new-password",
        name: "NewPassword",
        component: NewPassword,
      },
      {
        path: "not-verified",
        name: "NotVerified",
        component: NotVerified,
      },
    ],
  },
];

// return to top of page when navigating.
const scrollBehavior: RouterScrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition) return savedPosition;
  if (to.hash) return { selector: to.hash };

  return { left: 0, top: 0 };
};

// create router component.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollBehavior,
});

// check if user is authenticated for routes that require authentication.
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (LocalStorageHelper.areOptionalCookieAccepted()) {
    pageview({
      page_title: to.name?.toString(),
      page_location: to.fullPath,
      page_path: to.path,
    });
  }

  const userStatusStore = useUserStatusStore();
  const translationStore = useTranslationStore();

  const routeRequiresUserPermission = to.matched.some((record) => record.meta.requireUserPermission);
  const routeRequiresAdminPermission = to.matched.some((record) => record.meta.requireAdminPermission);

  // The page is not rendered until the status and translations is restored, see App.vue.
  // A loading spinner is shown, so we can safely use await here.
  if (!userStatusStore.isUserStatusRestored) {
    await userStatusStore.getUserStatus();

    // update store front language if necessary.
    LanguageHelper.updateStoreFrontLanguage();
  }

  if (!translationStore.isTranslationsRestored) {
    await translationStore.getActiveTranslations();
  }

  if ((routeRequiresAdminPermission && !userStatusStore.isAdmin) || (routeRequiresUserPermission && !userStatusStore.isAuthenticated)) {
    next("/login");
  } else {
    next();
  }
});

export default router;
