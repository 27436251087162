<template>
  <div class="price">
    <h2 :class="{ 'with-total': showTotal }">{{ value }}</h2>
    <div>
      <span class="small"><Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
      <h2 v-if="showTotal"><Translate text="STOREFRONT_PRODUCTS_TOTAL" /></h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Translate from "./Translate.vue";

/**
 * Simple price tag component.
 */
export default defineComponent({
  name: "ProductPrice",
  components: {
    Translate,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    showTotal: {
      type: Boolean,
      required: false,
    },
  },
  setup() {},
});
</script>

<style scoped lang="scss">
.price {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  font-family: "GoodPro";

  span {
    &.small {
      font-size: 1.3rem;
      line-height: 1.2rem;
      text-transform: none;
    }
  }

  h2 {
    &.with-total {
      font-size: 6rem;
    }
  }

  div {
    display: flex;
    flex-direction: column;
  }
}
</style>
