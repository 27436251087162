<template>
  <DataTable :value="listDto.list" :loading="listDto.isLoading" dataKey="model.id" filterDisplay="menu" size="small" stripedRows @row-dblclick="goToOrderDetails($event.data.model.id)">
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>
    <Column sortable filter field="model.orderNumber" :header="Translator.translate(isMobile ? 'STOREFRONT_PROFILE_ORDER_NUMBER_SHORT' : 'STOREFRONT_PROFILE_ORDER_NUMBER')">
      <template #body="slotProps">
        <span class="clickable" @click="goToOrderDetails(slotProps.data.model.id)">{{ slotProps.data.model.orderNumber }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.orderDate" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_DATE')">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.orderDate" :withTime="true"></DateDisplay>
      </template>
    </Column>
    <Column sortable filter field="model.deliveryStatus" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_STATUS')" header-style="width: 200px">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">
          <Translate text="STOREFRONT_ORDER_STATUS_CANCELED" />
        </Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">
          <Translate text="STOREFRONT_ORDER_STATUS_DELIVERED" />
        </Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">
          <Translate text="STOREFRONT_ORDER_STATUS_PARTIALLY_DELIVERED" />
        </Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">
          <Translate text="STOREFRONT_ORDER_STATUS_UNPROCESSED" />
        </Tag>
      </template>
    </Column>
    <Column field="model.amountDelivered" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_ALREADY_SENT')">
      <template #body="slotProps"
        >{{ computeTotalAmountDelivered(slotProps.data.model) }} <Translate text="STOREFRONT_PROFILE_ORDER_OF" /> {{ computeTotalAmountOrdered(slotProps.data.model) }}</template
      >
    </Column>
    <Column sortable filter field="model.shippingDate" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_SHIPPING_DATE')">
      <template #body="slotProps">
        <DateDisplay v-if="slotProps.data.model.shippingDate" :date="slotProps.data.model.shippingDate" :withTime="true"></DateDisplay>
        <span v-else>-</span>
      </template>
    </Column>
    <Column v-if="!isMobile" sortable filter :header="Translator.translate('STOREFRONT_PROFILE_ORDER_PRICE_TOTAL')">
      <template #body="slotProps"> {{ computeTotalCost(slotProps.data.model) }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /> </template>
    </Column>
    <template #footer> {{ footerText }} </template>
  </DataTable>
</template>

<script lang="ts">
import { OrderListDto } from "@/dtos/OrderDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { defineComponent, toRefs } from "vue";
import orderMixins from "@/mixins/orderMixins";
import { useBreakpoints } from "@vueuse/core";
import { Constants } from "@/enums/Constants";
import { FormHelper } from "@/helpers/FormHelper";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import Translate from "./Translate.vue";
import { Translator } from "@/helpers/Translator";

/**
 * Simple date render component.
 */
export default defineComponent({
  name: "OrdersDataTable",
  components: {
    DateDisplay,
    Translate,
  },
  props: {
    listDto: {
      type: OrderListDto,
      required: true,
    },
    footerText: {
      type: String,
      required: true,
    },
    detailsLink: {
      type: String,
      required: true,
    },
    showAdminButtons: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    goToOrderDetails(orderId: string): void {
      this.$router.push(this.detailsLink + orderId);
    },
  },
  setup(props) {
    const { listDto } = toRefs(props);

    const formHelper = new FormHelper("/profile");

    const computeTotalCost = orderMixins.computeTotalCost;
    const computeTotalAmountOrdered = orderMixins.computeTotalAmountOrdered;
    const computeTotalAmountDelivered = orderMixins.computeTotalAmountDelivered;

    const breakpoints = useBreakpoints(Constants.Breakpoints);

    const isMobile = breakpoints.isSmallerOrEqual("mobile");

    return {
      formHelper,
      listDto,
      isMobile,
      computeTotalCost,
      computeTotalAmountOrdered,
      computeTotalAmountDelivered,
      DeliveryStatus,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
.p-datatable {
  button {
    margin-left: 10px;
  }
}
</style>
