import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d0a000"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "cart" }
const _hoisted_3 = { class: "flex justify-content-between" }
const _hoisted_4 = { class: "flex justify-content-between" }
const _hoisted_5 = {
  key: 0,
  class: "cart-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!
  const _component_ProductCartEntry = _resolveComponent("ProductCartEntry")!
  const _component_LoadingSkeleton = _resolveComponent("LoadingSkeleton")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProductPrice = _resolveComponent("ProductPrice")!
  const _component_FFButton = _resolveComponent("FFButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createVNode(_component_Translate, { text: "STOREFRONT_CART" })
      ]),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderProducts, (orderProduct, index) => {
              return (_openBlock(), _createBlock(_component_ProductCartEntry, {
                key: orderProduct.product.id,
                model: orderProduct
              }, null, 8, ["model"]))
            }), 128)),
            _withDirectives(_createElementVNode("p", null, [
              _createVNode(_component_Translate, { text: "STOREFRONT_CART_EMPTY" })
            ], 512), [
              [_vShow, _ctx.orderProducts.length === 0]
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"5%"},
                height: "5rem"
              }),
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"85%"},
                height: "5rem"
              }),
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"8%"},
                height: "5rem"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"5%"},
                height: "5rem"
              }),
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"85%"},
                height: "5rem"
              }),
              _createVNode(_component_LoadingSkeleton, {
                style: {"width":"8%"},
                height: "5rem"
              })
            ])
          ], 64)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.outOfStockProducts, (outOfStockProduct) => {
        return (_openBlock(), _createBlock(_component_Message, {
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Translator.translateFormat("STOREFRONT_CART_NOT_ENOUGH_STOCK", outOfStockProduct.product.fullName(), outOfStockProduct.product.stock)), 1)
          ]),
          _: 2
        }, 1024))
      }), 256)),
      _createVNode(_component_Message, {
        severity: "info",
        closable: false,
        class: "info"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Translate, { text: "STOREFRONT_CART_FREE_SHIPPING" })
        ]),
        _: 1
      }),
      (_ctx.orderProducts.length > 0)
        ? (_openBlock(), _createBlock(_component_ProductPrice, {
            key: 2,
            value: _ctx.priceTotal,
            "show-total": true
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Translate, { text: "STOREFRONT_CART_DESCRIPTION" })
    ]),
    (_ctx.orderProducts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_FFButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToCheckout())),
            loading: _ctx.isAnyProductLoading,
            disabled: !_ctx.isValidOrder,
            label: _ctx.Translator.translate('STOREFRONT_CART_TO_CHECKOUT'),
            color: "var(--ff-primary-black)"
          }, null, 8, ["loading", "disabled", "label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}