import * as Yup from "yup";
import { ICouponModel } from "@/models/interfaces/ICouponModel";
import { CouponModel } from "@/models/CouponModel";
import { BaseFormData } from "./BaseFormData";
import { CouponDto } from "../CouponDtos";
import { toRaw } from "vue";
import { OrderModel } from "@/models/OrderModel";

export class CouponFormData extends BaseFormData<ICouponModel> implements ICouponModel {
  public code: string;
  public validFromDate: Date;
  public validToDate: Date;
  public reductionInPacks: number;
  public isActive: boolean;
  public orders: OrderModel[];

  public originalData: CouponModel;

  constructor(dto: CouponDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.code = dto.model.code;
    this.validFromDate = dto.model.validFromDate;
    this.validToDate = dto.model.validToDate;
    this.reductionInPacks = dto.model.reductionInPacks;
    this.isActive = dto.model.isActive;
    this.orders = dto.model.orders;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      isActive: Yup.boolean().default(true),
      code: Yup.string().required(),
      validFromDate: Yup.date().required(),
      validToDate: Yup.date().required(),
      reductionInPacks: Yup.number().required(),
      orders: Yup.array().defined(),
    });
  }
}
