import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c93c5832"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = {
  key: 0,
  class: "products"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDisplay = _resolveComponent("ProductDisplay")!
  const _component_Translate = _resolveComponent("Translate")!
  const _component_FFProgressSpinner = _resolveComponent("FFProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.productsDto.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsDto.list, (product, index) => {
            return (_openBlock(), _createBlock(_component_ProductDisplay, {
              key: product.model.id,
              dto: product,
              "is-scroll-animation": index > 0
            }, null, 8, ["dto", "is-scroll-animation"]))
          }), 128)),
          (_ctx.productsDto.list.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                _createVNode(_component_Translate, { text: "STOREFRONT_PRODUCTS_NO_PRODUCTS_AVAILABLE" })
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_FFProgressSpinner, { key: 1 }))
  ]))
}