import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, [
      _createVNode(_component_Translate, { text: "STOREFRONT_NEWPASSWORD_TITLE" })
    ]),
    _createElementVNode("p", null, [
      _createVNode(_component_Translate, { text: "STOREFRONT_NEWPASSWORD_DESCRIPTION" })
    ])
  ], 64))
}