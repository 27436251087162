import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_Panel, {
      header: _ctx.header,
      class: "actions"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["header"])
  ], 32))
}