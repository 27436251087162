<template>
  <div class="title-panel">
    <h2>Benutzer bearbeiten</h2>
  </div>

  <UsersCreateEdit :id="id" :dto="dto" :ordersDto="ordersDto"></UsersCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { UserDto } from "@/dtos/UserDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import UsersCreateEdit from "@/components/controlpanel/users/UsersCreateEdit.vue";
import { UserModel } from "@/models/UserModel";
import { useUserStore } from "@/stores/userStore";
import { OrderListDto } from "@/dtos/OrderDtos";
import { useOrderStore } from "@/stores/orderStore";

/**
 * View that renders the the "edit" form for users.
 */
export default defineComponent({
  name: "UsersEdit",
  setup() {
    const userStore = useUserStore();
    const orderStore = useOrderStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/users");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new UserDto(new UserModel()));

    const ordersDto = ref(new OrderListDto([]));

    onMounted(() => {
      // get entity from store.
      userStore.getUser(dto.value, id);

      // TODO use: userStore.getUser(dto.value, id).then(() => userStore.getOrdersByUserId(ordersDto.value, id));

      // get orders and filter by user id.
      userStore.getOrdersByUserId(ordersDto.value, id);
    });

    return {
      id,
      dto,
      ordersDto,
    };
  },
  components: { UsersCreateEdit },
});
</script>

<style scoped lang="scss"></style>
