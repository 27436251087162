import { ModelDto } from "./base/ModelDto";
import { UserModel } from "@/models/UserModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class UserDto extends ModelDto<UserModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.UserModel(x),
    },
  ];
}

class UserListDto extends ListDto<UserDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.UserDto(y)),
    },
  ];
}

export { UserDto, UserListDto };
