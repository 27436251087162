import { defineStore } from "pinia";
import { LoginData } from "@/dtos/data/LoginData";
import { LoginReturnCode } from "@/enums/LoginReturnCode";
import { UserDto } from "@/dtos/UserDtos";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { UserStatusApiController } from "@/api/UserStatusApiController";
import { UserModel } from "@/models/UserModel";
import { CartModel } from "@/models/CartModel";
import { AddressModel } from "@/models/AddressModel";
import { Language } from "@/enums/Language";
import { RoleType } from "@/enums/RoleType";
import { LanguageHelper } from "@/helpers/LanguageHelper";
import { RegisterData } from "@/dtos/data/RegisterData";
import { UserStatusState } from "@/dtos/state/UserStatusState";
import { FilterMatchMode } from "primevue/api";

const apiController = new UserStatusApiController();

export const useUserStatusStore = defineStore("userStatus", {
  // =================================================
  // ||                   STATE                     ||
  // =================================================
  state: (): UserStatusState => ({
    isUnderConstruction: false,
    isUserStatusRestored: false,
    userDto: {
      model: {
        id: 0,
        username: "Guest",
        password: "",
        mfaEnabled: false,
        notes: null,
        isActive: false,
        hasNewsletter: false,
        lastLoginDate: null,
        displayName: "Guest",
        language: Language.NONE,
        roleId: 0,
        role: {
          id: 0,
          name: "GUEST",
          roleType: RoleType.GUEST,
          createdAt: new Date(),
          updatedAt: null,
        },
        shippingAddress: new AddressModel(),
        billingAddress: null,
        useSameAddress: true,
        createdAt: new Date(),
        updatedAt: null,
        isMigrated: false,
      },
      isDirty: false,
      hasError: false,
      isLoading: false,
      validationErrorFields: [],
    },
    cartDto: {
      model: {
        id: 0,
        orderProductList: [],
        createdAt: new Date(),
        updatedAt: null,
      },
      isDirty: false,
      hasError: false,
      isLoading: false,
      validationErrorFields: [],
    },
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  // =================================================
  // ||                   GETTERS                   ||
  // =================================================
  getters: {
    isAuthenticated(): boolean {
      return this.userDto.model.role.roleType === RoleType.ADMIN || this.userDto.model.role.roleType === RoleType.USER;
    },

    isAdmin(): boolean {
      return this.userDto.model.role.roleType === RoleType.ADMIN;
    },
  },
  actions: {
    /* -------------------------------------------
     * Log the user in with the provided input data.
     * -------------------------------------------- */
    async postLogin(loginData: LoginData): Promise<LoginReturnCode> {
      this.userDto.isLoading = true;
      this.userDto.hasError = false;

      return new Promise<LoginReturnCode>((resolve, reject) => {
        apiController
          .login(loginData)
          .then((success) => {
            if (success.data.loginReturnCode === LoginReturnCode.USERNAMEPASSWORD_OK) {
              ObjectHelper.copyExistingPropsFromTo(success.data.user, this.userDto.model, UserModel.mappings);

              // update store front language if necessary.
              LanguageHelper.updateStoreFrontLanguage();

              resolve(LoginReturnCode.USERNAMEPASSWORD_OK);
            } else {
              reject();
            }
          })
          .catch((error) => {
            // redirect in case of password change event.
            if (error.data.loginReturnCode === LoginReturnCode.NEW_PASSWORD_REQUIRED) {
              resolve(LoginReturnCode.NEW_PASSWORD_REQUIRED);
            } else if (error.data.loginReturnCode === LoginReturnCode.VERIFICATION_PENDING) {
              resolve(LoginReturnCode.VERIFICATION_PENDING);
            } else {
              this.userDto.hasError = true;
              reject(error);
            }
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Log the user in with the provided input data.
     * -------------------------------------------- */
    async postLogout(): Promise<void> {
      this.userDto.isLoading = true;
      this.userDto.hasError = false;

      return new Promise<void>((resolve, reject) => {
        apiController
          .logout()
          .then(() => {
            this.getUserStatus()
              .then((response) => {
                // update store front language if necessary.
                LanguageHelper.updateStoreFrontLanguage();

                resolve(response);
              })
              .catch(reject);
          })
          .catch((error) => {
            this.userDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Register a new user with the provided input data.
     * -------------------------------------------- */
    async register(dto: UserDto, data: RegisterData): Promise<void> {
      dto.isLoading = true;
      dto.hasError = false;

      return new Promise<void>((resolve, reject) => {
        apiController
          .register(data)
          .then((success) => {
            resolve();
          })
          .catch((error) => {
            dto.hasError = true;
            reject(error);
          })
          .finally(() => {
            dto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Get User Session info from backend. Tells if user is logged in.
     * -------------------------------------------- */
    async getUserStatus(): Promise<void> {
      this.userDto.hasError = false;
      this.userDto.isLoading = true;

      return new Promise<void>((resolve, reject) => {
        return apiController
          .getUserStatus()
          .then((success) => {
            this.isUserStatusRestored = true;
            this.isUnderConstruction = success.data.isUnderConstruction;

            ObjectHelper.copyExistingPropsFromTo(success.data.user, this.userDto.model, UserModel.mappings);
            ObjectHelper.copyExistingPropsFromTo(success.data.cart, this.cartDto.model, CartModel.mappings);

            resolve();
          })
          .catch((error) => {
            this.userDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Gets the current cart count for one specific product.
     * -------------------------------------------- */
    getAmountInCart(productId: number): number {
      const product = this.cartDto.model.orderProductList.find((p) => p.product.id === productId);
      if (product) {
        return product.amountOrdered;
      }
      return 0;
    },

    /* -------------------------------------------
     * Adds amount of certain product in cart.
     * -------------------------------------------- */
    async updateProductCartAmount(productId: number, amount: number): Promise<void> {
      this.cartDto.hasError = false;
      this.cartDto.isLoading = true;

      return new Promise<void>((resolve, reject) => {
        apiController
          .updateProductCartAmount(productId, amount)
          .then(() => {
            this.getUserStatus().then(resolve).catch(reject);
          })
          .catch((error) => {
            this.cartDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.cartDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Entirely removes a single product from cart.
     * -------------------------------------------- */
    async removeProductFromCart(productId: number): Promise<void> {
      this.cartDto.hasError = false;
      this.cartDto.isLoading = true;

      return new Promise<void>((resolve, reject) => {
        return apiController
          .removeProductFromCart(productId)
          .then(() => {
            this.getUserStatus().then(resolve).catch(reject);
          })
          .catch((error) => {
            this.cartDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.cartDto.isLoading = false;
          });
      });
    },
  },
});
