<template>
  <div class="title-panel">
    <h2>Benutzer</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel Export" @click="onExportExcel"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
    :totalRecords="listDto.totalRecordCount"
    :loading="listDto.isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Benutzer gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.id" header="ID">
      <template #body="slotProps">
        <span class="clickable" @click="goToEdit(slotProps.data.model.id)">{{ slotProps.data.model.id }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.username" header="Benutzername/E-Mail">
      <template #body="slotProps">
        <span class="clickable" @click="goToEdit(slotProps.data.model.id)">{{ slotProps.data.model.username }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.displayName" header="Anzeigename"></Column>
    <Column sortable filter field="model.lastLoginDate" header="letztes Login am">
      <template #body="slotProps">
        <DateDisplay v-if="slotProps.data.model.lastLoginDate" :date="slotProps.data.model.lastLoginDate" :with-time="true"></DateDisplay>
        <span v-else>-</span>
      </template>
    </Column>
    <Column sortable filter field="model.roleId" header="Rolle">
      <template #body="slotProps">
        <Tag v-if="isAdmin(slotProps.data.model.role)" :value="slotProps.data.model.role.name" :severity="'warning'"></Tag>
        <Tag v-else :value="slotProps.data.model.role.name" :severity="'info'"></Tag>
      </template>
    </Column>
    <Column sortable filter field="model.isActive" header="Status">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.isActive" value="aktiv" :severity="'success'"></Tag>
        <Tag v-else value="inaktiv" :severity="'warning'"></Tag>
      </template>
    </Column>

    <template #footer> Es gibt {{ listDto.totalRecordCount }} Benutzer. </template>
  </DataTable>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { UserListDto } from "@/dtos/UserDtos";
import { useUserStore } from "@/stores/userStore";
import { RoleModel } from "@/models/RoleModel";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { ExportType } from "@/enums/ExportType";
import { RoleType } from "@/enums/RoleType";
import { storeToRefs } from "pinia";

/**
 * View that renders a list of all users.
 */
export default defineComponent({
  name: "UsersList",
  components: { DateDisplay },
  methods: {
    async onPage($event: DataTablePageEvent): Promise<void> {
      const store = useUserStore();

      store.setPage(this.listDto, $event.page + 1);
      store.setPageSize(this.listDto, $event.rows);

      await store.getUsers(this.listDto);
    },

    async onSort($event: DataTableSortEvent): Promise<void> {
      const store = useUserStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getUsers(this.listDto);
    },

    async onRefresh(): Promise<void> {
      const store = useUserStore();

      await store.getUsers(this.listDto);
    },

    async onFilter() {
      const store = useUserStore();

      store.setFilter(this.listDto, this.filters.global.value, this.enumFilter);

      await store.getUsers(this.listDto);
    },

    onExportPdf(): void {
      const store = useUserStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useUserStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string): void {
      this.$router.push("/controlpanel/users/" + id);
    },

    isAdmin(role: RoleModel): boolean {
      return role.roleType === RoleType.ADMIN;
    },
  },
  setup() {
    const store = useUserStore();

    const { filters, enumFilter } = storeToRefs(store);

    const listDto = reactive(new UserListDto([]));

    // make initial fetch.
    onMounted(() => {
      // reapply filter.
      store.setFilter(listDto, filters.value.global.value, enumFilter.value);

      // fetch data from API.
      store.getUsers(listDto);
    });
    return {
      listDto,
      filters,
      enumFilter,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}

img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
</style>
