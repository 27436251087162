<template>
  <footer>
    <div class="social-media">
      <a :href="ffSocialMediaLinks.facebook" class="ff-icon facebook" target="blank"></a>
      <a :href="ffSocialMediaLinks.instagram" class="ff-icon instagram" target="blank"></a>
      <a :href="ffSocialMediaLinks.tiktok" class="ff-icon tiktok" target="blank"></a>
    </div>
    <div class="links">
      <a :href="Translator.translate('STOREFRONT_NAVIGATION_FOOTER_SUSTAINABILITY_LINK')" target="_blank"><Translate text="STOREFRONT_NAVIGATION_FOOTER_SUSTAINABILITY" /></a>
      <router-link to="/privacy-protection"><Translate text="STOREFRONT_NAVIGATION_FOOTER_IMPRESSUM" /></router-link>
      <router-link to="/contact"><Translate text="STOREFRONT_NAVIGATION_FOOTER_CONTACT" /></router-link>
      <a :href="Translator.translate('STOREFRONT_NAVIGATION_FOOTER_CERTIFICATES_LINK')" target="_blank"><Translate text="STOREFRONT_NAVIGATION_FOOTER_CERTIFICATES" /></a>
      <a :href="Translator.translate('STOREFRONT_NAVIGATION_FOOTER_INFORMATION_LINK')" target="_blank"><Translate text="STOREFRONT_NAVIGATION_FOOTER_INFORMATION" /></a>
      <a :href="Translator.translate('STOREFRONT_NAVIGATION_FOOTER_FF_MAIN_PAGE_LINK')" target="_blank"><Translate text="STOREFRONT_NAVIGATION_FOOTER_FF_MAIN_PAGE" /></a>
    </div>
    <div class="copyright">
      <img src="@/assets/img/ff_logo_wordmark_trawler_black_frame_rgb.png" :alt="Translator.translate('STOREFRONT_TITLE')" />
      <span>&copy; {{ legalLine }}</span>
    </div>
  </footer>
</template>

<script lang="ts">
import { Translator } from "@/helpers/Translator";
import { computed, defineComponent } from "vue";
import Translate from "../shared/Translate.vue";

/**
 * Component that renders the Footer with social links and legal info in the Store Front.
 */
export default defineComponent({
  name: "StoreFrontFooter",
  components: {
    Translate,
  },
  setup() {
    const ffSocialMediaLinks = {
      facebook: "https://www.facebook.com/fishermansfriendswitzerland/",
      instagram: "https://www.instagram.com/fishermansfriend.ch/",
      tiktok: "https://www.tiktok.com/@fishermansfriend.ch/",
    };

    const legalLine = computed(() => `${new Date().getFullYear()} ${location.host}`);

    return {
      ffSocialMediaLinks,
      legalLine,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
footer {
  display: flex;
  flex-direction: column;
  margin-top: 168px;
  font-size: 10px;
  text-transform: uppercase;

  .social-media {
    display: flex;
    justify-content: center;
    font-size: 3.5rem;
    padding-bottom: 40px;

    .ff-icon {
      display: block;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 1em;
      width: 1em;
      height: 1em;
      line-height: 1em;
      box-sizing: content-box;

      position: relative;
      font-family: "FFIcons";
      opacity: 0.7;
      padding: 0.5rem;

      &::before {
        transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
      }

      &::after {
        transition: opacity 0.25s ease-out, border-width 0.25s ease-in-out, width 0.25s ease-out, height 0.25s ease-out;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: -1;
        border: 0 solid $ff-primary-red;
        border-radius: 50%;
        content: "";
        background-color: $ff-primary-red;
      }

      &:hover {
        opacity: 1;
        text-decoration: none !important;

        &::before {
          color: $ff-primary-white;
        }
        &::after {
          opacity: 1;
          border-width: 2rem;
        }
      }

      &.facebook {
        &::before {
          content: "\EA07";
        }
      }
      &.instagram {
        &::before {
          content: "\EA09";
        }
      }
      &.tiktok {
        &::before {
          content: "\EA0A";
        }
      }
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    padding-bottom: 60px;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;

    img {
      padding-bottom: 1rem;
      width: 96px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  footer {
    margin-top: 60px;

    .links {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
    }

    .copyright {
      img {
        width: 148px;
      }
    }
  }
}
</style>
