import { ProductCategoryDto } from "@/dtos/ProductDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { ProductCategoryListViewModel, ProductCategoryViewModel } from "@/viewModels/ViewModels";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";

export class ProductCategoryApiController extends BaseApiController<ProductCategoryModel, ProductCategoryDto, ProductCategoryViewModel, ProductCategoryListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.PRODUCTCATEGORIES);
  }
}
