import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "storefront" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoreFrontNavigation = _resolveComponent("StoreFrontNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_StoreFrontFooter = _resolveComponent("StoreFrontFooter")!
  const _component_StoreFrontCookieBanner = _resolveComponent("StoreFrontCookieBanner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StoreFrontNavigation),
    _createElementVNode("div", {
      class: _normalizeClass(["content", { 'is-admin': _ctx.isAdmin }])
    }, [
      _createVNode(_component_router_view)
    ], 2),
    _createVNode(_component_StoreFrontFooter),
    _createVNode(_component_StoreFrontCookieBanner)
  ]))
}