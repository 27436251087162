<template>
  <div v-if="listDto.list.length > 0" :class="{ 'hide-navigation': listDto.list.length === 1 }">
    <Galleria :value="listDto.list" :responsiveOptions="[]" :numVisible="5" :circular="true" :showItemNavigators="true" :showThumbnails="false" :autoPlay="true" :transitionInterval="8000">
      <template #item="slotProps">
        <a :href="slotProps.item.model.link()" :title="slotProps.item.model.title()" target="_blank">
          <img :src="slotProps.item.model.image" :alt="slotProps.item.model.title()" />
          <div class="title">
            <h2>{{ slotProps.item.model.title() }}</h2>
            <p>{{ slotProps.item.model.text() }}</p>
          </div>
        </a>
      </template>
    </Galleria>
  </div>
  <LoadingSkeleton v-else :skeletonType="blockSkeletonType" width="100%" :height="'500px'"></LoadingSkeleton>
</template>

<script lang="ts">
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { SliderListDto } from "@/dtos/SliderDtos";
import { SkeletonType } from "@/enums/SkeletonType";
import { useSliderStore } from "@/stores/sliderStore";
import { computed, defineComponent, onMounted, reactive } from "vue";

/**
 * Component that renders the sliders on the Home Page in the Store Front.
 */
export default defineComponent({
  name: "HomeSlider",
  components: {
    LoadingSkeleton,
  },
  setup() {
    const store = useSliderStore();

    const listDto = reactive(new SliderListDto([]));

    const totalDtosCount = computed(() => listDto.totalRecordCount);
    const isLoading = computed(() => listDto.isLoading);

    const blockSkeletonType = SkeletonType.BLOCK;

    // make initial fetch.
    onMounted(() => {
      store.getActiveSliders(listDto);
    });

    return {
      listDto,
      totalDtosCount,
      isLoading,
      blockSkeletonType,
    };
  },
});
</script>

<style scoped lang="scss">
.hide-navigation {
  :deep(.p-galleria-item-nav) {
    display: none !important;
  }
}

.p-galleria {
  position: relative;
  width: 100%;

  :deep(.p-galleria-item-nav) {
    z-index: 2;
    border-radius: 0;
  }

  :deep(.p-galleria-item) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-image: linear-gradient(0deg, black, transparent);
      opacity: 0.7;
    }

    a {
      width: 100%;
      height: 700px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 10%;
      }

      .title {
        margin: 0;
        position: absolute;
        bottom: 40px;
        left: 40px;
        z-index: 1;
        color: $ff-primary-white;
      }

      h2 {
        margin: 0;
        font-size: 5rem;
        line-height: 4.5rem;
      }

      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  :deep(.p-galleria) {
    .p-galleria-item {
      a {
        h2 {
          width: calc(100% - 40px);
        }
      }
    }

    .p-galleria-item-prev,
    .p-galleria-item-next {
      width: 30px;
    }
  }
}
</style>
