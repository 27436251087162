<template>
  <div class="title-panel">
    <h2>Bestellungen</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel Export" @click="onExportExcel"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
    :totalRecords="listDto.totalRecordCount"
    :loading="listDto.isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToDetails($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <div class="flex flex-wrap align-items-center gap-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
          </span>
          <Dropdown v-model="enumFilter" :options="deliveryStatusOptions" option-value="value" @change="onFilter" placeholder="Nach Status filtern...">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ deliveryStatusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
              </div>
              <div v-else>kein Filter</div>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
              <div v-else>&nbsp;</div>
            </template>
          </Dropdown>
          <i v-if="enumFilter || filters['global'].value" class="pi pi-eraser clickable" @click="clearFilters()"></i>
        </div>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Bestellungen gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.orderNumber" header="Bestellnummer">
      <template #body="slotProps">
        <span class="clickable" @click="goToDetails(slotProps.data.model.id)">{{ slotProps.data.model.orderNumber }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.orderDate" header="Bestelldatum">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.orderDate" :withTime="true"></DateDisplay>
      </template>
    </Column>
    <Column sortable filter field="deliveryStatusValue" header="Status" header-style="width: 200px">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">Storniert</Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">Versendet</Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">Teilweise versendet</Tag>
        <Tag v-else-if="slotProps.data.model.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">Versand wird vorbereitet</Tag>
        <Tag v-if="isArchived(slotProps.data.model)" value="archiviert" class="archived"></Tag>
      </template>
    </Column>
    <Column field="model.amountDelivered" header="Bereits versendet">
      <template #body="slotProps">{{ computeTotalAmountDelivered(slotProps.data.model) }} von {{ computeTotalAmountOrdered(slotProps.data.model) }}</template>
    </Column>
    <Column sortable filter field="model.userId" header="Besteller">
      <template #body="slotProps">
        {{ slotProps.data.model.user?.displayName }}
      </template>
    </Column>
    <Column sortable field="model.couponId" header="Coupon">
      <template #body="slotProps">
        <span v-if="slotProps.data.model.coupon?.code" class="clickable" @click="goToCouponDetails(slotProps.data.model.coupon.id)">{{ slotProps.data.model.coupon.code }}</span>
        <span v-else>-</span>
      </template>
    </Column>
    <Column header="Anzahl Produkte">
      <template #body="slotProps">
        {{ slotProps.data.model.orderProducts.length }}
      </template>
    </Column>
    <Column header="Preis Total">
      <template #body="slotProps"> {{ computeTotalCost(slotProps.data.model) }} Sachets </template>
    </Column>
    <template #footer> Es gibt {{ listDto.totalRecordCount }} Bestellungen. </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { OrderListDto } from "@/dtos/OrderDtos";
import { useOrderStore } from "@/stores/orderStore";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import orderMixins from "@/mixins/orderMixins";
import { ExportType } from "@/enums/ExportType";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { storeToRefs } from "pinia";
import { EnumHelper } from "@/helpers/EnumHelper";
import modelMixins from "@/mixins/modelMixins";

/**
 * View that renders a list of all orders.
 */
export default defineComponent({
  name: "OrdersList",
  components: { DateDisplay },
  methods: {
    async onPage($event: DataTablePageEvent): Promise<void> {
      const store = useOrderStore();

      store.setPage(this.listDto, $event.page + 1);
      store.setPageSize(this.listDto, $event.rows);

      await store.getOrders(this.listDto);
    },

    async onSort($event: DataTableSortEvent): Promise<void> {
      const store = useOrderStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getOrders(this.listDto);
    },

    async onRefresh(): Promise<void> {
      const store = useOrderStore();

      await store.getOrders(this.listDto);
    },

    async onFilter() {
      const store = useOrderStore();

      store.setFilter(this.listDto, this.filters.global.value, this.enumFilter);

      await store.getOrders(this.listDto);
    },

    clearFilters() {
      this.filters.global.value = null;
      this.enumFilter = null;

      this.onFilter();
    },

    onExportPdf(): void {
      const orderStore = useOrderStore();

      orderStore.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const orderStore = useOrderStore();

      orderStore.exportList(this.listDto, ExportType.EXCEL);
    },

    goToDetails(id: string): void {
      this.$router.push("/controlpanel/orders/" + id);
    },

    goToCouponDetails(id: string): void {
      this.$router.push("/controlpanel/coupons/" + id);
    },
  },
  setup() {
    const orderStore = useOrderStore();

    const listDto = reactive(new OrderListDto([]));

    const { filters, enumFilter } = storeToRefs(orderStore);

    const computeTotalCost = orderMixins.computeTotalCost;
    const computeTotalAmountOrdered = orderMixins.computeTotalAmountOrdered;
    const computeTotalAmountDelivered = orderMixins.computeTotalAmountDelivered;
    const deliveryStatusOptions = EnumHelper.createDropdownOptionsForDeliveryStatus();
    const isArchived = modelMixins.isOrderArchived;

    // make initial fetch.
    onMounted(() => {
      // reapply filter.
      orderStore.setFilter(listDto, filters.value.global.value, enumFilter.value);

      // fetch data from API.
      orderStore.getOrders(listDto);
    });
    return {
      listDto,
      filters,
      enumFilter,
      computeTotalCost,
      computeTotalAmountOrdered,
      computeTotalAmountDelivered,
      isArchived,
      deliveryStatusOptions,
      DeliveryStatus,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}

img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.archived {
  margin-left: 5px;
  background: $ff-controlpanel-light;
}
</style>
