import { ConfirmationOptions } from "primevue/confirmationoptions";
import { useConfirm } from "primevue/useconfirm";
import { Router, useRouter } from "vue-router";

/**
 * Used for helping with HTML Forms that make API requests.
 */
export class FormHelper {
  private listRoute: string;
  private router: Router;
  private confirm: {
    require: (option: ConfirmationOptions) => void;
    close: () => void;
  };

  constructor(listRoute: string) {
    this.listRoute = listRoute;
    this.router = useRouter();
    this.confirm = useConfirm();
  }

  /* -------------------------------------------
  Navigates to the list route.
  ------------------------------------------- */
  public async returnToList(): Promise<void> {
    this.router.push(this.listRoute);
  }

  /* -------------------------------------------
  Shows a popup that prompts the user to confirm a deletion.
  ------------------------------------------- */
  public async promptForDeletion(event: Event, acceptCallback: () => void, rejectCallback: () => void): Promise<void> {
    this.prompt(event, "Sind Sie sicher, dass Sie diesen Einträg löschen möchten?", "Bestätigen", "Ja", "Nein", "pi pi-trash", acceptCallback, rejectCallback);
  }

  /* -------------------------------------------
  Shows a custom popup that prompts the user to confirm an action.
  ------------------------------------------- */
  public async prompt(event: Event, message: string, header: string, acceptLabel: string, rejectLabel: string, acceptIcon: string, acceptCallback: () => void, rejectCallback: () => void): Promise<void> {
    this.confirm.require({
      message: message,
      header: header,
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      acceptIcon: acceptIcon,
      accept: acceptCallback,
      reject: rejectCallback,
    });
  }

  /* -------------------------------------------
  Converts an uploaded Blob file to base64 string.
  ------------------------------------------- */
  public async blobImageToBase64(blobFile: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blobFile);
    });
  }

  public base64ToBlob(base64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
