<template>
  <nav :class="{ collapsed: isNavigationCollapsed, shadowed: isNavigationShadowVisible }">
    <div class="row admin" v-if="isAdmin && !isBarebone">
      <router-link to="/controlpanel">
        <i class="pi pi-cog"></i>
        <Translate text="STOREFRONT_NAVIGATION_CONTROLPANEL_LINK" />
      </router-link>
      <span>
        <small><Translate text="STOREFRONT_NAVIGATION_CONTROLPANEL_LINK_HINT" /></small>
      </span>
    </div>

    <div class="row language-select">
      <div></div>
      <div>
        <Translate v-if="!isBarebone" text="STOREFRONT_NAVIGATION_LANGUAGE_SELECT" />
        <span @click="changeLanguage(Language.DE)" class="link" :class="{ active: currentLanguage === Language.DE }">DE</span>|
        <span @click="changeLanguage(Language.FR)" class="link" :class="{ active: currentLanguage === Language.FR }">FR</span>
      </div>
    </div>

    <div class="row user-status" v-if="!isBarebone">
      <div>
        <template v-if="isAuthenticated">
          <i class="pi pi-user"></i>
          <div class="loggedin-info">
            <router-link to="/profile" class="username" :class="{ disabled: isLoadingUser }">
              <span class="hide-on-mobile"> <Translate text="STOREFRONT_NAVIGATION_GREETING" /></span><span>{{ userDisplayName }} </span>
            </router-link>
            <a @click="logout()" class="logout" :class="{ disabled: isLoadingUser }">
              <Translate text="STOREFRONT_NAVIGATION_LOGOUT" />
            </a>
          </div>
        </template>
        <template v-else>
          <router-link to="/login">
            <i class="pi pi-user"></i>
            <Translate text="STOREFRONT_NAVIGATION_LOGIN" />
          </router-link>
        </template>
      </div>
      <div>
        <router-link to="/cart">
          <i v-if="cartItemsCount > 0" class="pi pi-shopping-cart p-overlay-badge" v-badge="cartItemsCount"></i>
          <i v-else class="pi pi-shopping-cart"></i>
          <span class="hide-on-mobile"><Translate text="STOREFRONT_NAVIGATION_CART" /></span>
        </router-link>
      </div>
    </div>

    <div class="row navigation">
      <div class="logo mobile">
        <router-link to="/">
          <img src="@/assets/img/ff_logo_wordmark_black_frame_rgb.png" :alt="Translator.translate('STOREFRONT_TITLE')" />
          <h1>
            <Translate v-if="!isBarebone" text="STOREFRONT_NAVIGATION_TITLE" />
            <template v-else>LOYALTY STORE</template>
          </h1>
        </router-link>
      </div>
      <div class="links-left">
        <template v-for="dto of productCategoriesDto.list" v-if="!isBarebone">
          <router-link :to="`/products?category=${dto.model.id}`">{{ dto.model.name() }}</router-link>
        </template>
        <i v-if="productCategoriesDto.isLoading" class="pi pi-spin pi-spinner category-spinner"></i>

        <router-link v-if="!isBarebone" to="/products"><Translate text="STOREFRONT_NAVIGATION_ALL_PRODUCTS" /></router-link>
      </div>
      <div class="logo desktop">
        <router-link to="/">
          <img src="@/assets/img/ff_logo_wordmark_trawler_black_frame_rgb.png" :alt="Translator.translate('STOREFRONT_TITLE')" :class="{ squished: isLogoSquished }" />
          <h1>
            <Translate v-if="!isBarebone" text="STOREFRONT_NAVIGATION_TITLE" />
            <template v-else>LOYALTY STORE</template>
          </h1>
        </router-link>
      </div>
      <div class="links-right">
        <router-link v-if="!isBarebone" to="/how-does-it-work"><Translate text="STOREFRONT_NAVIGATION_HOW_DOES_IT_WORK" /></router-link>
      </div>
    </div>
  </nav>

  <Toast class="store-front-toast">
    <template #message="slotProps">
      <div :class="{ collapsed: isNavigationCollapsed, shadowed: isNavigationShadowVisible, 'is-admin': isAdmin }">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <span v-if="slotProps.message.hasButton" class="breadcrumb mb-2 mt-2" @click="slotProps.message.buttonAction">
          <span>{{ slotProps.message.buttonLabel }}</span> <i class="pi pi-angle-right"></i>
        </span>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { Language } from "@/enums/Language";
import { useTranslationStore } from "@/stores/translationStore";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { useProductCategoryStore } from "@/stores/productCategoryStore";
import { ProductCategoryListDto } from "@/dtos/ProductDtos";
import FFButton from "./FFButton.vue";
/**
 * Component that renders the Store Front navigation.
 */
export default defineComponent({
  name: "StoreFrontNavigation",
  components: { Translate, FFButton },
  props: {
    // strips away everything except language picker and logo. Used when pages can't be loaded.
    isBarebone: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    async logout() {
      const userStatusStore = useUserStatusStore();

      userStatusStore.postLogout().then(() => {
        this.$router.push("/");
      });
    },

    changeLanguage(language: Language) {
      const translationStore = useTranslationStore();

      translationStore.currentLanguage = language;
    },
  },
  setup() {
    const userStatusStore = useUserStatusStore();
    const translationStore = useTranslationStore();
    const productCategoryStore = useProductCategoryStore();

    const userDisplayName = computed(() => userStatusStore.userDto.model.displayName);
    const isAuthenticated = computed(() => userStatusStore.isAuthenticated);
    const isAdmin = computed(() => userStatusStore.isAdmin);
    const isLoadingUser = computed(() => userStatusStore.userDto.isLoading);
    const cartItemsCount = computed(() => userStatusStore.cartDto.model.orderProductList.length ?? 0);
    const currentLanguage = computed(() => translationStore.currentLanguage);

    const scrollPosition = ref(0);
    const scrollDirection = ref(1);
    const isNavigationCollapsed = computed(() => scrollPosition.value > 200 && scrollDirection.value === -1);
    const isNavigationShadowVisible = computed(() => scrollPosition.value > 0);
    const isLogoSquished = computed(() => scrollPosition.value > 0);

    const productCategoriesDto = reactive(new ProductCategoryListDto([]));

    // add scroll listeners.
    const onScroll = () => {
      const newScrollPosition = window.top?.scrollY ?? 0;
      scrollDirection.value = Math.sign(scrollPosition.value - newScrollPosition);
      scrollPosition.value = newScrollPosition;
    };

    onMounted(() => {
      window.addEventListener("scroll", onScroll);

      productCategoryStore.getActiveProductCategories(productCategoriesDto);
    });

    onBeforeUnmount(() => window.removeEventListener("scroll", onScroll));

    return {
      userDisplayName,
      isAuthenticated,
      isAdmin,
      isLoadingUser,
      cartItemsCount,
      isNavigationCollapsed,
      isNavigationShadowVisible,
      isLogoSquished,
      currentLanguage,
      Language,
      Translator,
      productCategoriesDto,
    };
  },
});
</script>

<style scoped lang="scss">
nav {
  transition: transform 300ms ease-out;

  position: fixed;
  top: 0;
  width: 100%;
  background: $ff-primary-white;
  z-index: 99;

  &.collapsed {
    transform: translateY(-100%);
  }

  &.shadowed {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    transition: opacity 300ms ease-out;

    display: block;
    position: absolute;
    top: 0;
    opacity: 0;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    content: "";
    pointer-events: none;
  }

  .row {
    padding: 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $ff-storefront-light;

    &.admin {
      background-color: $ff-controlpanel-dark;
      flex-direction: column;
      justify-content: center;

      span,
      a {
        color: $ff-primary-white;
      }

      span {
        font-weight: normal;
        font-style: italic;
        white-space: wrap;
      }

      i {
        font-size: 0.7rem;
      }
    }

    &.language-select {
      .link {
        text-transform: lowercase;
        letter-spacing: 0.01em;
        font-size: 1.328125rem;
        font-weight: 700;
        font-variant: small-caps;

        cursor: pointer;
        padding: 0 10px;
        font-weight: bold;

        &.active {
          color: $ff-primary-red;
        }
      }
    }

    &.user-status {
      > div {
        align-items: center;
        display: flex;

        .loggedin-info {
          display: inline-flex;
          flex-direction: column;

          a {
            cursor: pointer;

            &.username span {
              font-weight: bold;
            }
          }
        }
      }

      a {
        padding: 0 10px;
      }
    }

    &.navigation {
      height: initial;
      padding: 0 15%;
      margin-bottom: 15px;
      font-weight: bold;
      align-items: flex-end;
      border-bottom: none;

      > div {
        &.links-left {
          justify-content: flex-start;
        }

        &.links-right {
          justify-content: flex-end;
        }

        &.links-left,
        &.links-right {
          display: flex;
          column-gap: 40px;
          width: 100%;
          margin-bottom: 7px;

          a {
            text-transform: lowercase;
            letter-spacing: 0.01em;
            font-size: 1.328125rem;
            font-weight: 700;
            font-variant: small-caps;
            text-wrap: nowrap;
          }

          .category-spinner {
            height: fit-content;
            margin-top: 6px;
            opacity: 0.6;
          }
        }

        &.logo {
          &.mobile {
            display: none;
          }

          a {
            width: 160px;
            text-wrap: nowrap;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;

            &:hover {
              text-decoration: none !important;
            }
          }

          h1 {
            margin: 0;
          }

          img {
            transition: max-height 200ms ease, margin 200ms ease, transform 200ms ease;

            width: 100%;
            margin: 19px /* according to FF CI/CD */;
            max-height: 1000px;
            object-fit: contain;

            &.squished {
              max-height: 0;
              margin-top: 0;
              transform: scale(0);
            }
          }
        }
      }
    }
  }

  :deep(.p-badge),
  :deep(.p-overlay-badge) {
    border-radius: 50% !important;
  }

  div {
    i {
      margin-right: 5px;
      font-size: 1.2rem;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  nav {
    position: absolute;

    &.collapsed {
      transform: translateY(0);
    }

    .row {
      &.user-status {
        .hide-on-mobile {
          display: none;
        }
      }

      &.navigation {
        flex-direction: column;
        padding: 20px;
        width: 100%;

        > div {
          &.logo {
            &.desktop {
              display: none;
            }
            &.mobile {
              display: flex;
              width: 100%;

              a {
                width: 100%;

                img {
                  max-width: 200px;
                  width: 50%;
                }
              }
            }
          }

          &.links-left,
          &.links-right {
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>

<!-- Unscoped specific toast styling -->
<style lang="scss">
.logout {
  width: fit-content;
  font-size: 0.9rem;
}

.p-toast {
  transition: top 300ms ease-out;
  position: relative;

  top: 116px !important;

  .navigation-button {
    margin-top: 20px;
    width: fit-content;
  }

  .p-toast-message {
    background: $ff-primary-white;
    color: $ff-primary-black;
    font-family: "TradeGothicNext";

    &:after {
      content: "";
      clear: both;
      position: absolute;
      top: -16px;
      right: 16px;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
    }

    .p-toast-summary {
      font-family: "GoodPro";
      font-size: 2rem;
      line-height: 1.8rem;
      text-transform: uppercase;
    }

    &.p-toast-message-success {
      &::after {
        border-bottom: 16px solid $successMessageBg;
      }

      .p-toast-icon-close {
        &:focus-visible {
          box-shadow: 0 0 0 0.2rem $successMessageTextColor;
        }
      }
    }
    &.p-toast-message-info {
      &::after {
        border-bottom: 16px solid $infoMessageBg;
      }

      .p-toast-icon-close {
        &:focus-visible {
          box-shadow: 0 0 0 0.2rem $infoMessageTextColor;
        }
      }
    }
    &.p-toast-message-warning {
      &::after {
        border-bottom: 16px solid $warningMessageBg;
      }

      .p-toast-icon-close {
        &:focus-visible {
          box-shadow: 0 0 0 0.2rem $warningMessageTextColor;
        }
      }
    }
    &.p-toast-message-error {
      &::after {
        border-bottom: 16px solid $errorMessageBg;
      }

      .p-toast-icon-close {
        &:focus-visible {
          box-shadow: 0 0 0 0.2rem $errorMessageTextColor;
        }
      }
    }
  }

  &:has(.is-admin) {
    top: 166px !important;
  }

  &:has(.collapsed) {
    top: 20px !important;
  }
}

@media (max-width: $breakpoint-mobile) {
  nav {
    .row.user-status {
      height: 60px;

      .loggedin-info {
        row-gap: 4px;
      }
    }
  }

  .store-front-toast {
    right: 0 !important;
    width: 90%;

    .p-toast-message-content {
      padding: 1.5rem 1rem;
    }

    &:has(.shadowed) {
      top: 20px !important;

      &:has(.is-admin) {
        top: 20px !important;
      }
    }
  }
}
</style>
