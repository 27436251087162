import { LocationQueryValue } from "vue-router";

/**
 * Helper methods related to routing.
 */
export default {
  /* -------------------------------------------
  Returns the parameter if it's a valid integer.
  ------------------------------------------- */
  tryGetParam(param: string | string[], errorCallback: () => void): number {
    const id = Number(param);
    if (!Number.isInteger(id)) {
      errorCallback();
    }
    return id;
  },

  /* -------------------------------------------
  Returns the parameter if it's a valid integer.
  ------------------------------------------- */
  tryGetQuery(param: LocationQueryValue | LocationQueryValue[], errorCallback: () => void): number {
    const id = Number(param);
    if (!Number.isInteger(id)) {
      errorCallback();
    }
    return id;
  },
};
