import { ModelDto } from "./base/ModelDto";
import { ProductModel } from "@/models/ProductModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { ProductImageModel } from "@/models/ProductImageModel";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";

class ProductDto extends ModelDto<ProductModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.ProductModel(x),
    },
  ];
}

class ProductListDto extends ListDto<ProductDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.ProductDto(y)),
    },
  ];
}

class ProductImageDto extends ModelDto<ProductImageModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.ProductImageModel(x),
    },
  ];
}

class ProductImageListDto extends ListDto<ProductImageDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.ProductImageDto(y)),
    },
  ];
}

class ProductCategoryDto extends ModelDto<ProductCategoryModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.ProductCategoryModel(x),
    },
  ];
}

class ProductCategoryListDto extends ListDto<ProductCategoryDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.ProductCategoryDto(y)),
    },
  ];
}

export { ProductDto, ProductListDto, ProductImageDto, ProductImageListDto, ProductCategoryDto, ProductCategoryListDto };
