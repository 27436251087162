import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";
import { PasswordResetData } from "./PasswordResetData";

export class PasswordResetFormData extends BaseFormData<PasswordResetData> implements PasswordResetData {
  public username: string;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.username = "";

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      username: Yup.string().email().required(),
    });
  }
}
