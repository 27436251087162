<template>
  <div class="title-panel">
    <h2>Coupons</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel Export" @click="onExportExcel"></Button>
      <Button type="button" label="Neu erstellen" @click="goToCreate()"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :totalRecords="listDto.totalRecordCount"
    :rowsPerPageOptions="[10, 20, 50]"
    :loading="listDto.isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Coupons gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.code" header="Code">
      <template #body="slotProps">
        <span class="clickable" @click="goToEdit(slotProps.data.model.id)">{{ slotProps.data.model.code }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.validFromDate" header="Gültig von">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.validFromDate"></DateDisplay>
        <Tag v-if="isCurrentlyValid(slotProps.data.model)" icon="pi pi-check" class="icon" :severity="'success'"></Tag>
      </template>
    </Column>
    <Column sortable filter field="model.validToDate" header="Gültig bis">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.validToDate"></DateDisplay>
        <Tag v-if="isCurrentlyValid(slotProps.data.model)" icon="pi pi-check" class="icon" :severity="'success'"></Tag>
      </template>
    </Column>
    <Column sortable filter field="model.reductionInPacks" header="Anzahl Sachets"></Column>
    <Column sortable filter field="model.isActive" header="Status">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.isActive" value="aktiv" :severity="'success'"></Tag>
        <Tag v-else value="inaktiv" :severity="'warning'"></Tag>
        <Tag v-if="isArchived(slotProps.data.model)" value="archiviert" class="archived"></Tag>
      </template>
    </Column>

    <template #footer> Es gibt {{ listDto.totalRecordCount }} Coupons. </template>
  </DataTable>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from "vue";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { useCouponStore } from "@/stores/couponStore";
import { CouponModel } from "@/models/CouponModel";
import { CouponListDto } from "@/dtos/CouponDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { ExportType } from "@/enums/ExportType";
import { storeToRefs } from "pinia";
import modelMixins from "@/mixins/modelMixins";

/**
 * View that renders a list of all coupons.
 */
export default defineComponent({
  name: "CouponsList",
  components: { DateDisplay },
  methods: {
    async onPage($event: DataTablePageEvent) {
      const store = useCouponStore();

      store.setPage(this.listDto, $event.page + 1);
      store.setPageSize(this.listDto, $event.rows);

      await store.getCoupons(this.listDto);
    },

    async onSort($event: DataTableSortEvent) {
      const store = useCouponStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getCoupons(this.listDto);
    },

    async onRefresh() {
      const store = useCouponStore();

      await store.getCoupons(this.listDto);
    },

    async onFilter() {
      const store = useCouponStore();

      store.setFilter(this.listDto, this.filters.global.value, this.enumFilter);

      await store.getCoupons(this.listDto);
    },

    onExportPdf(): void {
      const store = useCouponStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useCouponStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string) {
      this.$router.push("/controlpanel/coupons/" + id);
    },

    goToCreate() {
      this.$router.push("/controlpanel/coupons/create");
    },
  },
  setup() {
    const store = useCouponStore();
    const now = new Date();

    const { filters, enumFilter } = storeToRefs(store);

    const listDto = reactive(new CouponListDto([]));

    const isCurrentlyValid = computed(() => (model: CouponModel) => new Date(model.validFromDate) <= now && new Date(model.validToDate) >= now);
    const willBeValid = computed(() => (model: CouponModel) => new Date(model.validToDate) > now);

    const isArchived = modelMixins.isCouponArchived;

    // make initial fetch.
    onMounted(() => {
      // reapply filter.
      store.setFilter(listDto, filters.value.global.value, enumFilter.value);

      // fetch data from API.
      store.getCoupons(listDto);
    });
    return {
      listDto,
      isCurrentlyValid,
      willBeValid,
      filters,
      enumFilter,
      isArchived,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}

img {
  max-height: 50px;
  max-width: 50px;
}

.archived {
  margin-left: 5px;
  background: $ff-controlpanel-light;
}

.p-tag {
  &.icon {
    height: 15px;
    width: 15px;
    margin-left: 4px;

    :deep(span) {
      font-size: 0.7rem;
      margin: 0;

      &::before {
        font-size: 0.5rem;
      }
    }
  }
}
</style>
