<template>
  <div class="inner">
    <router-link to="/cart" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_CHECKOUT_TO_CART" /> </span>
    </router-link>

    <div class="checkout">
      <h2><Translate text="STOREFRONT_CHECKOUT" /></h2>

      <h3 class="first"><Translate text="STOREFRONT_CHECKOUT_CONTACT" /></h3>
      <div class="order-contact">
        <Panel :header="Translator.translate('STOREFRONT_CHECKOUT_SHIPPING_ADDRESS')">
          <template #icons>
            <button class="p-panel-header-icon p-link mr-2" @click="goToEditAddresses">
              <span class="pi pi-pencil"></span>
            </button>
          </template>
          <div v-if="hasShippingAddress">
            <p class="m-0">{{ user.shippingAddress.firstname }} {{ user.shippingAddress.surname }}</p>
            <p class="m-0" v-if="user.shippingAddress.companyName">{{ user.shippingAddress.companyName }}</p>
            <p class="m-0">{{ user.shippingAddress.street }} {{ user.shippingAddress.streetNumber }}</p>
            <p class="m-0">{{ user.shippingAddress.additional }}</p>
            <p class="m-0">{{ user.shippingAddress.zipCode }} {{ user.shippingAddress.city }}</p>
            <p class="m-0">{{ user.shippingAddress.telephoneNumber }}</p>
          </div>
          <div v-else>
            <p class="m-0"><Translate text="STOREFRONT_FORMS_NO_SHIPPING_ADDRESS_DEFINED" /></p>
            <small class="p-error"><Translate text="STOREFRONT_CHECKOUT_SHIPPING_ADDRESS_MISSING" /></small>
          </div>
        </Panel>
      </div>

      <h3><Translate text="STOREFRONT_CHECKOUT_SUMMARY" /></h3>
      <div class="order-summary">
        <template v-if="!isLoading">
          <div class="row header">
            <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_PRODUCT" /></span>
            <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_PRODUCT_TOTAL" /></span>
          </div>

          <div class="row" v-for="(orderProduct, index) of orderProducts" :key="orderProduct.product.id">
            <span>
              <span class="amount">{{ orderProduct.amountOrdered }}x</span> <span class="name">{{ orderProduct.product.fullName() }}</span>
            </span>
            <span class="price">{{ orderProduct.sellingPrice * orderProduct.amountOrdered }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
          </div>

          <div class="row nosplit">
            <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_SHIPPING" /></span>
            <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_FREE_SHIPPING" /></span>
          </div>

          <template v-if="isValidCouponActive">
            <div class="row midsum nosplit">
              <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_SUBTOTAL" /></span>
              <span>{{ priceTotalWithoutReduction }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
            </div>
            <div class="row p-success nosplit">
              <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_COUPON" /> '{{ couponDto.model.code }}'</span>
              <span>-{{ priceReduction }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
            </div>
            <div class="row sum nosplit">
              <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_TOTAL" /></span>
              <span>{{ priceTotal }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
            </div>
          </template>
          <template v-else>
            <div class="row sum nosplit">
              <span><Translate text="STOREFRONT_CHECKOUT_SUMMARY_TOTAL" /></span>
              <span>{{ priceTotal }} <Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="row justify-content-between">
            <LoadingSkeleton style="width: 49%" height="2rem"></LoadingSkeleton>
            <LoadingSkeleton style="width: 49%" height="2rem"></LoadingSkeleton>
          </div>
          <div class="row justify-content-between">
            <LoadingSkeleton style="width: 49%" height="2rem"></LoadingSkeleton>
            <LoadingSkeleton style="width: 49%" height="2rem"></LoadingSkeleton>
          </div>
        </template>
      </div>
    </div>

    <Message class="info" severity="info" :closable="false"><Translate text="STOREFRONT_CHECKOUT_PAYMENT_IN_SACHETS" /></Message>

    <Panel v-if="orderProducts.length > 0" class="mt-5" :header="Translator.translate('STOREFRONT_CHECKOUT_COUPON_HEADER')">
      <p class="m-0"><Translate text="STOREFRONT_CHECKOUT_COUPONS_DESCRIPTION" /></p>

      <form @submit.prevent="" @change="clearCouponIfEmpty()" :class="{ 'm-0': validator.hasValidationErrors(dto, 'couponCode') || isValidCouponActive }">
        <div class="form-row short">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-gift"></i>
            <InputText v-model="form.couponCode" :disabled="isLoading" v-on:keyup.enter="validateCoupon()" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'couponCode') }" />
            <label for="requestOrigin"><Translate text="STOREFRONT_FORMS_CHECKOUT_COUPON" /></label>
          </span>
          <Button label="" icon="pi pi-check" @click="validateCoupon()" :loading="couponDto.isLoading"></Button>
        </div>
        <p v-show="isValidCouponActive">
          <small class="p-success">{{ Translator.translateFormat("STOREFRONT_FORMS_CHECKOUT_COUPON_ACTIVATED", couponDto.model.code) }}</small>
        </p>
        <p v-show="validator.hasValidationErrors(dto, 'couponCode')">
          <small class="p-error"><Translate text="STOREFRONT_FORMS_CHECKOUT_COUPON_VALIDATION" /></small>
        </p>
      </form>
    </Panel>

    <Panel v-if="orderProducts.length > 0" class="mt-5" :header="Translator.translate('STOREFRONT_CHECKOUT_TERMS_AND_CONDITIONS_HEADER')">
      <p class="m-0">
        <Translate text="STOREFRONT_CHECKOUT_TERMS_AND_CONDITIONS" />
      </p>

      <form @submit.prevent="" @change="validator.validateForm(form as CheckoutFormData, dto)" class="mb-0">
        <div class="form-row">
          <Checkbox
            v-model="form.isAgbAccepted"
            input-id="isAgbAccepted"
            :binary="true"
            :disabled="isLoading"
            class="required"
            :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'isAgbAccepted') }"
          />
          <label for="isAgbAccepted" class="p-link ml-2"><Translate text="STOREFRONT_FORMS_CHECKOUT_TERMS_AND_CONDITIONS_ACCEPT" /></label>
        </div>
        <p v-show="validator.hasValidationErrors(dto, 'isAgbAccepted')">
          <small class="p-error"><Translate text="STOREFRONT_FORMS_CHECKOUT_TERMS_AND_CONDITIONS_ACCEPT_VALIDATION" /></small>
        </p>

        <small class="required-explanation">* <Translate text="STOREFRONT_FORMS_REQUIRED" /></small>
      </form>
    </Panel>

    <Panel v-if="orderProducts.length > 0" class="mt-5" :header="Translator.translate('STOREFRONT_CHECKOUT_NEWSLETTER_HEADER')">
      <p class="m-0"><Translate text="STOREFRONT_FORMS_CHECKOUT_NEWSLETTER" /></p>

      <form @submit.prevent="" @change="validator.validateForm(form as CheckoutFormData, dto)">
        <div class="form-row">
          <Checkbox input-id="hasNewsletter" :binary="true" :disabled="isLoading" v-model="form.hasNewsletter" />
          <label for="hasNewsletter" class="p-link ml-2"><Translate text="STOREFRONT_FORMS_CHECKOUT_NEWSLETTER_ACCEPT" /></label>
        </div>
      </form>
    </Panel>

    <Message v-if="dto.hasError" severity="error"><Translate text="STOREFRONT_MESSAGES_CHECKOUT_ERROR" /></Message>

    <div v-if="orderProducts.length > 0" class="checkout-buttons">
      <FFButton
        @click="placeOrder()"
        :loading="isLoading || isLoadingOrderPlaced"
        :disabled="!hasShippingAddress"
        :label="Translator.translate('STOREFRONT_CHECKOUT_SUBMIT')"
        color="var(--ff-primary-black)"
      ></FFButton>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import ProductCartEntry from "@/components/storefront/landingpage/products/ProductCartEntry.vue";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { CheckoutFormData } from "@/dtos/data/CheckoutFormData";
import { useOrderStore } from "@/stores/orderStore";
import { Validator } from "@/helpers/Validator";
import { OrderDto } from "@/dtos/OrderDtos";
import { OrderModel } from "@/models/OrderModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useCouponStore } from "@/stores/couponStore";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponModel } from "@/models/CouponModel";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import stringMixins from "@/mixins/stringMixins";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "StoreFrontCheckout",
  components: {
    ProductCartEntry,
    ProductPrice,
    FFButton,
    LoadingSkeleton,
    Translate,
  },
  methods: {
    goToCheckout(): void {
      this.$router.push("/checkout");
    },

    goToEditAddresses(): void {
      this.$router.push({
        path: `/profile/edit/addresses`,
        hash: "#checkout",
      });
    },

    clearCouponIfEmpty(): void {
      // get coupon status if user has entered anything.
      if (this.form.couponCode === "") {
        this.dto.validationErrorFields = [];
        this.dto.model.couponId = null;
        this.couponDto.model.id = NaN;
      }
    },

    validateCoupon(): void {
      const couponStore = useCouponStore();

      // clear validation.
      this.dto.validationErrorFields = [];
      this.dto.model.couponId = null;

      // get coupon status if user has entered anything.
      if (this.form.couponCode) {
        couponStore
          .getCouponStatus(this.couponDto, this.form.couponCode)
          .then(() => {
            this.dto.model.couponId = this.couponDto.model.id;
          })
          .catch((error: Error) => {
            this.dto.validationErrorFields.push("couponCode");
          });
      }
    },

    placeOrder(): void {
      const orderStore = useOrderStore();
      const userStatusStore = useUserStatusStore();

      this.form.shouldValidate = true;

      this.isLoadingOrderPlaced = true;

      // set user id.
      this.dto.model.userId = userStatusStore.userDto.model.id;

      // set coupon if any.
      if (!isNaN(this.couponDto.model.id)) {
        this.form.couponId = this.couponDto.model.id;
      }

      // copy form values.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator
        .validateForm(this.form as CheckoutFormData, this.dto)
        .then(() => orderStore.createOrder(this.dto)) /* create order */
        .then(() => userStatusStore.getUserStatus()) /* refresh store */
        .then(() => {
          // update order reference to match current order.
          orderStore.orderReference = `${this.dto.model.orderNumber}`;
          orderStore.orderId = this.dto.model.id;

          // then redirect to one-time page "order confirmed".
          this.$router.push("/order-confirmed");
        })
        .finally(() => {
          this.isLoadingOrderPlaced = false;
        });
    },
  },
  setup() {
    const validator = new Validator();
    const userStatusStore = useUserStatusStore();

    // Html references.
    const porductCartEntryRefs = ref<InstanceType<typeof ProductCartEntry>[]>([]);

    // set up form.
    const dto = ref(new OrderDto(new OrderModel()));
    const couponDto = ref(new CouponDto(new CouponModel()));
    const form = ref(new CheckoutFormData(dto.value));

    // use local isLoading state.
    const isLoading = ref(false);
    const isLoadingOrderPlaced = ref(false);

    // set up computed fields.
    const orderProducts = computed(() => userStatusStore.cartDto.model.orderProductList);
    const user = computed(() => userStatusStore.userDto.model);
    const isValidCouponActive = computed(() => !isNaN(couponDto.value.model.id));
    const priceTotalWithoutReduction = computed(() => (orderProducts.value.length > 0 ? orderProducts.value.map((p) => p.amountOrdered * p.product.price).reduce((total, price) => total + price) : 0));
    const priceReduction = computed(() => couponDto.value.model.reductionInPacks);
    const priceTotal = computed(() => Math.max(0, isValidCouponActive.value ? priceTotalWithoutReduction.value - priceReduction.value : priceTotalWithoutReduction.value));

    const hasShippingAddress = computed(
      () => userStatusStore.userDto.model.shippingAddress !== null && userStatusStore.userDto.model.shippingAddress !== undefined && !isNaN(userStatusStore.userDto.model.shippingAddress.id)
    );
    const hasBillingAddress = computed(
      () => userStatusStore.userDto.model.billingAddress !== null && userStatusStore.userDto.model.billingAddress !== undefined && !isNaN(userStatusStore.userDto.model.billingAddress.id)
    );

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_CHECKOUT")),
    });

    // refresh user status at this point.
    onMounted(() => {
      isLoading.value = true;
      userStatusStore.getUserStatus().finally(() => {
        isLoading.value = false;
      });
    });

    return {
      validator,
      orderProducts,
      dto,
      couponDto,
      isLoading,
      isLoadingOrderPlaced,
      hasShippingAddress,
      hasBillingAddress,
      isValidCouponActive,
      user,
      form,
      priceTotalWithoutReduction,
      priceReduction,
      priceTotal,
      porductCartEntryRefs,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
.checkout {
  h3 {
    margin-top: 80px;

    &:nth-child(2) {
      margin-top: 40px;
    }
  }
}

.required {
  & + label {
    position: relative;
  }
}

.order-summary,
.p-panel {
  margin-top: 20px;

  :deep(.p-panel-header) {
    padding: 0.7rem 1.25rem;
  }

  :deep(.p-panel-icons) {
    display: inline-flex;
    align-items: center;

    label {
      width: auto;
    }
  }

  :deep(form) {
    .form-row {
      &.short {
        width: 50%;
      }
    }
  }
}

:deep(.p-checkbox) {
  margin-top: 20px;
  align-self: center;
}

.order-summary {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .row {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    padding: 10px 0;

    span:first-child {
      width: 50%;
    }
    span:last-child {
      text-align: right;
      width: 50%;
    }

    border-bottom: 1px solid $ff-storefront-light;

    &.header {
      border-bottom: 1px solid $ff-storefront-dark;
    }
    &.midsum,
    &.sum {
      border-top: 1px solid $ff-storefront-dark;
    }

    &.header,
    &.sum,
    .amount {
      font-weight: bold;
    }

    .name {
      text-transform: uppercase;
    }
  }
}

.checkout-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  .primary-button {
    background: $ff-primary-black;
  }
}

:deep(.p-message) {
  margin-top: 30px;

  &.info {
    margin-top: 30px;
    border: solid $ff-primary-black;
    border-width: 0 0 0 6px;
    background: $ff-storefront-light;
    color: $ff-primary-black;

    svg {
      color: $ff-primary-black;
    }
  }

  .p-message-wrapper {
    padding: 10px 20px;
  }
}

form {
  .form-row {
    display: inline-flex;
    justify-content: flex-start;

    .p-checkbox {
      align-self: center;
      margin-top: 0;
      width: auto !important;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  form {
    .form-row {
      align-items: center;

      &.short {
        width: 100% !important;
      }
    }
  }

  .order-summary {
    .row {
      &.nosplit {
        span:first-child {
          width: 50%;
        }
        span:last-child {
          width: 50%;
        }
      }

      span:first-child {
        width: 70%;
      }
      span:last-child {
        width: 30%;
      }
    }
  }
}
</style>
