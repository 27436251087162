<template>
  <form @submit.prevent="">
    <Panel :header="header" class="actions">
      <slot></slot>
    </Panel>
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Simple price tag component.
 */
export default defineComponent({
  name: "ManagementButtonPanel",
  props: {
    header: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
</script>

<style scoped lang="scss">
.actions {
  :deep(.p-panel-content) {
    padding: 0 1.25rem;
  }

  :deep(.form-row) {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    min-height: 60px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 0;

      label,
      button {
        width: calc(50% - 20px);
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $ff-storefront-light;
    }

    > * {
      width: 300px;
    }
  }
}
</style>
