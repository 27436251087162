import { StoreApiProxy } from "./StoreApiProxy";
import { UserListViewModel, UserViewModel } from "@/viewModels/ViewModels";
import { UserModel } from "@/models/UserModel";
import { ObjectHelper } from "./ObjectHelper";
import { UserProfileApiController } from "@/api/UserProfileApiController";
import { OrderDto, OrderListDto } from "@/dtos/OrderDtos";
import { UserDto, UserListDto } from "@/dtos/UserDtos";
import { AddressDto } from "@/dtos/AddressDtos";
import { ChangePasswordData } from "@/dtos/data/ChangePasswordData";
import { CompletePasswordResetData } from "@/dtos/data/CompletePasswordResetData";
import { PasswordResetData } from "@/dtos/data/PasswordResetData";
import { CompleteRegisterData } from "@/dtos/data/CompleteRegisterData";

/**
 * Special adapted StoreApiProxy to fit all the user needs.
 */
export class StoreUserProfileApiProxy extends StoreApiProxy<UserModel, UserDto, UserListDto, UserViewModel, UserListViewModel> {
  private userProfileApiController: UserProfileApiController;

  constructor(apiController: UserProfileApiController) {
    super(apiController, UserListDto.mappings, UserDto.mappings);

    this.userProfileApiController = this.apiController as UserProfileApiController;
  }

  /* -------------------------------------------
  Send a GET request to retrieve all orders of a user.
  ------------------------------------------- */
  public async getOrders(listDto: OrderListDto): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .getOrders()
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, OrderListDto.mappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request to cancel an order for a user.
  ------------------------------------------- */
  public async cancelOrder(dto: OrderDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .cancelOrder(dto.model.id)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, OrderDto.mappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to change the password of a user.
  ------------------------------------------- */
  public async changePassword(dto: UserDto, data: ChangePasswordData): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .changePassword(data)
        .then((success) => {
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a POST request to start the password reset process for a user.
  ------------------------------------------- */
  public async startPasswordResetProcess(dto: UserDto, data: PasswordResetData): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .startPasswordResetProcess(data)
        .then((success) => {
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to complete the password reset process for a user.
  ------------------------------------------- */
  public async completePasswordResetProcess(dto: UserDto, data: CompletePasswordResetData): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .completePasswordResetProcess(data)
        .then((success) => {
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to complete the verificatoin process for a user.
  ------------------------------------------- */
  public async completeVerificationProcess(dto: UserDto, data: CompleteRegisterData): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .completeVerificationProcess(data)
        .then((success) => {
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a POST request to create a new user's address.
  ------------------------------------------- */
  public async createProfileAddress(dto: AddressDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .createProfileAddress(dto.model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, AddressDto.mappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to update a user's address.
  ------------------------------------------- */
  public async updateProfileAddress(dto: AddressDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .updateProfileAddress(dto.model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, AddressDto.mappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request to delete a user's address.
  ------------------------------------------- */
  public async deleteProfileAddress(dto: AddressDto): Promise<void> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userProfileApiController
        .deleteProfileAddress(dto.model.addressType)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, AddressDto.mappings);
          resolve();
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }
}
