<template>
  <div id="cookie-banner" :class="{ hide: !isCookieBannerVisible }">
    <div class="cookie-banner-content">
      <Translate text="STOREFRONT_COOKIEBANNER_DESCRIPTION" />
      <div class="buttons">
        <Button :label="Translator.translate('STOREFRONT_COOKIEBANNER_ACCEPT_NECESSARY_ONLY')" @click="acceptNecessaryCookies" severity="secondary"></Button>
        <Button :label="Translator.translate('STOREFRONT_COOKIEBANNER_ACCEPT_ALL')" @click="acceptAllCookies" severity="primary"></Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import Translate from "../shared/Translate.vue";
import { LocalStorageHelper } from "@/helpers/LocalStorageHelper";
import { Translator } from "@/helpers/Translator";
import { optIn, optOut } from "vue-gtag";

/**
 * Component that renders the Footer with social links and legal info in the Store Front.
 */
export default defineComponent({
  name: "StoreFrontCookieBanner",
  components: {
    Translate,
  },
  methods: {
    acceptNecessaryCookies() {
      LocalStorageHelper.setOptionalCookieAccepted(false);
      LocalStorageHelper.setNecessaryCookieAccepted(true);

      this.isCookieBannerVisible = false;

      this.updateCookieConsent();
    },
    acceptAllCookies() {
      LocalStorageHelper.setOptionalCookieAccepted(true);
      LocalStorageHelper.setNecessaryCookieAccepted(true);

      this.isCookieBannerVisible = false;

      this.updateCookieConsent();
    },
  },
  setup() {
    const isCookieBannerVisible = ref(!LocalStorageHelper.areNecessaryCookieAccepted());

    const updateCookieConsent = () => {
      // handle cookie consent.
      if (LocalStorageHelper.areOptionalCookieAccepted()) {
        console.log("opting in of cookies...");
        optIn();
      } else {
        console.log("opting out of cookies...");
        optOut();
      }
    };

    updateCookieConsent();

    return {
      Translator,
      isCookieBannerVisible,
      updateCookieConsent,
    };
  },
});
</script>

<style scoped lang="scss">
#cookie-banner {
  transition: transform 500ms ease;

  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 1px solid $ff-storefront-light;
  background: $ff-primary-white;
  z-index: 2;
  width: 100%;

  &.hide {
    transform: translateY(100%);

    &::after {
      opacity: 0;
    }
  }

  &::after {
    transition: opacity 300ms ease-out;

    display: block;
    position: absolute;
    top: 0;
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    content: "";
    pointer-events: none;
  }

  .cookie-banner-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    font-size: 0.8rem;

    .buttons {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      button {
        width: 150px;
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  #cookie-banner {
    .cookie-banner-content {
      flex-direction: column;
      row-gap: 10px;

      .buttons {
        button {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: $breakpoint-tablet) {
  #cookie-banner {
    .cookie-banner-content {
      .buttons {
        flex-direction: row;
        column-gap: 5px;

        button {
          width: 150px;
        }
      }
    }
  }
}
</style>
