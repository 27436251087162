import { StoreApiProxy } from "./StoreApiProxy";
import { UserListViewModel, UserViewModel } from "@/viewModels/ViewModels";
import { UserModel } from "@/models/UserModel";
import { ObjectHelper } from "./ObjectHelper";
import { OrderListDto } from "@/dtos/OrderDtos";
import { UserDto, UserListDto } from "@/dtos/UserDtos";
import { UserApiController } from "@/api/UserApiController";

/**
 * Special adapted StoreApiProxy to fit all the user needs.
 */
export class StoreUserApiProxy extends StoreApiProxy<UserModel, UserDto, UserListDto, UserViewModel, UserListViewModel> {
  private userApiController: UserApiController;

  constructor(apiController: UserApiController) {
    super(apiController, UserListDto.mappings, UserDto.mappings);

    this.userApiController = this.apiController as UserApiController;
  }

  /* -------------------------------------------
  Send a GET request to retrieve all orders of a user.
  ------------------------------------------- */
  public async getOrdersByUserId(listDto: OrderListDto, userId: number): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.userApiController
        .getOrdersByUserId(userId)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, OrderListDto.mappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }
}
