<template>
  <div ref="productCartEntryRef" class="product-cart-entry">
    <div class="stripes background-stripes"></div>
    <div class="amount">
      <h2>{{ model.amountOrdered }}</h2>
      <h3>x</h3>
    </div>
    <a class="info" @click="goToDetails()">
      <img :src="imageListDto.list.length > 0 ? imageListDto.list[0].model.imageData : require('@/assets/img/placeholder.jpg')" />
      <div class="details">
        <h2>
          {{ model.product.name() }} <em>{{ model.product.subname() }}</em>
        </h2>
        <ProductPrice :value="model.product.price"></ProductPrice>
      </div>
    </a>
    <div class="buttons">
      <Button class="amount-button" :loading="isAddingToCart" :label="isAddingToCart ? 'a' : '+'" @click="addToCart()" :disabled="!canOrderMore"></Button>
      <Button class="amount-button" :loading="isAddingToCart" :label="isAddingToCart ? 'a' : '-'" @click="removeFromCart()"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, watchEffect } from "vue";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import colorMixins from "@/mixins/colorMixins";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { OrderProductModel } from "@/models/OrderProductModel";
import { Translator } from "@/helpers/Translator";
import { useProductStore } from "@/stores/productStore";
import { ProductImageListDto } from "@/dtos/ProductDtos";

/**
 * Component that renders a product as a cart entry.
 */
export default defineComponent({
  name: "ProductCartEntry",
  props: {
    model: {
      type: OrderProductModel,
      required: true,
    },
  },
  components: { LoadingSkeleton, ProductPrice },
  methods: {
    addToCart() {
      this.addToCartAmount(1);
    },

    removeFromCart() {
      const userStatusStore = useUserStatusStore();

      const currentAmountInCart = userStatusStore.getAmountInCart(this.model.product.id);
      if (currentAmountInCart <= 1) {
        this.isAddingToCart = true;
        userStatusStore.removeProductFromCart(this.model.product.id).catch(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 6000,
            severity: "error",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_REMOVE_FROM_CART_FAILED_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_REMOVE_FROM_CART_FAILED_DESCRIPTION"),
          });
        });
      } else {
        this.addToCartAmount(-1);
      }
    },

    addToCartAmount(amountToAdd: number) {
      const userStatusStore = useUserStatusStore();

      this.isAddingToCart = true;

      const currentAmount = userStatusStore.getAmountInCart(this.model.product.id);

      userStatusStore
        .updateProductCartAmount(this.model.product.id, currentAmount + amountToAdd)
        .then(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 3000,
            severity: "success",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_DESCRIPTION"),
          });
        })
        .catch(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 6000,
            severity: "error",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_DESCRIPTION"),
          });
        });
    },
    goToDetails() {
      this.$router.push("products/" + this.model.product.id);
    },
  },
  setup(props) {
    const productStore = useProductStore();
    const toast = useToast();

    // get entity from props.
    const { model } = toRefs(props);
    const imageListDto = ref(new ProductImageListDto([]));

    // use local loading state, so that each product can be added separately.
    const isAddingToCart = ref(false);
    const canOrderMore = computed(() => model.value.amountOrdered <= model.value.product.stock);

    // set css colors based on selected theme.
    const productCartEntryRef = ref(null);
    watchEffect(() => {
      if (productCartEntryRef.value && model.value.product.colorTheme) {
        const productCartEntryElement = productCartEntryRef.value as HTMLElement;
        productCartEntryElement.style.setProperty("--primary-theme-color", colorMixins.getPackageColorFromTheme(model.value.product.colorTheme));
        productCartEntryElement.style.setProperty("--secondary-theme-color", colorMixins.getLabelColorFromTheme(model.value.product.colorTheme));
      }
    });

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    onMounted(() => {
      productStore.getProductImages(model.value.product.id, imageListDto.value);
    });

    return {
      model,
      isAddingToCart,
      canOrderMore,
      productCartEntryRef,
      showToast,
      imageListDto,
    };
  },
});
</script>

<style scoped lang="scss">
.product-cart-entry {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: calc(100% - 20px);
  height: 120px;
  background-color: $ff-primary-white;
  border-bottom: 1px solid $ff-primary-black;

  .background-stripes {
    position: absolute;
    background-color: var(--primary-theme-color);
    top: -20px;
    right: -20px;
    left: unset;
    height: 140px;
    width: unset;
  }

  a {
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .amount {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 80px;
    color: $ff-primary-white;
    background-color: var(--primary-theme-color);

    h2 {
      padding-top: calc(40%);
    }
  }

  .info {
    width: calc(100% - 160px);
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    img {
      width: 20%;
      padding: 10px 0;
      object-fit: contain;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .price {
        justify-content: flex-start;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 80px;
    border-left: 1px solid $ff-primary-black;

    > .p-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 2rem;
      background: none;
      color: $ff-primary-black;
      font-family: "FFIcons";
      padding: 0;

      &.p-button-loading {
        :deep(.p-button-label) {
          display: none;
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:first-child {
        border-bottom: 1px solid $ff-primary-black;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .product-cart-entry {
    column-gap: 10px;
    height: 90px;

    .background-stripes {
      height: 110px;
    }
    .amount {
      width: 40px;

      h2 {
        font-size: 2rem;
        line-height: 1.8rem;
        padding-top: 28px;
      }

      h3 {
        font-size: 1.4rem;
      }
    }

    .info {
      width: calc(100% - 50px);
      column-gap: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      img {
        max-width: 50px;
      }

      .details {
        width: calc(100% - 50px);
        justify-content: center;

        h2 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 2rem;
        }
      }
    }

    .buttons {
      width: 50px;
    }
  }
}
</style>
