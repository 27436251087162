<template>
  <div class="title-panel">
    <h2>Produkt bearbeiten</h2>
  </div>

  <ProductsCreateEdit :id="id" :dto="dto"></ProductsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ProductDto } from "@/dtos/ProductDtos";
import { ProductModel } from "@/models/ProductModel";
import { useProductStore } from "@/stores/productStore";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import ProductsCreateEdit from "@/components/controlpanel/products/ProductsCreateEdit.vue";

/**
 * View that renders the the "edit" form for products.
 */
export default defineComponent({
  name: "ProductsEdit",
  components: { ProductsCreateEdit },
  setup() {
    const store = useProductStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/products");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new ProductDto(new ProductModel()));

    // get entity from store.
    onMounted(() => {
      store.getProduct(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
});
</script>

<style scoped lang="scss"></style>
