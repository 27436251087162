<template>
  <Panel header="Metadaten" v-if="dto && dto.model">
    <div class="form-row split">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.createdAt" />
        <label for="createdAt">Erstellt am</label>
      </span>

      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <Calendar v-if="dto.model.updatedAt" id="updatedAt" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.updatedAt" />
        <InputText v-else type="text" id="updatedAt" :disabled="true" value="-" />
        <label for="updatedAt" class="p-float">Aktualisiert am</label>
      </span>
    </div>
    <div class="form-row split" v-if="dto.model.isMigrated">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <Calendar id="migrationDate" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.migrationDate" />
        <label for="migrationDate">Migriert am</label>
      </span>

      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <InputText type="text" id="migrationId" :disabled="true" v-model="dto.model.migrationId" />
        <label for="migrationId">Migration Id</label>
      </span>
    </div>
  </Panel>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import Translate from "../shared/Translate.vue";
import { ProductDto } from "@/dtos/ProductDtos";
import { UserDto } from "@/dtos/UserDtos";
import { CouponDto } from "@/dtos/CouponDtos";
import { OrderDto } from "@/dtos/OrderDtos";

/**
 * Shows metadata information about the entity.
 */
export default defineComponent({
  name: "ControlPanelMetadataPanel",
  components: { Translate },
  props: {
    dto: {
      type: [UserDto, ProductDto, CouponDto, OrderDto],
      required: true,
    },
  },
  methods: {},
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-bottom: 40px;
}
</style>
