import { ModelDto } from "./base/ModelDto";
import { CountryModel } from "@/models/CountryModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class CountryDto extends ModelDto<CountryModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.CountryModel(x),
    },
  ];
}

class CountryListDto extends ListDto<CountryDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.CountryDto(y)),
    },
  ];
}

export { CountryDto, CountryListDto };
