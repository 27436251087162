import { TranslationDto } from "@/dtos/TranslationDtos";
import { Language } from "@/enums/Language";
import { useTranslationStore } from "@/stores/translationStore";

const VARIABLE_REGEX = /%[sS]/g;

export const Translator = {
  getTransaltionDtoByKey(key: string): TranslationDto | null {
    const translationStore = useTranslationStore();
    const translation = translationStore.listDto.list.find((t) => t.model.translationKey === key);
    return translation ? translation : null;
  },

  translateFormat(key: string, ...variables: (string | number)[]): string {
    let translatedText = key;

    // translate text first.
    const translation = this.getTransaltionDtoByKey(key);
    if (translation) {
      translatedText = this.translateDto(translation);
    }

    // replaces variables next.
    for (let i = 0; i < variables.length; i++) {
      let matches = Array.from(translatedText.matchAll(VARIABLE_REGEX));

      // replace placeholder with variable.
      if (matches.length > 0 && matches[0].index !== undefined) {
        let matchIndex = matches[0].index;
        translatedText = translatedText.substring(0, matchIndex) + variables[i] + translatedText.substring(matchIndex + 2);
      }
    }

    return translatedText;
  },

  translate(text: string): string {
    return this.translateFormat(text);
  },

  translateDto(dto: TranslationDto): string {
    const translationStore = useTranslationStore();

    let translatedText = dto.model.translationKey;
    if (dto) {
      switch (translationStore.currentLanguage) {
        case Language.DE:
          translatedText = dto.model.languageDe;
          break;
        case Language.FR:
          translatedText = dto.model.languageFr;
          break;
      }
    }
    return translatedText;
  },
};
