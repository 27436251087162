<template>
  <div class="inner">
    <h2><Translate text="STOREFRONT_FORMS_PROFILE" /></h2>
    <p><Translate text="STOREFRONT_FORMS_PROFILE_HINT" /></p>

    <h3 class="first"><Translate text="STOREFRONT_FORMS_PROFILE_PERSONALITIES" /></h3>

    <div class="split-panel">
      <Panel :header="Translator.translate('STOREFRONT_FORMS_PROFILE_PERSONALDATA_HEADER')">
        <template #icons>
          <button class="p-panel-header-icon p-link mr-2" @click="goToEditProfile()">
            <span class="pi pi-pencil"></span>
          </button>
        </template>
        <div v-if="dto.model">
          <div class="entry">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_DISPLAYNAME" /></span>
            <span>{{ dto.model.displayName }}</span>
          </div>
          <div class="entry">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_EMAIL" /></span>
            <span>{{ dto.model.username }}</span>
          </div>
          <div class="entry">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_LANGUAGE" /></span>
            <span>{{ Language[dto.model.language] }}</span>
          </div>
          <div class="entry">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_EMAIL" /></span>
            <span><Translate :text="dto.model.hasNewsletter ? 'STOREFRONT_GENERAL_YES' : 'STOREFRONT_GENERAL_NO'" /></span>
          </div>
          <div class="entry">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_REGISTERED_AT" /></span>
            <span><DateDisplay :date="dto.model.createdAt"></DateDisplay></span>
          </div>
          <div class="entry" v-if="dto.model.lastLoginDate">
            <span><Translate text="STOREFRONT_FORMS_PROFILE_LAST_SIGNIN" /></span>
            <span><DateDisplay :date="dto.model.lastLoginDate"></DateDisplay></span>
          </div>
        </div>
        <div v-else>
          <p class="m-0"><LoadingSkeleton></LoadingSkeleton></p>
        </div>
      </Panel>

      <AddressPanel
        :model="(dto.model.shippingAddress as AddressModel)"
        :header="Translator.translate('STOREFRONT_FORMS_PROFILE_SHIPPING_ADDRESS_HEADER')"
        :showEditButton="true"
        @goToEdit="goToEditAddresses()"
      ></AddressPanel>
    </div>

    <h3 class="first"><Translate text="STOREFRONT_FORMS_PROFILE_ORDERS_HEADER" /></h3>
    <div class="orders">
      <OrdersDataTable :listDto="ordersDto" details-link="/profile/orders/" :footer-text="Translator.translateFormat('STOREFRONT_FORMS_PROFILE_ORDERS_COUNT', ordersDto.list.length)"></OrdersDataTable>
    </div>

    <h3 class="first"><Translate text="STOREFRONT_FORMS_PROFILE_MANAGEMENT" /></h3>
    <ManagementButtonPanel :header="Translator.translate('STOREFRONT_FORMS_PROFILE_OTHERS_HEADER')">
      <div class="form-row split">
        <label><Translate text="STOREFRONT_FORMS_PROFILE_MANAGEMENT_USERID" /></label>
        <label>{{ dto.model.id }}</label>
      </div>
      <div class="form-row split">
        <label><Translate text="STOREFRONT_FORMS_PROFILE_MANAGEMENT_PASSWORD" /></label>
        <Button :label="Translator.translate('STOREFRONT_FORMS_PROFILE_MANAGEMENT_CHANGE_PASSWORD')" :severity="'info'" @click="goToChangePassword()"></Button>
      </div>
    </ManagementButtonPanel>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { Validator } from "@/helpers/Validator";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { AddressModel } from "@/models/AddressModel";
import AddressPanel from "@/components/storefront/users/AddressPanel.vue";
import { OrderListDto } from "@/dtos/OrderDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import OrdersDataTable from "@/components/shared/OrdersDataTable.vue";
import ManagementButtonPanel from "@/components/shared/ManagementButtonPanel.vue";
import { Language } from "@/enums/Language";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import stringMixins from "@/mixins/stringMixins";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "StoreFrontUserProfile",
  components: {
    FFButton,
    LoadingSkeleton,
    AddressPanel,
    DateDisplay,
    OrdersDataTable,
    ManagementButtonPanel,
    Translate,
  },
  methods: {
    goToEditProfile() {
      this.$router.push(`/profile/edit`);
    },

    goToEditAddresses() {
      this.$router.push(`/profile/edit/addresses`);
    },

    goToChangePassword() {
      this.$router.push(`/profile/edit/password`);
    },
  },
  setup() {
    const validator = new Validator();
    const userStatusStore = useUserStatusStore();
    const userProfileStore = useUserProfileStore();

    const dto = computed(() => userStatusStore.userDto);

    const ordersDto = ref(new OrderListDto([]));

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_PROFILE")),
    });

    // refresh user status and get orders at this point.
    onMounted(() => {
      userStatusStore.getUserStatus().then(() => userProfileStore.getOrders(ordersDto.value));
    });

    return {
      validator,
      dto,
      ordersDto,
      Language,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

:deep(.p-panel) {
  .p-panel-header {
    padding: 0.7rem 1.25rem;
  }

  .entry {
    display: flex;
    column-gap: 20px;

    span {
      margin-bottom: 5px;

      &:first-child {
        width: 200px;
        font-weight: bold;
      }
    }
  }
}

.split-panel {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
  height: min-content;

  > * {
    width: calc(50% - 10px);

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    &.p-panel {
      margin-top: 0;

      :deep(.p-toggleable-content),
      :deep(.p-panel-content) {
        height: calc(100% - 2rem);
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  :deep(.p-panel) {
    .entry {
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 10px;

      span {
        width: 100%;
      }
    }
  }
}
</style>
