import { SortDirection } from "@/enums/SortDirection";
import { BaseDto } from "./BaseDto";
import { BaseListDto } from "./BaseListDto";

export class ListDto<T> implements BaseDto, BaseListDto {
  public list: T[];

  public isDirty: boolean;
  public isLoading: boolean;
  public hasError: boolean;
  public validationErrorFields: string[];

  public filter: string | null;
  public enumFilter: number | null;
  public sortColumn: string | null;
  public sortDirection: SortDirection;
  public page: number;
  public pageSize: number;
  public pageCount: number;
  public totalRecordCount: number;

  constructor(source: T[]) {
    this.list = source;
    this.isLoading = false;
    this.isDirty = false;
    this.hasError = false;
    this.validationErrorFields = [];

    // set default datatable properties.
    this.filter = null;
    this.enumFilter = null;
    this.sortColumn = null;
    this.sortDirection = SortDirection.ASC;
    this.page = -1;
    this.pageSize = 10;
    this.pageCount = -1;
    this.totalRecordCount = 0;
  }
}
