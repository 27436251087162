import { Constants } from "@/enums/Constants";

const fallbackColors = {
  package: "var(--ff-cherry-stripe-package)",
  label: "var(--ff-cherry-stripe-label)",
};

/**
 * Helper methods for color-related operations.
 */
export default {
  /* -------------------------------------------
  Returns the SCSS variable of the package color for the given color theme.
  ------------------------------------------- */
  getPackageColorFromTheme(theme: string): string {
    return Constants.ColorThemes.find((t) => t.name === theme)?.packageColor ?? fallbackColors.package;
  },

  /* -------------------------------------------
  Returns the SCSS variable of the label color for the given color theme.
  ------------------------------------------- */
  getLabelColorFromTheme(theme: string): string {
    return Constants.ColorThemes.find((t) => t.name === theme)?.labelColor ?? fallbackColors.label;
  },
};
