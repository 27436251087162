import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { CouponApiController } from "@/api/CouponApiController";
import { CouponListDto } from "@/dtos/CouponDtos";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponListViewModel, CouponViewModel } from "@/viewModels/ViewModels";
import { CouponModel } from "@/models/CouponModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { FilterableState } from "@/dtos/state/FilterableState";

const apiController = new CouponApiController();
const storeApiProxy = new StoreApiProxy<CouponModel, CouponDto, CouponListDto, CouponViewModel, CouponListViewModel>(apiController, CouponListDto.mappings, CouponDto.mappings);

export const useCouponStore = defineStore("coupon", {
  state: (): FilterableState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all coupons from API.
     * -------------------------------------------- */
    async getCoupons(listDto: CouponListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single coupon from API.
     * -------------------------------------------- */
    async getCoupon(dto: CouponDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Get status for coupon from API.
     * -------------------------------------------- */
    async getCouponStatus(dto: CouponDto, code: string): Promise<void> {
      return storeApiProxy.apiGetSingleStatus(dto, code);
    },

    /* -------------------------------------------
     * Saves one coupon DTO.
     * -------------------------------------------- */
    async updateCoupon(dto: CouponDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new coupon DTO.
     * -------------------------------------------- */
    async createCoupon(dto: CouponDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing coupon DTO.
     * -------------------------------------------- */
    async deleteCoupon(dto: CouponDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: CouponListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: CouponListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: CouponListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: CouponListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: CouponListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: CouponListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
