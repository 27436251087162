import { Constants } from "@/enums/Constants";
import { ReturnCode } from "@/enums/ReturnCode";
import { AxiosResponse } from "axios";
import { ObjectHelper } from "./ObjectHelper";
import { baseResponseMappings } from "./TypeHelper";
import { RegisterReturnCode } from "@/enums/RegisterReturnCode";
import { LoginReturnCode } from "@/enums/LoginReturnCode";

/**
 * Helper class to evaluate responses from the API.
 */
export const ApiHelper = {
  /* -------------------------------------------
  Resolves a response if the request was accepted by the API. Rejects it otherwise.
  ------------------------------------------- */
  processResponse<T>(response: AxiosResponse<T>): Promise<AxiosResponse<T>> {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      ObjectHelper.copyExistingPropsFromTo(response.data, response.data, baseResponseMappings);

      if (this.isSuccess(response)) {
        resolve(response);
      } else if (this.isFailure(response)) {
        reject(response);
      } else {
        reject(response);
      }
    });
  },

  isSuccess(response: AxiosResponse): boolean {
    // handle special cases.
    if (response.data.registerReturnCode && response.data.registerReturnCode !== RegisterReturnCode.USER_REGISTERED) return false;
    if (response.data.loginReturnCode && response.data.loginReturnCode !== LoginReturnCode.USERNAMEPASSWORD_OK) return false;

    return response.status === Constants.StatusCodes.SUCCESS && response.data.returnCode === ReturnCode.OK;
  },
  isFailure(response: AxiosResponse): boolean {
    // handle special cases.
    if (response.data.registerReturnCode && response.data.registerReturnCode !== RegisterReturnCode.USER_REGISTERED) return true;
    if (response.data.loginReturnCode && response.data.loginReturnCode !== LoginReturnCode.USERNAMEPASSWORD_OK) return true;

    return response.status === Constants.StatusCodes.SUCCESS && response.data.returnCode !== ReturnCode.OK;
  },
};
