import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { LogEntryApiController } from "@/api/LogEntryApiController";
import { LogEntryListDto } from "@/dtos/LogEntryDtos";
import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { LogEntryListViewModel, LogEntryViewModel } from "@/viewModels/ViewModels";
import { LogEntryModel } from "@/models/LogEntryModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { FilterableState } from "@/dtos/state/FilterableState";

const apiController = new LogEntryApiController();
const storeApiProxy = new StoreApiProxy<LogEntryModel, LogEntryDto, LogEntryListDto, LogEntryViewModel, LogEntryListViewModel>(apiController, LogEntryListDto.mappings, LogEntryDto.mappings);

export const useLogEntryStore = defineStore("logEntry", {
  state: (): FilterableState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all logEntries from API.
     * -------------------------------------------- */
    async getLogEntries(listDto: LogEntryListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single logEntry from API.
     * -------------------------------------------- */
    async getLogEntry(dto: LogEntryDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one logEntry DTO.
     * -------------------------------------------- */
    async updateLogEntry(dto: LogEntryDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new logEntry DTO.
     * -------------------------------------------- */
    async createLogEntry(dto: LogEntryDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing logEntry DTO.
     * -------------------------------------------- */
    async deleteLogEntry(dto: LogEntryDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: LogEntryListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: LogEntryListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: LogEntryListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: LogEntryListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: LogEntryListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: LogEntryListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
