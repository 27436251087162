import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";
import { ChangePasswordData } from "./ChangePasswordData";

export class ChangePasswordFormData extends BaseFormData<ChangePasswordData> implements ChangePasswordData {
  public oldPassword: string;
  public newPassword1: string;
  public newPassword2: string;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    this.isInitializedProperly = true;

    this.oldPassword = "";
    this.newPassword1 = "";
    this.newPassword2 = "";

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      oldPassword: Yup.string().required(),
      newPassword1: Yup.string().required(),
      newPassword2: Yup.string().required(),
    });
  }
}
