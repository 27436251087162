import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ICountryModel } from "./interfaces/ICountryModel";
import { Language } from "@/enums/Language";
import { useTranslationStore } from "@/stores/translationStore";

export class CountryModel extends BaseModel implements ICountryModel {
  public nameDe: string;
  public nameFr: string;

  constructor() {
    super();

    this.nameDe = "";
    this.nameFr = "";
  }

  public name(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.nameDe : this.nameFr;
  };

  public static readonly mappings: TypeMapping[] = [];
}
