<template>
  <HomeSlider></HomeSlider>

  <HighlightProductReel></HighlightProductReel>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeSlider from "@/components/storefront/landingpage/HomeSlider.vue";
import HighlightProductReel from "@/components/storefront/landingpage/HighlightProductReel.vue";

export default defineComponent({
  name: "StoreFrontLandingPage",
  components: {
    HomeSlider,
    HighlightProductReel,
  },
  setup() {

    return {};
  },
});
</script>

<style scoped lang="scss"></style>
