/**
 * Describes the order and order product delivery status. 
 * It goes in that order: UNPROCESSED -> PARTIALLY_DELIVERED -> DELIVERED (-> CANCELED).
 */
export enum DeliveryStatus {
  NONE = 0,
  UNPROCESSED = 1,
  PARTIALLY_DELIVERED = 2,
  DELIVERED = 3,
  CANCELED = 4,
}
