import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { ContactFormEntryApiController } from "@/api/ContactFormEntryApiController";
import { ContactFormEntryListDto } from "@/dtos/ContactFormEntryDtos";
import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { ContactFormEntryListViewModel, ContactFormEntryViewModel } from "@/viewModels/ViewModels";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { FilterableState } from "@/dtos/state/FilterableState";

const apiController = new ContactFormEntryApiController();
const storeApiProxy = new StoreApiProxy<ContactFormEntryModel, ContactFormEntryDto, ContactFormEntryListDto, ContactFormEntryViewModel, ContactFormEntryListViewModel>(
  apiController,
  ContactFormEntryListDto.mappings,
  ContactFormEntryDto.mappings
);

export const useContactFormEntryStore = defineStore("contactFormEntry", {
  state: (): FilterableState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all contactFormEntries from API.
     * -------------------------------------------- */
    async getContactFormEntries(listDto: ContactFormEntryListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single contactFormEntry from API.
     * -------------------------------------------- */
    async getContactFormEntry(dto: ContactFormEntryDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one contactFormEntry DTO.
     * -------------------------------------------- */
    async updateContactFormEntry(dto: ContactFormEntryDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new contactFormEntry DTO.
     * -------------------------------------------- */
    async createContactFormEntry(dto: ContactFormEntryDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing contactFormEntry DTO.
     * -------------------------------------------- */
    async deleteContactFormEntry(dto: ContactFormEntryDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: ContactFormEntryListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: ContactFormEntryListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: ContactFormEntryListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: ContactFormEntryListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: ContactFormEntryListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: ContactFormEntryListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
