import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeSlider = _resolveComponent("HomeSlider")!
  const _component_HighlightProductReel = _resolveComponent("HighlightProductReel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeSlider),
    _createVNode(_component_HighlightProductReel)
  ], 64))
}