<template>
  {{ dateAsString }}
</template>

<script lang="ts">
import stringMixins from "@/mixins/stringMixins";
import { computed, defineComponent, toRefs } from "vue";

/**
 * Simple date render component.
 */
export default defineComponent({
  name: "DateDisplay",
  props: {
    date: {
      type: Date,
      required: false,
    },
    dateString: {
      type: String,
      required: false,
    },
    withTime: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { date, dateString, withTime } = toRefs(props);

    if (!props.date && !props.dateString) console.error("At least one date needs to be present");

    const dateAsString = computed(() => stringMixins.renderDate(new Date(date.value || dateString.value || new Date()), withTime.value));

    return {
      dateAsString,
    };
  },
});
</script>

<style scoped lang="scss"></style>
