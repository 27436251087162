<template>
  <ControlPanelHeader></ControlPanelHeader>
  <div id="controlpanel">
    <ControlPanelNavigation></ControlPanelNavigation>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import ControlPanelNavigation from "@/components/controlpanel/ControlPanelNavigation.vue";
import ControlPanelHeader from "@/components/controlpanel/ControlPanelHeader.vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "ControlPanel",
  components: {
    ControlPanelNavigation,
    ControlPanelHeader,
  },
  setup() {
    // dont index control panel pages.
    useHead({
      title: () => stringMixins.formatSeoTitle("Control Panel"),
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    return {};
  },
});
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}

.p-toast {
  &.control-panel-toast {
    .p-toast-message {
      box-shadow: 0 0 10px 5px $ff-controlpanel-light;
    }
  }

  button {
    margin-top: 10px;
  }
}

#controlpanel {
  display: flex;
  margin-bottom: 40px;

  .content {
    width: calc(100% - 240px);
    padding: 20px;
    margin: 100px 0 0 240px;

    .title-panel {
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 2rem;
      gap: 0.5rem;
    }

    .p-datatable-tbody {
      tr {
        &:hover {
          background: $ff-storefront-light;
        }
      }
    }

    .button-panel {
      display: flex;
      justify-content: space-between;

      button:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  #controlpanel {
    display: block;

    .content {
      width: 100%;
      margin: 0;

      .title-panel {
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
      }
    }
  }
}
</style>
