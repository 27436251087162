import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { CountryModel } from "./CountryModel";
import { IAddressModel } from "./interfaces/IAddressModel";
import { TypeHelper } from "@/helpers/TypeHelper";
import { AddressType } from "@/enums/AddressType";

export class AddressModel extends BaseModel implements IAddressModel {
  public firstname: string;
  public surname: string;
  public companyName?: string | null;
  public countryId: number;
  public street: string;
  public streetNumber: string;
  public additional?: string | null;
  public zipCode: number | null;
  public city: string;
  public telephoneNumber?: string | null;
  public userId?: number | null;
  public orderId?: number | null;
  public addressType: AddressType;
  public isMigrated: boolean;
  public migrationDate?: Date | null;

  country: CountryModel;

  constructor() {
    super();

    this.firstname = "";
    this.surname = "";
    this.companyName = null;
    this.countryId = -1;
    this.street = "";
    this.streetNumber = "";
    this.additional = null;
    this.zipCode = null;
    this.city = "";
    this.telephoneNumber = null;
    this.userId = null;
    this.orderId = null;
    this.addressType = AddressType.NONE;
    this.country = new CountryModel();
    this.isMigrated = false;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "country",
      cast: (x) => TypeHelper.mappers.CountryModel(x),
    },
  ];
}
