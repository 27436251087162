import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f91b774"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "checkout-buttons" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Message = _resolveComponent("Message")!
  const _component_FFButton = _resolveComponent("FFButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isValidOrderConfirmed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", null, [
            _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED" })
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED_THANKYOU" })
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED_ORDER_NUMBER" }),
            _createElementVNode("span", null, [
              _createVNode(_component_router_link, {
                to: `/profile/orders/${_ctx.orderId}`,
                class: "clickable"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.orderReference), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED_DESCRIPTION" })
          ]),
          _createVNode(_component_Message, {
            class: "info",
            severity: "info",
            closable: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED_ORDER_NUMBER_HINT" })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FFButton, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToLandingPage())),
              label: _ctx.Translator.translate('STOREFRONT_ORDERCONFIRMED_BACK_TO_LANDINGPAGE'),
              color: "var(--ff-primary-black)"
            }, null, 8, ["label"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, [
            _createVNode(_component_Translate, { text: "STOREFRONT_ORDERCONFIRMED_NOTHING_ORDERED" })
          ])
        ]))
  ]))
}