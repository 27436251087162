import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IProductImageModel } from "./interfaces/IProductImageModel";

export class ProductImageModel extends BaseModel implements IProductImageModel {
  productId!: number;
  imageData: string;
  sortOrder: number;
  _reference?: string | null;
  public isMigrated: boolean;
  public migrationDate?: Date | null;

  constructor() {
    super();

    this.imageData = "";
    this.sortOrder = -1;
    this.isMigrated = false;
  }

  public static readonly mappings: TypeMapping[] = [];
}
