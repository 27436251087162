import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";
import { CompleteRegisterData } from "./CompleteRegisterData";

export class CompleteRegisterFormData extends BaseFormData<CompleteRegisterData> implements CompleteRegisterData {
  public username: string;
  public verificationCode: string;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    this.isInitializedProperly = true;

    this.username = "";
    this.verificationCode = "";

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      username: Yup.string().email().required(),
      verificationCode: Yup.string().required(),
    });
  }
}
