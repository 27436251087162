import { CountryDto } from "@/dtos/CountryDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { CountryListViewModel, CountryViewModel } from "@/viewModels/ViewModels";
import { CountryModel } from "@/models/CountryModel";

export class CountryApiController extends BaseApiController<CountryModel, CountryDto, CountryViewModel, CountryListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.COUNTRIES);
  }
}
