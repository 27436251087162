import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "controlpanel" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlPanelHeader = _resolveComponent("ControlPanelHeader")!
  const _component_ControlPanelNavigation = _resolveComponent("ControlPanelNavigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ControlPanelHeader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ControlPanelNavigation),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}