import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { toRaw } from "vue";
import { IUserModel } from "@/models/interfaces/IUserModel";
import { UserModel } from "@/models/UserModel";
import { AddressModel } from "@/models/AddressModel";
import { Language } from "@/enums/Language";
import { AddressType } from "@/enums/AddressType";

export class UserFormData extends BaseFormData<IUserModel> implements IUserModel {
  public displayName: string;
  public hasNewsletter: boolean;
  public language: Language;
  public shippingAddress!: AddressModel;

  public originalData: UserModel;

  constructor(dto: UserDto, includeAddress: boolean) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.displayName = dto.model.displayName;
    this.hasNewsletter = dto.model.hasNewsletter;
    this.language = dto.model.language;

    if (includeAddress) {
      this.shippingAddress = dto.model.shippingAddress || new AddressModel();

      // address is not existing, initialize with "new" id.
      if (isNaN(this.shippingAddress.id)) {
        this.shippingAddress.id = -1;
      }
    }

    this.originalData = structuredClone(toRaw(dto.model));

    if (includeAddress) {
      this.formSchema = Yup.object({
        displayName: Yup.string().required(),
        hasNewsletter: Yup.boolean().required(),
        language: Yup.mixed<Language>().required(),
        shippingAddress: Yup.object({
          firstname: Yup.string().required(),
          surname: Yup.string().required(),
          companyName: Yup.string().optional().nullable(),
          countryId: Yup.number().required().positive(),
          street: Yup.string().required(),
          streetNumber: Yup.string().optional().nullable(),
          additional: Yup.string().optional().nullable(),
          zipCode: Yup.number().required(),
          city: Yup.string().required(),
          telephoneNumber: Yup.string().optional().nullable(),
          addressType: Yup.mixed<AddressType>().required(),
        }).required(),
      });
    } else {
      this.formSchema = Yup.object({
        displayName: Yup.string().required(),
        hasNewsletter: Yup.boolean().required(),
        language: Yup.mixed<Language>().required(),
      });
    }
  }
}
