import { defineStore } from "pinia";
import { OrderDto, OrderListDto } from "@/dtos/OrderDtos";
import { UserDto } from "@/dtos/UserDtos";
import { UserProfileApiController } from "@/api/UserProfileApiController";
import { StoreUserProfileApiProxy } from "@/helpers/StoreUserProfileApiProxy";
import { AddressDto } from "@/dtos/AddressDtos";
import { ChangePasswordData } from "@/dtos/data/ChangePasswordData";
import { PasswordResetData } from "@/dtos/data/PasswordResetData";
import { CompletePasswordResetData } from "@/dtos/data/CompletePasswordResetData";
import { CompleteRegisterData } from "@/dtos/data/CompleteRegisterData";

const apiController = new UserProfileApiController();
const storeApiProxy = new StoreUserProfileApiProxy(apiController);

export const useUserProfileStore = defineStore("userProfile", {
  actions: {
    /* -------------------------------------------
     * Saves one user DTO.
     * -------------------------------------------- */
    async updateUser(dto: UserDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Get all orders for a user from API.
     * -------------------------------------------- */
    async getOrders(listDto: OrderListDto): Promise<void> {
      return storeApiProxy.getOrders(listDto);
    },

    /* -------------------------------------------
     * Get all orders for a user from API.
     * -------------------------------------------- */
    async cancelOrder(dto: OrderDto): Promise<void> {
      return storeApiProxy.cancelOrder(dto);
    },

    /* -------------------------------------------
     * Change the password of a user.
     * -------------------------------------------- */
    async changePassword(dto: UserDto, data: ChangePasswordData): Promise<void> {
      return storeApiProxy.changePassword(dto, data);
    },

    /* -------------------------------------------
     * Starts the password reset process for a user.
     * -------------------------------------------- */
    async startPasswordResetProcess(dto: UserDto, data: PasswordResetData): Promise<void> {
      return storeApiProxy.startPasswordResetProcess(dto, data);
    },

    /* -------------------------------------------
     * Completes the password reset process for a user.
     * -------------------------------------------- */
    async completePasswordResetProcess(dto: UserDto, data: CompletePasswordResetData): Promise<void> {
      return storeApiProxy.completePasswordResetProcess(dto, data);
    },

    /* -------------------------------------------
     * Completes the verification process for a user.
     * -------------------------------------------- */
    async completeVerificationProcess(dto: UserDto, data: CompleteRegisterData): Promise<void> {
      return storeApiProxy.completeVerificationProcess(dto, data);
    },

    /* -------------------------------------------
     * Creates one profile address DTO.
     * -------------------------------------------- */
    async createProfileAddress(dto: AddressDto): Promise<void> {
      return storeApiProxy.createProfileAddress(dto);
    },

    /* -------------------------------------------
     * Saves one profile address DTO.
     * -------------------------------------------- */
    async updateProfileAddress(dto: AddressDto): Promise<void> {
      return storeApiProxy.updateProfileAddress(dto);
    },

    /* -------------------------------------------
     * Deletes one profile address DTO.
     * -------------------------------------------- */
    async deleteProfileAddress(dto: AddressDto): Promise<void> {
      return storeApiProxy.deleteProfileAddress(dto);
    },
  },
});
