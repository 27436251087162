<template>
  <div class="inner">
    <router-link to="/profile" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_PROFILE" /></span>
    </router-link>

    <h2><Translate text="STOREFRONT_FORMS_PROFILE_EDIT" /></h2>

    <div v-if="form.isReady()">
      <form @submit.prevent="" @change="validator.validateForm(form as UserFormData, dto)">
        <Panel :header="Translator.translate('STOREFRONT_FORMS_PROFILE_PERSONALDATA_HEADER')">
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <InputText type="text" id="username" :disabled="true" v-model="dto.model.username" />
              <label for="username"><Translate text="STOREFRONT_FORMS_PROFILE_EMAIL" /></label>
            </span>
          </div>

          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card"></i>
              <InputText
                type="text"
                id="displayName"
                name="displayName"
                autocomplete="name"
                :disabled="dto.isLoading"
                v-model="form.displayName"
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'displayName') }"
              />
              <label for="displayName"><Translate text="STOREFRONT_FORMS_PROFILE_DISPLAYNAME" /></label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'displayName')"><Translate text="STOREFRONT_FORMS_PROFILE_DISPLAYNAME_VALIDATION" /></small>
          </div>

          <div class="form-row split">
            <div>
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-id-card"></i>

                <Dropdown
                  v-model="form.language"
                  :options="languageOptions"
                  :disabled="dto.isLoading"
                  option-value="value"
                  placeholder="Wähle deine Sprache"
                  @update:modelValue="validator.validateForm(form as UserFormData, dto)"
                  class="required"
                  :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'language') }"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>{{ Language[slotProps.value] }}</div>
                    </div>
                    <div v-else>&nbsp;</div>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>{{ slotProps.option.text }}</div>
                    </div>
                  </template>
                </Dropdown>

                <label for="language"><Translate text="STOREFRONT_FORMS_PROFILE_LANGUAGE" /></label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, 'language')"><Translate text="STOREFRONT_FORMS_PROFILE_LANGUAGE_VALIDATION" /></small>
            </div>
            <div></div>
          </div>

          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <Checkbox id="hasNewsletter" :binary="true" :disabled="dto.isLoading" v-model="form.hasNewsletter" />
              <label for="hasNewsletter"><Translate text="STOREFRONT_FORMS_PROFILE_NEWSLETTER" /></label>
            </span>
          </div>

          <small class="required-explanation">* <Translate text="STOREFRONT_FORMS_REQUIRED" /></small>
        </Panel>

        <Message v-if="dto.hasError" severity="error"><Translate text="STOREFRONT_MESSAGES_GENERIC_ERROR" /></Message>
      </form>

      <div class="button-panel">
        <div></div>
        <div>
          <Button type="button" :label="Translator.translate('STOREFRONT_GENERAL_CANCEL')" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" :label="Translator.translate('STOREFRONT_GENERAL_SAVE')" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
        </div>
      </div>
    </div>

    <div v-else-if="dto.isLoading">
      <LoadingSkeleton></LoadingSkeleton>
    </div>

    <div v-else>
      <Message severity="error"><Translate text="STOREFRONT_MESSAGES_GENERIC_ERROR" /></Message>
      <div>
        <Button type="button" :label="Translator.translate('STOREFRONT_NAVIGATION_BACK_TO_PROFILE')" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { Validator } from "@/helpers/Validator";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { UserDto } from "@/dtos/UserDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { IUserModel } from "@/models/interfaces/IUserModel";
import { UserFormData } from "@/dtos/data/UserFormData";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { UserModel } from "@/models/UserModel";
import { Language } from "@/enums/Language";
import stringMixins from "@/mixins/stringMixins";
import { useTranslationStore } from "@/stores/translationStore";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "StoreFrontUserProfileEdit",
  components: {
    FFButton,
    LoadingSkeleton,
    DateDisplay,
    Translate,
  },
  methods: {
    async onSave() {
      const userProfileStore = useUserProfileStore();
      const translationStore = useTranslationStore();

      this.form.shouldValidate = true;

      // validate before save.
      this.validator.validateForm(this.form as UserFormData, this.dto).then(() => {
        // update values in store.
        ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

        // send PUT request and show toast if successful.
        userProfileStore
          .updateUser(this.dto as UserDto)
          .then(async () => {
            // change display language.
            translationStore.currentLanguage = this.dto.model.language;

            // show success message.
            this.showToast({
              life: 5000,
              severity: "success",
              summary: Translator.translate("STOREFRONT_MESSAGES_PROFILE_UPDATE_SUCCESS_TITLE"),
              detail: Translator.translate("STOREFRONT_MESSAGES_PROFILE_UPDATE_SUCCESS_DESCRIPTION"),
            });
          })
          .catch((error) => {
            // show error message.
            this.showToast({
              life: 6000,
              severity: "error",
              summary: Translator.translate("STOREFRONT_MESSAGES_PROFILE_UPDATE_FAILED_TITLE"),
              detail: Translator.translate("STOREFRONT_MESSAGES_PROFILE_UPDATE_FAILED_DESCRIPTION"),
            });
          });
      });
    },
  },
  setup() {
    const toast = useToast();
    const userStatusStore = useUserStatusStore();

    const validator = new Validator<IUserModel>();
    const formHelper = new FormHelper("/profile");

    const dto = ref<InstanceType<typeof UserDto>>(ObjectHelper.copyExistingPropsFromTo(userStatusStore.userDto, new UserDto(new UserModel())));

    const languageOptions = [
      { value: Language.DE, text: Language[Language.DE] },
      { value: Language.FR, text: Language[Language.FR] },
    ];

    // get entity from store.
    onMounted(() => {
      userStatusStore.getUserStatus();
    });

    // set up as form state as reactive object.
    const form = ref(new UserFormData(dto.value, false));

    // setup form watchers.
    const updateFormData = (newValue: UserDto) => {
      if (newValue?.model !== null) form.value = new UserFormData(newValue, false);
    };
    watch(dto.value, updateFormData);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    const capitalizeFirstLetter = stringMixins.capitalizeFirstLetter;

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_PROFILE_EDIT")),
    });

    return {
      dto,
      form,
      formHelper,
      validator,
      languageOptions,
      Language,
      Translator,
      showToast,
      capitalizeFirstLetter,
    };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.button-panel {
  display: flex;
  justify-content: space-between;

  button:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
