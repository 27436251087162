export enum LogEntryType {
  NONE = 0,
  MODEL_CREATED = 100,
  MODEL_UPDATED = 101,
  MODEL_DELETED = 102,
  USER_INVALID_LOGIN_ATTEMPT = 200,
  USER_LOGIN = 201,
  MAIL_SUCCESSFULLY_SENT = 800,
  MAIL_COULD_NOT_BE_SENT = 801,
  JOB_EXECUTION = 802,
  SECURITY_WRONG_ORIGIN_COUNTRY = 900,
  SECURITY_REQUEST_LIMIT_REACHED = 901,
}
