/**
 * Used for helping with file downloads.
 */
export const DownloadHelper = {
  /* -------------------------------------------
  Downloads a file with a correct filename.
  ------------------------------------------- */
  downloadContent(blob: Blob, filename: string): void {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  },

  /* -------------------------------------------
  Gets a filename from the Content-Disposition header.
  ------------------------------------------- */
  getFIlenameFromContentDispositionHeader(header: string, fallbackFileEnding: string): string {
    let fileName = "export." + fallbackFileEnding;
    if (header) {
      const fileNameMatch = header.match(/(?<=filename=).*/);
      if (fileNameMatch !== null && fileNameMatch.length === 1) {
        return fileNameMatch[0];
      }
    }
    return fileName;
  },
};
