<template>
  <div class="title-panel">
    <h2>Bestellung bearbeiten</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-pdf" label="Lieferschein" @click="exportOrderReceiptPdf" :loading="isExportLoading"></Button>
    </div>
  </div>

  <OrdersCreateEdit :id="id" :dto="dto"></OrdersCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { OrderDto } from "@/dtos/OrderDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import OrdersCreateEdit from "@/components/controlpanel/orders/OrdersCreateEdit.vue";
import { OrderModel } from "@/models/OrderModel";
import { useOrderStore } from "@/stores/orderStore";
import { useUserStore } from "@/stores/userStore";
import { UserDto } from "@/dtos/UserDtos";
import { UserModel } from "@/models/UserModel";
import { useCouponStore } from "@/stores/couponStore";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponModel } from "@/models/CouponModel";
import { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";

/**
 * View that renders the the "edit" form for orders.
 */
export default defineComponent({
  name: "OrdersEdit",
  methods: {
    exportOrderReceiptPdf(): void {
      const orderStore = useOrderStore();

      this.isExportLoading = true;

      orderStore
        .exportReceipt(this.id)
        .catch(() => {
          // show toast if failed.
          this.showToast({
            life: 6000,
            severity: "error",
            summary: "Fehlgeschlagen",
            detail: "Das PDF konnte nicht generiert werden. Versuchen Sie es später erneut.",
          });
        })
        .finally(() => {
          this.isExportLoading = false;
        });
    },
  },
  setup() {
    const orderStore = useOrderStore();
    const userStore = useUserStore();
    const couponStore = useCouponStore();
    const route = useRoute();
    const toast = useToast();

    const formHelper = new FormHelper("/controlpanel/orders");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new OrderDto(new OrderModel()));
    const userDto = ref(new UserDto(new UserModel()));
    const couponDto = ref(new CouponDto(new CouponModel()));

    const isExportLoading = ref(false);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    onMounted(() => {
      // get entity from store.
      // TODO optimize in API, dont have multiple calls.
      orderStore
        .getOrder(dto.value, id)
        .then(() => userStore.getUser(userDto.value, dto.value.model.userId))
        .then(() => (dto.value.model.couponId ? couponStore.getCoupon(couponDto.value, dto.value.model.couponId) : null))
        .then(() => {
          dto.value.model.userId = userDto.value.model.id;
          dto.value.model.user = userDto.value.model;
          dto.value.model.couponId = couponDto.value.model.id;
          dto.value.model.coupon = couponDto.value.model;
        });
    });

    return {
      id,
      dto,
      isExportLoading,
      showToast,
    };
  },
  components: { OrdersCreateEdit },
});
</script>

<style scoped lang="scss"></style>
