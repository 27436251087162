<template>
  <div class="inner">
    <h2>
      <Translate text="STOREFRONT_PRIVACYPROTECTION_PAGETITLE" />
    </h2>
    <Translate text="STOREFRONT_PRIVACYPROTECTION_PAGECONTENT" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useHead } from "@unhead/vue";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontPrivacyProtection",
  components: {
    Translate,
  },
  setup() {
    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_PRIVACYPROTECTION_PAGETITLE")),
    });

    return {};
  },
});
</script>

<style scoped lang="scss"></style>
