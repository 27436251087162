import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { ICheckoutData } from "../shared/ICheckoutData";
import { OrderDto } from "../OrderDtos";
import { toRaw } from "vue";
import { OrderModel } from "@/models/OrderModel";

export class CheckoutFormData extends BaseFormData<ICheckoutData> implements ICheckoutData {
  public isAgbAccepted: boolean;
  public hasNewsletter: boolean;
  public couponCode: string;
  public couponId?: number | null;

  public originalData: OrderModel;

  constructor(dto: OrderDto) {
    super(dto);

    this.isInitializedProperly = true;

    this.isAgbAccepted = false;
    this.hasNewsletter = true;
    this.couponCode = "";
    this.couponId = null;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      isAgbAccepted: Yup.boolean().required().isTrue(),
      hasNewsletter: Yup.boolean().required(),
      couponCode: Yup.string().optional().nullable(),
      couponId: Yup.number().optional().nullable(),
    });
  }
}
