import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { ProductApiController } from "@/api/ProductApiController";
import { ProductImageListDto, ProductListDto } from "@/dtos/ProductDtos";
import { ProductDto } from "@/dtos/ProductDtos";
import stringMixins from "@/mixins/stringMixins";
import { StoreProductApiProxy } from "@/helpers/StoreProductApiProxy";
import { ProductImageModel } from "@/models/ProductImageModel";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { FilterableState } from "@/dtos/state/FilterableState";
import { ProductModel } from "@/models/ProductModel";
import { AxiosResponse } from "axios";
import { ProductViewModel } from "@/viewModels/ViewModels";

const apiController = new ProductApiController();
const storeApiProxy = new StoreProductApiProxy(apiController);

export const useProductStore = defineStore("product", {
  state: (): FilterableState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all products from API.
     * -------------------------------------------- */
    async getProducts(listDto: ProductListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get all active products from API.
     * -------------------------------------------- */
    async getActiveProducts(listDto: ProductListDto, categoryId: number | null): Promise<void> {
      return storeApiProxy.apiGetMultipleActiveProducts(listDto, categoryId);
    },

    /* -------------------------------------------
     * Get all highlight products from API.
     * -------------------------------------------- */
    async getHighlightProducts(listDto: ProductListDto): Promise<void> {
      return storeApiProxy.apiGetMultipleHighlights(listDto);
    },

    /* -------------------------------------------
     * Get single product from API.
     * -------------------------------------------- */
    async getProduct(dto: ProductDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Get single activ eproduct from API.
     * -------------------------------------------- */
    async getActiveProduct(dto: ProductDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingleActive(dto, id);
    },

    /* -------------------------------------------
     * Saves one product DTO.
     * -------------------------------------------- */
    async updateProduct(dto: ProductDto): Promise<void> {
      // first update product, then all images, then all categories.
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Saves one product DTO.
     * -------------------------------------------- */
    async updateProductHighlight(dto: ProductDto): Promise<AxiosResponse<ProductViewModel>> {
      // first update product, then all images, then all categories.
      return storeApiProxy.apiUpdateAndGetPromise(dto);
    },

    /* -------------------------------------------
     * Get all product images from API.
     * -------------------------------------------- */
    async getProductImages(productId: number, listDto: ProductImageListDto): Promise<void> {
      return storeApiProxy.apiGetMultipleImages(productId, listDto);
    },

    /* -------------------------------------------
     * Creates one image of a product.
     * -------------------------------------------- */
    async createProductImage(dto: ProductDto, model: ProductImageModel): Promise<void> {
      return storeApiProxy.apiCreateImage(dto, model);
    },

    /* -------------------------------------------
     * Deletes one image of a product.
     * -------------------------------------------- */
    async deleteProductImage(dto: ProductDto, model: ProductImageModel): Promise<void> {
      return storeApiProxy.apiDeleteImage(dto, model);
    },

    /* -------------------------------------------
     * Saves one image of a product.
     * -------------------------------------------- */
    async updateProductImage(dto: ProductDto, model: ProductImageModel): Promise<void> {
      return storeApiProxy.apiUpdateImage(dto, model);
    },

    /* -------------------------------------------
     * Creates one category of a product.
     * -------------------------------------------- */
    async createProductCategory(dto: ProductDto, model: ProductCategoryModel): Promise<void> {
      return storeApiProxy.apiCreateCategory(dto, model);
    },

    /* -------------------------------------------
     * Deletes one category of a product.
     * -------------------------------------------- */
    async deleteProductCategory(dto: ProductDto, model: ProductCategoryModel): Promise<void> {
      return storeApiProxy.apiDeleteCategory(dto, model);
    },

    /* -------------------------------------------
     * Creates one similar product relation of a product.
     * -------------------------------------------- */
    async createSimilarProduct(dto: ProductDto, model: ProductModel): Promise<void> {
      return storeApiProxy.apiCreateSimilarProduct(dto, model);
    },

    /* -------------------------------------------
     * Deletes one similar product relation of a product.
     * -------------------------------------------- */
    async deleteSimilarProduct(dto: ProductDto, model: ProductModel): Promise<void> {
      return storeApiProxy.apiDeleteSimilarProduct(dto, model);
    },

    /* -------------------------------------------
     * Creates a new product DTO.
     * -------------------------------------------- */
    async createProduct(dto: ProductDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing product DTO.
     * -------------------------------------------- */
    async deleteProduct(dto: ProductDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: ProductListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: ProductListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: ProductListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: ProductListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: ProductListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: ProductListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
