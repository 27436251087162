<template>
  <div class="inner">
    <router-link :to="returnToPath" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate :text="returnToDisplayName" /></span>
    </router-link>

    <h2><Translate text="STOREFRONT_FORMS_PROFILE_ADDRESSES" /></h2>

    <div v-if="form.isReady()">
      <form @submit.prevent="" @change="validator.validateForm(form as UserFormData, dto)">
        <AddressPanel
          ref="shippingAddressRef"
          :model="form.shippingAddress"
          :editModeUserDto="dto"
          :edit-mode-validation-prefix="'shippingAddress.'"
          :header="Translator.translate('STOREFRONT_FORMS_PROFILE_ADDRESSES_SHIPPING_ADDRESS_HEADER')"
          :show-edit-button="false"
          :is-edit-mode="true"
        ></AddressPanel>

        <Message v-show="anythingHasError" severity="error"><Translate text="STOREFRONT_MESSAGES_GENERIC_ERROR" /></Message>
      </form>

      <div class="button-panel">
        <div></div>
        <div>
          <Button type="button" :label="Translator.translate('STOREFRONT_GENERAL_CANCEL')" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" :label="Translator.translate('STOREFRONT_GENERAL_SAVE')" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
        </div>
      </div>
    </div>

    <div v-else-if="dto.isLoading">
      <LoadingSkeleton></LoadingSkeleton>
    </div>

    <div v-else>
      <Message severity="error"><Translate text="STOREFRONT_MESSAGES_GENERIC_ERROR" /></Message>
      <div>
        <Button type="button" :label="Translator.translate('STOREFRONT_NAVIGATION_BACK_TO_PROFILE')" :severity="'secondary'" @click="return"></Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { Validator } from "@/helpers/Validator";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import AddressPanel from "@/components/storefront/users/AddressPanel.vue";
import { AddressDto } from "@/dtos/AddressDtos";
import { UserDto } from "@/dtos/UserDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { IUserModel } from "@/models/interfaces/IUserModel";
import { UserFormData } from "@/dtos/data/UserFormData";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { UserModel } from "@/models/UserModel";
import { AddressModel } from "@/models/AddressModel";
import { AddressType } from "@/enums/AddressType";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontUserProfileEdit",
  components: {
    FFButton,
    LoadingSkeleton,
    AddressPanel,
    DateDisplay,
    Translate,
  },
  props: {
    returnToPath: {
      type: String,
      required: false,
    },
    returnToDisplayName: {
      type: String,
      required: false,
    },
  },
  methods: {
    return() {
      this.$router.push(this.returnToPath);
    },
    async onSave() {
      const userProfileStore = useUserProfileStore();

      this.form.shouldValidate = true;

      // validate before save.
      this.validator.validateForm(this.form as UserFormData, this.dto).then(() => {
        // update values in store.
        ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

        const addressesToCreate = this.dto.model.shippingAddress.id === -1 ? [this.dto.model.shippingAddress] : [];
        const addressesToUpdate = this.dto.model.shippingAddress.id === -1 ? [] : [this.dto.model.shippingAddress];

        // reset address Dtos for tracking.
        this.addressDtos = [];

        // TODO implement
        const addressesToDelete: AddressModel[] = [];

        // update or create addresses.
        const addressRequests: Promise<void>[] = [];
        addressRequests.push(
          ...addressesToCreate.map((addressToCreate) => {
            /* TODO refactor, use userDto for updating addresses */
            addressToCreate.addressType = AddressType.SHIPPING;
            addressToCreate.userId = this.dto.model.id;

            // create DTO for tracking.
            const addressDto = new AddressDto(addressToCreate);
            this.addressDtos.push(addressDto);

            return userProfileStore.createProfileAddress(addressDto);
          }),
          ...addressesToUpdate.map((addressToUpdate) => {
            /* TODO refactor, use userDto for updating addresses */
            addressToUpdate.addressType = AddressType.SHIPPING;

            // create DTO for tracking.
            const addressDto = new AddressDto(addressToUpdate);
            this.addressDtos.push(addressDto);

            return userProfileStore.updateProfileAddress(addressDto);
          }),
          ...addressesToDelete.map((addressToDelete) => {
            // create DTO for tracking.
            const addressDto = new AddressDto(addressToDelete);
            this.addressDtos.push(addressDto);

            return userProfileStore.deleteProfileAddress(new AddressDto(addressToDelete));
          })
        );

        // send requests and show toast if successful.
        Promise.all(addressRequests)
          .then(async () => {
            // show success message.
            this.showToast({
              life: 5000,
              severity: "success",
              summary: Translator.translate("STOREFRONT_MESSAGES_ADDRESS_UPDATE_SUCCESS_TITLE"),
              detail: Translator.translate("STOREFRONT_MESSAGES_ADDRESS_UPDATE_SUCCESS_DESCRIPTION"),
            });

            this.return();
          })
          .then(() => {})
          .catch((error) => {
            // show error message.
            this.showToast({
              life: 6000,
              severity: "error",
              summary: Translator.translate("STOREFRONT_MESSAGES_ADDRESS_UPDATE_FAILED_TITLE"),
              detail: Translator.translate("STOREFRONT_MESSAGES_ADDRESS_UPDATE_FAILED_DESCRIPTION"),
            });
          });
      });
    },
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const userStatusStore = useUserStatusStore();

    const validator = new Validator<IUserModel>();
    const formHelper = new FormHelper("/profile");

    const dto = ref<InstanceType<typeof UserDto>>(ObjectHelper.copyExistingPropsFromTo(userStatusStore.userDto, new UserDto(new UserModel())));

    const addressDtos = ref<InstanceType<typeof Array<AddressDto>>>([]);

    const shippingAddressRef = ref<InstanceType<typeof AddressPanel>>();

    const returnToPath = computed(() => (route.hash === "#checkout" ? "/checkout" : "/profile"));
    const returnToDisplayName = computed(() => (route.hash === "#checkout" ? "STOREFRONT_NAVIGATION_TO_CHECKOUT" : "STOREFRONT_NAVIGATION_TO_PROFILE"));

    // set up as form state as reactive object.
    const form = ref(new UserFormData(dto.value, true));

    // setup form watchers.
    const updateFormData = (newValue: UserDto) => {
      if (newValue?.model !== null) form.value = new UserFormData(newValue, true);
    };
    watch(dto.value, updateFormData);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    const anythingHasError = computed(() => dto.value.hasError || addressDtos.value.some((a) => a.hasError));

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_PROFILE_ADDRESSES")),
    });

    // get entity from store.
    onMounted(() => {
      userStatusStore.getUserStatus();
    });

    return {
      dto,
      addressDtos,
      form,
      formHelper,
      shippingAddressRef,
      validator,
      anythingHasError,
      Translator,
      showToast,
      returnToPath,
      returnToDisplayName,
    };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.button-panel {
  display: flex;
  justify-content: space-between;

  button:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
