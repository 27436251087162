import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a9df56c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Galleria = _resolveComponent("Galleria")!
  const _component_LoadingSkeleton = _resolveComponent("LoadingSkeleton")!

  return (_ctx.listDto.list.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({ 'hide-navigation': _ctx.listDto.list.length === 1 })
      }, [
        _createVNode(_component_Galleria, {
          value: _ctx.listDto.list,
          responsiveOptions: [],
          numVisible: 5,
          circular: true,
          showItemNavigators: true,
          showThumbnails: false,
          autoPlay: true,
          transitionInterval: 8000
        }, {
          item: _withCtx((slotProps) => [
            _createElementVNode("a", {
              href: slotProps.item.model.link(),
              title: slotProps.item.model.title(),
              target: "_blank"
            }, [
              _createElementVNode("img", {
                src: slotProps.item.model.image,
                alt: slotProps.item.model.title()
              }, null, 8, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h2", null, _toDisplayString(slotProps.item.model.title()), 1),
                _createElementVNode("p", null, _toDisplayString(slotProps.item.model.text()), 1)
              ])
            ], 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["value"])
      ], 2))
    : (_openBlock(), _createBlock(_component_LoadingSkeleton, {
        key: 1,
        skeletonType: _ctx.blockSkeletonType,
        width: "100%",
        height: '500px'
      }, null, 8, ["skeletonType"]))
}