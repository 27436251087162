<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="">
      <Panel header="Log-Informationen">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.createdAt" />
            <label for="createdAt">Erstellt am</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-exclamation-circle"></i>
            <Dropdown v-model="form.logEntryType" :options="logEntryTypeOptions" :disabled="true" option-value="value">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>[{{ slotProps.value }}] {{ LogEntryType[slotProps.value] }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">[{{ slotProps.option.value }}] {{ slotProps.option.text }}</div>
                <div v-else>&nbsp;</div>
              </template>
            </Dropdown>
            <label for="logEntryType">Typ</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-exclamation-circle"></i>
            <Dropdown v-model="form.logEntryMessageType" :options="logEntryMessageTypeOptions" :disabled="true" option-value="value">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>[{{ slotProps.value }}] {{ LogEntryMessageType[slotProps.value] }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">[{{ slotProps.option.value }}] {{ slotProps.option.text }}</div>
                <div v-else>&nbsp;</div>
              </template>
            </Dropdown>
            <label for="logEntryMessageType">Nachricht-Typ</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-book"></i>
            <Textarea type="text" id="message" :disabled="true" rows="3" v-model="form.message" />
            <label for="message">Log-Nachricht</label>
          </span>
        </div>
      </Panel>

      <Panel header="Request-Informationen">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-android"></i>
            <Textarea type="text" id="requestUserAgent" :disabled="true" rows="3" v-model="form.requestUserAgent" />
            <label for="requestUserAgent">Request User-Agent</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-compass"></i>
            <InputText type="text" id="requestOrigin" :disabled="true" v-model="form.requestOrigin" />
            <label for="requestOrigin">Request Origin</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-globe"></i>
            <InputText type="text" id="requestIpAddressV4" :disabled="true" v-model="form.requestIpAddressV4" />
            <label for="requestIpAddressV4">Request IP-Adresse (v4)</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-file"></i>
            <Textarea type="text" id="requestDataRaw" :disabled="true" rows="20" v-model="form.requestDataRaw" />
            <label for="requestDataRaw">Request Data (Raw)</label>
          </span>
        </div>
      </Panel>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { LogEntryFormData } from "@/dtos/data/LogEntryFormData";
import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { FormHelper } from "@/helpers/FormHelper";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { LogEntryType } from "@/enums/LogEntryType";
import { LogEntryMessageType } from "@/enums/LogEntryMessageType";
import { EnumHelper } from "@/helpers/EnumHelper";

/**
 * A shared component used for editing (and creating) logEntries.
 */
export default defineComponent({
  name: "LogEntriesCreateEdit",
  props: {
    id: Number,
    dto: {
      type: LogEntryDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  setup(props) {
    const formHelper = new FormHelper("/controlpanel/logentries");

    // get entity from props.
    const { id, dto } = toRefs(props);

    const logEntryTypeOptions = EnumHelper.createDropdownOptionsFromEnum(LogEntryType);
    const logEntryMessageTypeOptions = EnumHelper.createDropdownOptionsFromEnum(LogEntryMessageType);

    // set up as form state as reactive object.
    const form = ref(new LogEntryFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new LogEntryFormData(newValue)));

    return {
      id,
      dto,
      form,
      formHelper,
      LogEntryType,
      logEntryTypeOptions,
      LogEntryMessageType,
      logEntryMessageTypeOptions,
    };
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-bottom: 40px;
}

:disabled,
.p-disabled,
.p-calendar-disabled,
:deep(.p-calendar-disabled) input {
  opacity: 0.8 !important;
}

i {
  z-index: 1;
}

:deep(.p-dropdown) {
  .p-inputtext {
    margin-left: 40px;
    padding-left: 0;
  }
}
</style>
