<template>
  <div class="title-panel">
    <h2>Coupon bearbeiten</h2>
  </div>

  <CouponsCreateEdit :id="id" :dto="dto"></CouponsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponModel } from "@/models/CouponModel";
import { useCouponStore } from "@/stores/couponStore";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import CouponsCreateEdit from "@/components/controlpanel/coupons/CouponsCreateEdit.vue";

/**
 * View that renders the the "edit" form for coupons.
 */
export default defineComponent({
  name: "CouponsEdit",
  setup() {
    const store = useCouponStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/coupons");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new CouponDto(new CouponModel()));

    // get entity from store.
    onMounted(() => {
      store.getCoupon(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { CouponsCreateEdit },
});
</script>

<style scoped lang="scss"></style>
