<template>
  <div class="title-panel">
    <h2>Übersetzungen</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel Export" @click="onExportExcel"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
    :totalRecords="listDto.totalRecordCount"
    :loading="listDto.isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Übersetzungen gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.translationKey" header="Key">
      <template #body="slotProps">
        <span class="clickable" @click="goToEdit(slotProps.data.model.id)">{{ slotProps.data.model.translationKey }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.languageDe" header="Deutsch"></Column>
    <Column sortable filter field="model.languageFr" header="Französisch"></Column>
    <template #footer> Es gibt {{ listDto.totalRecordCount }} Übersetzungen. </template>
  </DataTable>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from "vue";
import { SortDirection } from "@/enums/SortDirection";
import { useTranslationStore } from "@/stores/translationStore";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { ExportType } from "@/enums/ExportType";
import { TranslationListDto } from "@/dtos/TranslationDtos";
import { storeToRefs } from "pinia";

/**
 * View that renders a list of all translations.
 */
export default defineComponent({
  name: "TranslationsList",
  methods: {
    async onPage($event: DataTablePageEvent) {
      const store = useTranslationStore();

      store.setPage(this.listDto, $event.page + 1);
      store.setPageSize(this.listDto, $event.rows);

      await store.getTranslations(this.listDto);
    },
    async onSort($event: DataTableSortEvent) {
      const store = useTranslationStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getTranslations(this.listDto);
    },

    async onRefresh() {
      const store = useTranslationStore();
      store.getTranslations(this.listDto);
    },

    async onFilter() {
      const store = useTranslationStore();

      store.setFilter(this.listDto, this.filters.global.value, this.enumFilter);

      await store.getTranslations(this.listDto);
    },

    onExportPdf(): void {
      const store = useTranslationStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useTranslationStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string) {
      this.$router.push("/controlpanel/translations/" + id);
    },

    goToCreate() {
      this.$router.push("/controlpanel/translations/create");
    },
  },
  setup() {
    const store = useTranslationStore();

    const { filters, enumFilter } = storeToRefs(store);

    const listDto = reactive(new TranslationListDto([]));

    // make initial fetch.
    onMounted(() => {
      // reapply filter.
      store.setFilter(listDto, filters.value.global.value, enumFilter.value);

      // fetch data from API.
      store.getTranslations(listDto);
    });

    return {
      listDto,
      filters,
      enumFilter,
    };
  },
});
</script>

<style scoped lang="scss">
// special table stylings
:deep(.p-datatable-tbody > tr > td) {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30vw;
}
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}
</style>
