import { ModelDto } from "./base/ModelDto";
import { OrderModel } from "@/models/OrderModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { OrderProductModel } from "@/models/OrderProductModel";

class OrderDto extends ModelDto<OrderModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.OrderModel(x),
    },
  ];
}

class OrderListDto extends ListDto<OrderDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.OrderDto(y)),
    },
  ];
}

class OrderProductDto extends ModelDto<OrderProductModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.OrderProductModel(x),
    },
  ];
}

export { OrderDto, OrderListDto, OrderProductDto };
