/**
 * Used for helping with enum keys and values.
 */
export const DateHelper = {
  /* -------------------------------------------
  Removes time from a given date.
  ------------------------------------------- */
  stripTime(date: Date): Date {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  },

  /* -------------------------------------------
  Gets the first second of the date.
  ------------------------------------------- */
  getStartOfDay(date: Date): Date {
    return new Date(new Date(date).setHours(0));
  },

  /* -------------------------------------------
  Gets the last second of the date.
  ------------------------------------------- */
  getEndOfDay(date: Date): Date {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  },

  /* -------------------------------------------
  Gets the first day of the week (Mon - Sun).
  ------------------------------------------- */
  getFirstDayOfWeek(date: Date): Date {
    return new Date(new Date().setDate(date.getDate() - (date.getDay() || 7) + 1));
  },

  /* -------------------------------------------
  Gets the last day of the week (Mon - Sun).
  ------------------------------------------- */
  getLastDayOfWeek(date: Date): Date {
    return new Date(new Date().setDate(date.getDate() - (date.getDay() || 7) + 7));
  },

  /* -------------------------------------------
  Gets the first day of the month.
  ------------------------------------------- */
  getFirstDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  },

  /* -------------------------------------------
  Gets the last day of the month.
  ------------------------------------------- */
  getLastDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  },

  /* -------------------------------------------
  Gets the first day of the quarter.
  ------------------------------------------- */
  getFirstDayOfQuarter(date: Date): Date {
    const quarter = Math.floor(date.getMonth() / 3);
    return new Date(date.getFullYear(), quarter * 3, 1);
  },

  /* -------------------------------------------
  Gets the last day of the quarter.
  ------------------------------------------- */
  getLastDayOfQuarter(date: Date): Date {
    const firstDayOfQuarter = this.getFirstDayOfQuarter(date);
    return new Date(firstDayOfQuarter.getFullYear(), firstDayOfQuarter.getMonth() + 3, 0);
  },

  /* -------------------------------------------
  Gets the ISO string, but including the time zone offset.
  ------------------------------------------- */
  toISOStringWithTimezone(date: Date): string {
    const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    return isoDateTime;
  },

  /* -------------------------------------------
  Gets "from" date of the previous period of a given timespan.
  ------------------------------------------- */
  getPreviousPeriodFromDate(from: Date, to: Date): Date {
    return new Date(from.getTime() - (to.getTime() - from.getTime()));
  },

  /* -------------------------------------------
  Adds days to a date.
  ------------------------------------------- */
  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
};
