import { OrdersTimelineData } from "./OrdersTimelineData";

export class OrdersReport {
  amountOfOrders: number = 0;
  amountOfCanceledOrders: number = 0;
  amountOfOrderedProducts: number = 0;
  amountOfSentProducts: number = 0;
  amountOfOrderedSachets: number = 0;
  ordersTimeline: OrdersTimelineData[] = [];
}
