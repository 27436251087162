<template>
  <div class="title-panel">
    <h2>Slider bearbeiten</h2>
  </div>

  <SlidersCreateEdit :id="id" :dto="dto"></SlidersCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SliderDto } from "@/dtos/SliderDtos";
import { SliderModel } from "@/models/SliderModel";
import { useSliderStore } from "@/stores/sliderStore";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import SlidersCreateEdit from "@/components/controlpanel/sliders/SlidersCreateEdit.vue";

/**
 * View that renders the the "edit" form for sliders.
 */
export default defineComponent({
  name: "SlidersEdit",
  setup() {
    const store = useSliderStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/sliders");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new SliderDto(new SliderModel()));

    // get entity from store.
    onMounted(() => {
      store.getSlider(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { SlidersCreateEdit },
});
</script>

<style scoped lang="scss"></style>
