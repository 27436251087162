import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Menubar = _resolveComponent("Menubar")!

  return (_openBlock(), _createBlock(_component_Menubar, {
    model: _ctx.items,
    unstyled: true,
    class: "menubar"
  }, {
    item: _withCtx(({ item }) => [
      (item.url)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", {
              class: _normalizeClass(item.icon)
            }, null, 2),
            (item.isExact)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  "exact-active-class": "active",
                  to: item.url
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  "active-class": "active",
                  to: item.url
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model"]))
}