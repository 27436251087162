import { UserModel } from "@/models/UserModel";
import { TypeMapping } from "../dtos/shared/TypeMapping";
import { ObjectHelper } from "./ObjectHelper";
import { CountryModel } from "@/models/CountryModel";
import { CouponModel } from "@/models/CouponModel";
import { ProductModel } from "@/models/ProductModel";
import { AddressModel } from "@/models/AddressModel";
import { RoleModel } from "@/models/RoleModel";
import { TranslationModel } from "@/models/TranslationModel";
import { SliderModel } from "@/models/SliderModel";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";
import { OrderModel } from "@/models/OrderModel";
import { ProductImageModel } from "@/models/ProductImageModel";
import { CartModel } from "@/models/CartModel";
import { OrderProductModel } from "@/models/OrderProductModel";
import { AddressDto } from "@/dtos/AddressDtos";
import { CartDto } from "@/dtos/CartDtos";
import { CountryDto } from "@/dtos/CountryDtos";
import { CouponDto } from "@/dtos/CouponDtos";
import { OrderDto, OrderProductDto } from "@/dtos/OrderDtos";
import { ProductDto, ProductCategoryDto, ProductImageDto } from "@/dtos/ProductDtos";
import { SliderDto } from "@/dtos/SliderDtos";
import { TranslationDto } from "@/dtos/TranslationDtos";
import { UserDto } from "@/dtos/UserDtos";
import { EnumHelper } from "./EnumHelper";
import { LogEntryModel } from "@/models/LogEntryModel";
import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";

/**
 * Used for mapping specific types.
 */
export const TypeHelper = {
  mappers: {
    Date: (value: any) => new Date(value),
    DateNullable: (value: any) => value !== undefined && value !== null ? new Date(value) : null,
    RoleModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new RoleModel(), RoleModel.mappings),
    UserModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new UserModel(), UserModel.mappings),
    TranslationModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new TranslationModel(), TranslationModel.mappings),
    CountryModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new CountryModel(), CountryModel.mappings),
    CouponModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new CouponModel(), CouponModel.mappings),
    ProductModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new ProductModel(), ProductModel.mappings),
    ProductCategoryModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new ProductCategoryModel(), ProductCategoryModel.mappings),
    ProductImageModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new ProductImageModel(), ProductImageModel.mappings),
    AddressModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new AddressModel(), AddressModel.mappings),
    SliderModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new SliderModel(), SliderModel.mappings),
    CartModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new CartModel(), CartModel.mappings),
    OrderModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new OrderModel(), OrderModel.mappings),
    OrderProductModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new OrderProductModel(), OrderProductModel.mappings),
    LogEntryModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new LogEntryModel(), LogEntryModel.mappings),
    ContactFormEntryModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new ContactFormEntryModel(), ContactFormEntryModel.mappings),
    UserDto: (value: any) => new UserDto(TypeHelper.mappers.UserModel(value)),
    TranslationDto: (value: any) => new TranslationDto(TypeHelper.mappers.TranslationModel(value)),
    CountryDto: (value: any) => new CountryDto(TypeHelper.mappers.CountryModel(value)),
    CouponDto: (value: any) => new CouponDto(TypeHelper.mappers.CouponModel(value)),
    ProductDto: (value: any) => new ProductDto(TypeHelper.mappers.ProductModel(value)),
    ProductCategoryDto: (value: any) => new ProductCategoryDto(TypeHelper.mappers.ProductCategoryModel(value)),
    ProductImageDto: (value: any) => new ProductImageDto(TypeHelper.mappers.ProductImageModel(value)),
    AddressDto: (value: any) => new AddressDto(TypeHelper.mappers.AddressModel(value)),
    SliderDto: (value: any) => new SliderDto(TypeHelper.mappers.SliderModel(value)),
    CartDto: (value: any) => new CartDto(TypeHelper.mappers.CartModel(value)),
    OrderDto: (value: any) => new OrderDto(TypeHelper.mappers.OrderModel(value)),
    OrderProductDto: (value: any) => new OrderProductDto(TypeHelper.mappers.OrderProductModel(value)),
    LogEntryDto: (value: any) => new LogEntryDto(TypeHelper.mappers.LogEntryModel(value)),
    ContactFormEntryDto: (value: any) => new ContactFormEntryDto(TypeHelper.mappers.ContactFormEntryModel(value)),
  },
};

/**
 * Used for some properties that appear in many entities.
 */
export const genericMappings: TypeMapping[] = [
  {
    key: "validFromDate",
    cast: (x) => TypeHelper.mappers.Date(x),
  },
  {
    key: "createdBy",
    cast: (x) => TypeHelper.mappers.UserModel(x),
  },
  {
    key: "updatedBy",
    cast: (x) => TypeHelper.mappers.UserModel(x),
  },
  {
    key: "createdAt",
    cast: (x) => TypeHelper.mappers.Date(x),
  },
  {
    key: "updatedAt",
    cast: (x) => TypeHelper.mappers.DateNullable(x),
  },
  {
    key: "sortDirection",
    cast: (x) => EnumHelper.getSortDirection(x),
  },
];

/**
 * Used for some properties that appear in request/response base entities.
 */
export const baseResponseMappings: TypeMapping[] = [
  {
    key: "statusCode",
    cast: (x) => EnumHelper.getStatusCode(x),
  },
  {
    key: "registerReturnCode",
    cast: (x) => EnumHelper.getRegisterReturnCode(x),
  },
  {
    key: "loginReturnCode",
    cast: (x) => EnumHelper.getLoginReturnCode(x),
  },
  {
    key: "returnCode",
    cast: (x) => EnumHelper.getReturnCode(x),
  },
];
