import { UserDto } from "@/dtos/UserDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { UserModel } from "@/models/UserModel";
import { AddressViewModel, OrderListViewModel, OrderViewModel, UserListViewModel, UserViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { AddressModel } from "@/models/AddressModel";
import { AddressType } from "@/enums/AddressType";
import { ChangePasswordData } from "@/dtos/data/ChangePasswordData";
import { PasswordResetData } from "@/dtos/data/PasswordResetData";
import { CompletePasswordResetData } from "@/dtos/data/CompletePasswordResetData";
import { CompleteRegisterData } from "@/dtos/data/CompleteRegisterData";

export class UserProfileApiController extends BaseApiController<UserModel, UserDto, UserViewModel, UserListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.USER_PROFILE);
  }

  public getOrders(): Promise<AxiosResponse<OrderListViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/orders`);
  }

  public cancelOrder(orderId: number): Promise<AxiosResponse<OrderViewModel>> {
    return super._internalDelete(`${this.apiEndpoint}/orders/${orderId}`);
  }

  public override update(_: number, model: UserModel): Promise<AxiosResponse<UserViewModel>> {
    return super._internalPut(`${this.apiEndpoint}`, model);
  }

  public changePassword(data: ChangePasswordData): Promise<AxiosResponse<UserViewModel>> {
    return super._internalPut(`${this.apiEndpoint}/passwordchange`, data);
  }

  public startPasswordResetProcess(data: PasswordResetData): Promise<AxiosResponse<UserViewModel>> {
    return super._internalPost(`${this.apiEndpoint}/password`, data);
  }

  public completePasswordResetProcess(data: CompletePasswordResetData): Promise<AxiosResponse<UserViewModel>> {
    return super._internalPut(`${this.apiEndpoint}/password`, data);
  }

  public completeVerificationProcess(data: CompleteRegisterData): Promise<AxiosResponse<UserViewModel>> {
    return super._internalPut(`${this.apiEndpoint}/verification`, data);
  }

  public createProfileAddress(model: AddressModel): Promise<AxiosResponse<AddressViewModel>> {
    return super._internalPost(`${this.apiEndpoint}/addresses`, model);
  }

  public updateProfileAddress(model: AddressModel): Promise<AxiosResponse<AddressViewModel>> {
    return super._internalPut(`${this.apiEndpoint}/addresses`, model);
  }

  public deleteProfileAddress(addressType: AddressType): Promise<AxiosResponse<AddressViewModel>> {
    return super._internalDelete(`${this.apiEndpoint}/addresses?addressType=${addressType}`);
  }
}
