<template>
  <header>
    <div>
      <router-link to="/controlpanel" v-if="!isMobile"><img class="logo" src="@/assets/img/ff_logo_wordmark_trawler_black_frame_rgb.png" alt="Logo" /></router-link>
      <h1>Control Panel</h1>
    </div>

    <div class="user-info">
      <div class="user">
        <span class="username">{{ userDisplayName }}</span>
        <a @click="logout()" class="logout"><Translate text="STOREFRONT_NAVIGATION_LOGOUT" /></a>
      </div>
      <i class="pi pi-user"></i>
    </div>
  </header>
</template>

<script lang="ts">
import { Constants } from "@/enums/Constants";
import stringMixins from "@/mixins/stringMixins";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useBreakpoints } from "@vueuse/core";
import { computed, defineComponent } from "vue";
import Translate from "../shared/Translate.vue";

/**
 * A component to render the header in the control panel.
 * Shows information about the logged in user.
 */
export default defineComponent({
  name: "ControlPanelHeader",
  components: { Translate },
  methods: {
    async logout() {
      const userStatusStore = useUserStatusStore();

      userStatusStore.postLogout().then(() => {
        this.$router.push("/");
      });
    },
  },
  setup() {
    const store = useUserStatusStore();

    const isAuthenticated = computed(() => store.isAuthenticated);
    const userDisplayName = computed(() => store.userDto.model.displayName);
    const roleDisplayName = computed(() => stringMixins.capitalizeFirstLetter(store.userDto.model.role.name));

    const breakpoints = useBreakpoints(Constants.Breakpoints);

    const isMobile = breakpoints.isSmallerOrEqual("mobile");

    return {
      isAuthenticated,
      userDisplayName,
      roleDisplayName,
      isMobile,
    };
  },
});
</script>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: $ff-primary-black;
  color: $ff-primary-white;
  padding: 0 20px;
  z-index: 1;

  a {
    height: 70%;
    margin-right: 20px;

    img {
      height: 100%;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  .user-info {
    flex-direction: row;

    .user {
      flex-direction: column;
      align-items: end;
      padding-right: 10px;

      .username {
        font-weight: bold;
      }

      .logout {
        color: $ff-primary-white;
        text-decoration: underline;
        margin-right: 0;
        cursor: pointer;
      }
    }

    i {
      font-size: 2rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  header {
    position: relative;
  }
}
</style>
