<template>
  <ProgressSpinner></ProgressSpinner>
</template>

<script lang="ts">
import { defineComponent, toRef } from "vue";

/**
 * Simple FF-colored progress spinner component.
 */
export default defineComponent({
  name: "FFProgressSpinner",
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.p-progress-spinner {
  display: block;

  :deep(svg circle) {
    stroke: $ff-primary-red !important;
  }
}
</style>
