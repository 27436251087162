<template>
  <div class="title-panel">
    <h2>Produkte</h2>
    <div class="flex align-items-center justify-content-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel Export" @click="onExportExcel"></Button>
      <Button type="button" label="Neu erstellen" @click="goToCreate()"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
    :totalRecords="listDto.totalRecordCount"
    :loading="listDto.isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Produkte gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>
    <Column sortable filter field="model.isHighlight" header="Highlight" style="width: 100px">
      <template #body="slotProps">
        <i v-if="slotProps.data.isLoading" class="pi pi-spin pi-spinner" />
        <i v-else-if="slotProps.data.model.isHighlight" class="pi pi-star-fill" @click="setProductHighlight(slotProps.data, false)" />
        <i v-else-if="!isArchived(slotProps.data.model)" class="pi pi-star" @click="setProductHighlight(slotProps.data, true)" :class="{ disabled: !slotProps.data.model.isActive }" />
      </template>
    </Column>
    <Column field="model.productImages" header="Bild" style="width: 80px">
      <template #body="slotProps">
        <img v-if="slotProps.data.model.productImages.length > 0" :src="slotProps.data.model.productImages.sort((a:ProductImageModel, b:ProductImageModel)=> a.sortOrder - b.sortOrder)[0].imageData" />
      </template>
    </Column>
    <Column sortable filter field="model.subnameDe" header="Name">
      <template #body="slotProps">
        <span class="clickable" @click="goToEdit(slotProps.data.model.id)">{{ slotProps.data.model.fullName() }}</span>
      </template>
    </Column>
    <Column sortable filter field="model.price" header="Preis"></Column>
    <Column sortable filter field="model.stock" header="Lagerbestand">
      <template #body="slotProps">
        {{ slotProps.data.model.stock }}
        <Avatar v-if="slotProps.data.model.stock === 0" icon="pi pi-exclamation-triangle" class="ml-2" shape="circle" style="background-color: var(--ff-gold-label)" />
      </template>
    </Column>
    <Column filter field="model.productCategories" header="Kategorien">
      <template #body="slotProps">
        <template v-for="(category, index) of slotProps.data.model.productCategories">
          <Chip :label="category.nameDe" />
        </template>
      </template>
    </Column>
    <Column sortable filter field="model.isActive" header="Status">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.isActive" value="aktiv" severity="success"></Tag>
        <Tag v-else value="inaktiv" severity="warning"></Tag>
        <Tag v-if="isArchived(slotProps.data.model)" value="archiviert" class="archived"></Tag>
      </template>
    </Column>
    <Column sortable filter field="model.updatedAt" header="Aktualisiert am">
      <template #body="slotProps">
        <DateDisplay v-if="slotProps.data.model.updatedAt" :date="slotProps.data.model.updatedAt" :with-time="true"></DateDisplay>
        <DateDisplay v-else :date="slotProps.data.model.createdAt" :with-time="true"></DateDisplay>
      </template>
    </Column>

    <template #footer> Es gibt {{ listDto.totalRecordCount }} Produkte. </template>
  </DataTable>

  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { useProductStore } from "@/stores/productStore";
import { ProductDto, ProductListDto } from "@/dtos/ProductDtos";
import { ProductImageModel } from "@/models/ProductImageModel";
import { ExportType } from "@/enums/ExportType";
import Tag from "primevue/tag";
import { storeToRefs } from "pinia";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { AxiosResponse } from "axios";
import { ProductViewModel } from "@/viewModels/ViewModels";
import { StatusCode } from "@/enums/StatusCode";
import modelMixins from "@/mixins/modelMixins";

/**
 * View that renders a list of all products.
 */
export default defineComponent({
  name: "ProductsList",
  components: {
    DateDisplay,
  },
  methods: {
    async onPage($event: DataTablePageEvent): Promise<void> {
      const store = useProductStore();

      store.setPage(this.listDto, $event.page + 1);
      store.setPageSize(this.listDto, $event.rows);

      await store.getProducts(this.listDto);
    },

    async onSort($event: DataTableSortEvent): Promise<void> {
      const store = useProductStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getProducts(this.listDto);
    },

    async onRefresh(): Promise<void> {
      const store = useProductStore();
      await store.getProducts(this.listDto);
    },

    async setProductHighlight(product: ProductDto, isHighlight: boolean): Promise<void> {
      const store = useProductStore();

      product.model.isHighlight = isHighlight;

      await store
        .updateProductHighlight(product)
        .then(async () => {
          // show toast if successful.
          this.showToast({
            life: 5000,
            severity: "success",
            summary: "Erfolgreich gespeichert",
            detail: "Das Produkt wurde erfolgreich als Highlight gespeichert.",
          });
        })
        .catch(async (error: AxiosResponse<ProductViewModel>) => {
          product.model.isHighlight = false;

          if (error.data.statusCode === StatusCode.PRODUCT_MAX_HIGHLIGHTS_REACHED) {
            // show warning of max highlight count is reached.
            this.showToast({
              life: 3000,
              severity: "warn",
              summary: "Warnung",
              detail: "Es können maximal 3 Produkte als Highlight markiert werden.",
            });
          } else {
            product.model.isHighlight = false;

            // show toast if failed.
            this.showToast({
              life: 3000,
              severity: "error",
              summary: "Fehlgeschlagen",
              detail: "Das Produkt konnte nicht aktualisiert werden.",
            });
          }
        });
    },

    async onFilter() {
      const store = useProductStore();

      store.setFilter(this.listDto, this.filters.global.value, this.enumFilter);

      await store.getProducts(this.listDto);
    },

    onExportPdf(): void {
      const store = useProductStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useProductStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string): void {
      this.$router.push("/controlpanel/products/" + id);
    },

    goToCreate(): void {
      this.$router.push("/controlpanel/products/create");
    },
  },
  setup() {
    const store = useProductStore();
    const toast = useToast();

    const listDto = reactive(new ProductListDto([]));

    const { filters, enumFilter } = storeToRefs(store);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    const isArchived = modelMixins.isProductArchived;

    // make initial fetch.
    onMounted(() => {
      // reapply filter.
      store.setFilter(listDto, filters.value.global.value, enumFilter.value);

      // fetch data from API.
      store.getProducts(listDto);
    });

    return {
      listDto,
      filters,
      enumFilter,
      showToast,
      isArchived,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable) {
  .p-datatable-header > div {
    margin-bottom: 5px;
  }
}

tbody tr td > i {
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.p-chip {
  &:not(:last-child) {
    margin-right: 5px;
  }
}

.archived {
  margin-left: 5px;
  background: $ff-controlpanel-light;
}
</style>
