<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="" @change="validator.validateForm(form as UserControlPanelFormData, dto)">
      <Panel header="Benutzerinformationen">
        <!-- Active -->
        <div class="active-panel">
          <InputSwitch id="active" v-model="form.isActive" />
          <label for="active" class="p-label">{{ form.isActive ? "Aktiv" : "Inaktiv" }}</label>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-book"></i>
            <InputNumber type="text" id="id" :disabled="true" v-model="dto.model.id" :use-grouping="false" />
            <label for="id">ID</label>
          </span>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar v-if="dto.model.lastLoginDate" id="lastLoginDate" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.lastLoginDate" />
            <InputText v-else type="text" id="lastLoginDate" :disabled="true" value="-" />
            <label for="lastLoginDate" class="p-float">Zuletzt angemeldet am</label>
          </span>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <InputText type="text" id="displayName" :disabled="dto.isLoading" v-model="form.displayName" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'displayName') }" />
            <label for="displayName">Anzeigename</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'displayName')">Anzeigename ist ein Pflichtfeld.</small>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="username" :disabled="dto.isLoading" v-model="form.username" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'username') }" />
            <label for="username">Benutzername/E-Mail</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'username')">Benutzername ist ein Pflichtfeld und muss eine E-Mail-Adresse sein.</small>
        </div>

        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card"></i>

              <Dropdown
                v-model="form.language"
                :options="languageOptions"
                :disabled="dto.isLoading"
                option-value="value"
                @update:modelValue="validator.validateForm(form as UserControlPanelFormData, dto)"
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'language') }"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ Language[slotProps.value] }}</div>
                  </div>
                  <div v-else>&nbsp;</div>
                </template>
                <template #option="slotProps">
                  <div class="flex align-items-center">
                    <div>{{ slotProps.option.text }}</div>
                  </div>
                </template>
              </Dropdown>

              <label for="language">Benutzersprache</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'language')">Benutzersprache ist ein Pflichtfeld.</small>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <Checkbox
                id="hasNewsletter"
                :binary="true"
                :disabled="dto.isLoading"
                v-model="form.hasNewsletter"
                class="required"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'hasNewsletter') }"
              />
              <label for="hasNewsletter">Newsletter abonniert?</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'hasNewsletter')">Newsletter ist ein Pflichtfeld.</small>
          </div>
        </div>

        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-list"></i>
              <Textarea id="notes" :disabled="dto.isLoading" rows="7" v-model="form.notes"></Textarea>
              <label for="notes">Notizen<sup>1</sup></label>
            </span>
            <small class="p-info"><sup>1</sup>Notizen sind nur für Administratoren sichtbar, nicht für Benutzer.</small>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <Checkbox v-model="overridePassword" :binary="true"></Checkbox>
              <label for="password">Passwort überschreiben?</label>
            </span>
            <div v-if="overridePassword">
              <span class="p-float-label p-input-icon-left mt-4">
                <i class="pi pi-lock"></i>
                <InputText type="text" id="password" :disabled="dto.isLoading" v-model="form.password" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'password') }" />
                <label for="password">Neues Passwort</label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, 'password')">Password ist ein Pflichtfeld.</small>
              <Message severity="info" :closable="false" class="passwordreset-hint">Achtung: Das Passwort wird direkt überschrieben sobald du den Benutzer speicherst.</Message>
            </div>
          </div>
        </div>

        <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>
      </Panel>

      <div class="button-panel">
        <div></div>
        <div>
          <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
        </div>
      </div>

      <Panel header="Bestellungen">
        <div v-if="ordersDto.list.length > 0" class="orders">
          <OrdersDataTable
            :listDto="ordersDto"
            details-link="/controlpanel/orders/"
            :footer-text="`Dieser Benutzer hat ${ordersDto.list.length} Bestellungen.`"
            :show-admin-buttons="true"
          ></OrdersDataTable>
        </div>
        <div v-else>
          <p>Dieser Benutzer hat noch keine Bestellungen.</p>
        </div>
      </Panel>

      <MetadataPanel :dto="dto"></MetadataPanel>
    </form>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { useUserStore } from "@/stores/userStore";
import { UserDto } from "@/dtos/UserDtos";
import { useToast } from "primevue/usetoast";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ToastMessageOptions } from "primevue/toast";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { UserControlPanelFormData } from "@/dtos/data/UserControlPanelFormData";
import { IControlPanelUserModel } from "@/models/interfaces/IControlPanelUserModel";
import { OrderListDto } from "@/dtos/OrderDtos";
import OrdersDataTable from "@/components/shared/OrdersDataTable.vue";
import MetadataPanel from "../MetadataPanel.vue";
import { Language } from "@/enums/Language";

/**
 * A shared component used for editing (and creating) users.
 */
export default defineComponent({
  name: "UsersCreateEdit",
  props: {
    id: Number,
    dto: {
      type: UserDto,
      required: true,
    },
    ordersDto: {
      type: OrderListDto,
      required: true,
    },
  },
  components: { LoadingSkeleton, DateDisplay, OrdersDataTable, MetadataPanel },
  methods: {
    async onSave() {
      const store = useUserStore();

      this.form.shouldValidate = true;

      // remove password value to prevent accidential password resets.
      if (!this.overridePassword) {
        this.form.password = null;
      }

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as UserControlPanelFormData, this.dto).then(() => {
        // send PUT request and show toast if successful.
        store
          .updateUser(this.dto as UserDto)
          .then(async () => {
            this.overridePassword = false;

            this.showToast({
              life: 5000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Der Benutzer wurde erfolgreich aktualisiert.",
            });
          })
          .catch(async () => {
            // show toast if failed.
            this.showToast({
              life: 3000,
              severity: "error",
              summary: "Fehlgeschlagen",
              detail: "Der Benutzer konnte nicht aktualisiert werden.",
            });
          });
      });
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<IControlPanelUserModel>();
    const formHelper = new FormHelper("/controlpanel/users");

    const overridePassword = ref(false);

    // get entity from props.
    const { id, dto, ordersDto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new UserControlPanelFormData(dto.value));

    const languageOptions = [
      { value: Language.DE, text: Language[Language.DE] },
      { value: Language.FR, text: Language[Language.FR] },
    ];

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new UserControlPanelFormData(newValue)));

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      ordersDto,
      form,
      formHelper,
      validator,
      overridePassword,
      Language,
      languageOptions,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss">
.button-panel {
  margin-bottom: 40px;
}

.passwordreset-hint {
  :deep(.p-message-wrapper) {
    padding: 10px 15px;
  }
}

.button-panel,
.p-panel {
  margin-bottom: 40px;
}
</style>
