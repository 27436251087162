import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-892eed0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookie-banner-content" }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    id: "cookie-banner",
    class: _normalizeClass({ hide: !_ctx.isCookieBannerVisible })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Translate, { text: "STOREFRONT_COOKIEBANNER_DESCRIPTION" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: _ctx.Translator.translate('STOREFRONT_COOKIEBANNER_ACCEPT_NECESSARY_ONLY'),
          onClick: _ctx.acceptNecessaryCookies,
          severity: "secondary"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.Translator.translate('STOREFRONT_COOKIEBANNER_ACCEPT_ALL'),
          onClick: _ctx.acceptAllCookies,
          severity: "primary"
        }, null, 8, ["label", "onClick"])
      ])
    ])
  ], 2))
}