import { Constants } from "@/enums/Constants";

const LOCALSTORAGE_TRUE = "true";
const LOCALSTORAGE_FALSE = "false";

/**
 * Used for helping with localStorage.
 */
export const LocalStorageHelper = {
  /* -------------------------------------------
  Returns if the necessary cookies are accepted.
  ------------------------------------------- */
  areNecessaryCookieAccepted(): boolean {
    const value = localStorage.getItem(Constants.LocalStorage.NecessaryCookiesAccepted);
    return value === LOCALSTORAGE_TRUE;
  },

  /* -------------------------------------------
  Returns if the optional cookies are accepted.
  ------------------------------------------- */
  areOptionalCookieAccepted(): boolean {
    const value = localStorage.getItem(Constants.LocalStorage.OptionalCookiesAccepted);
    return value === LOCALSTORAGE_TRUE;
  },

  /* -------------------------------------------
  Sets the necessary cookies accepted value.
  ------------------------------------------- */
  setNecessaryCookieAccepted(value: boolean): void {
    localStorage.setItem(Constants.LocalStorage.NecessaryCookiesAccepted, value ? LOCALSTORAGE_TRUE : LOCALSTORAGE_FALSE);
  },

  /* -------------------------------------------
  Sets the optional cookies accepted value.
  ------------------------------------------- */
  setOptionalCookieAccepted(value: boolean): void {
    localStorage.setItem(Constants.LocalStorage.OptionalCookiesAccepted, value ? LOCALSTORAGE_TRUE : LOCALSTORAGE_FALSE);
  },
};
