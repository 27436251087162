import { Translator } from "@/helpers/Translator";

/**
 * Helper methods related to string operations.
 */
export default {
  /* -------------------------------------------
  Capitalized the first letter of a string, and returns the rest in lower case.
  ------------------------------------------- */
  capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  },

  /* -------------------------------------------
  Forcefully parses a string to a number.
  ------------------------------------------- */
  parseToNumber(input: string): number {
    return Number.parseInt(input, 10) || 0;
  },

  /* -------------------------------------------
  Adds single quotes to large numbers: from "1234" to "1'234".
  ------------------------------------------- */
  beautifyNumber(input: string | number): string {
    if (input === undefined) return "";
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  },

  /* -------------------------------------------
  Strips "model." from any string.
  ------------------------------------------- */
  stripModelString(input: string): string {
    if (typeof input === typeof "") {
      return (input as String).replaceAll("model.", "");
    }
    return input;
  },

  /* -------------------------------------------
  Renders a date in swiss format.
  ------------------------------------------- */
  renderDate(input: Date, withTime: boolean = false): string {
    const padZero = (i: string | number): string => ("0" + i).slice(-2);

    const prettyDate = `${padZero(input.getDate())}.${padZero(input.getMonth() + 1)}.${input.getFullYear()}${withTime ? ", " + padZero(input.getHours()) + ":" + padZero(input.getMinutes()) : ""}`;
    return prettyDate;
  },

  formatSeoTitle(title: string): string {
    return title + " | " + Translator.translate("STOREFRONT_TITLE");
  },
};
