<template>
  <h2><Translate text="STOREFRONT_NOTVERIFIED_TITLE" /></h2>

  <p><Translate text="STOREFRONT_NOTVERIFIED_DESCRIPTION" :asHtml="true" /></p>

  <router-link to="/login" class="breadcrumb">
    <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_LOGIN" /></span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";
import { Translator } from "@/helpers/Translator";

/**
 * The shared login forgot-password component.
 */
export default defineComponent({
  name: "NotVerified",
  components: { Translate },
  methods: {},
  setup() {

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_NOTVERIFIED_TITLE")),
    });

    return {};
  },
});
</script>

<style scoped lang="scss"></style>
