<template>
  <div class="title-panel">
    <h2>Coupon erstellen</h2>
  </div>

  <CouponsCreateEdit :dto="dto"></CouponsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponModel } from "@/models/CouponModel";
import CouponsCreateEdit from "@/components/controlpanel/coupons/CouponsCreateEdit.vue";

/**
 * View that renders the the "create" form for coupons.
 */
export default defineComponent({
  name: "CouponsCreate",
  setup() {
    // create empty instance.
    const dto = ref(new CouponDto(new CouponModel()));

    // get entity from store.
    dto.value.model.id = -1;

    return {
      dto,
    };
  },
  components: { CouponsCreateEdit },
});
</script>

<style scoped lang="scss"></style>
