<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="">
      <Panel header="Kontaktformular-Informationen">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.createdAt" />
            <label for="createdAt">Erstellt am</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-id-card"></i>

            <Dropdown v-model="form.language" :options="languageOptions" :disabled="true">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ Language[slotProps.value] }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div>{{ slotProps.option.text }}</div>
                </div>
              </template>
            </Dropdown>

            <label for="language">Sprache</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-exclamation-circle"></i>
            <InputText type="text" id="title" :disabled="true" v-model="form.title" />
            <label for="title">Titel</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-file"></i>
            <Textarea type="text" id="text" :disabled="true" rows="10" v-model="form.text" />
            <label for="text">Text</label>
          </span>
        </div>
      </Panel>

      <Panel header="Request-Informationen">
        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <InputText type="text" id="firstname" :disabled="true" v-model="form.firstname" />
              <label for="firstname">Vorname</label>
            </span>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <InputText type="text" id="surname" :disabled="true" v-model="form.surname" />
              <label for="surname">Nachname</label>
            </span>
          </div>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-envelope"></i>
            <InputText type="text" id="email" :disabled="true" v-model="form.email" />
            <label for="email">E-Mail-Adresse</label>
          </span>
        </div>

        <div class="form-row split large-small">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-home"></i>
              <InputText type="text" id="street" :disabled="true" v-model="form.street" />
              <label for="street">Strasse</label>
            </span>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-home"></i>
              <InputText type="text" id="streetNumber" :disabled="true" v-model="form.streetNumber" />
              <label for="streetNumber">Hausnr.</label>
            </span>
          </div>
        </div>

        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-map-marker"></i>
              <InputNumber id="zipCode" :disabled="true" v-model="form.zipCode" :min="1" :max="9999" :use-grouping="false" />
              <label for="zipCode">PLZ</label>
            </span>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-map"></i>
              <InputText type="text" id="city" :disabled="true" v-model="form.city" />
              <label for="city">Ort</label>
            </span>
          </div>
        </div>
      </Panel>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { ContactFormEntryFormData } from "@/dtos/data/ContactFormEntryFormData";
import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { FormHelper } from "@/helpers/FormHelper";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { Language } from "@/enums/Language";

/**
 * A shared component used for editing (and creating) contactFormEntries.
 */
export default defineComponent({
  name: "ContactFormEntriesCreateEdit",
  props: {
    id: Number,
    dto: {
      type: ContactFormEntryDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  setup(props) {
    const formHelper = new FormHelper("/controlpanel/contactformentries");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new ContactFormEntryFormData(dto.value));

    const languageOptions = [
      { value: Language.DE, text: Language[Language.DE] },
      { value: Language.FR, text: Language[Language.FR] },
    ];

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new ContactFormEntryFormData(newValue)));

    return {
      id,
      dto,
      form,
      formHelper,
      Language,
      languageOptions,
    };
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-bottom: 40px;
}

:disabled,
.p-disabled,
.p-calendar-disabled,
:deep(.p-calendar-disabled) input {
  opacity: 0.8 !important;
}

i {
  z-index: 1;
}

:deep(.p-dropdown) {
  .p-inputtext {
    margin-left: 40px;
    padding-left: 0;
  }
}
</style>
