import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";
import { CompletePasswordResetData } from "./CompletePasswordResetData";

export class CompletePasswordResetFormData extends BaseFormData<CompletePasswordResetData> implements CompletePasswordResetData {
  public username: string;
  public resetCode: string;
  public newPassword1: string;
  public newPassword2: string;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    this.isInitializedProperly = true;

    this.username = "";
    this.resetCode = "";
    this.newPassword1 = "";
    this.newPassword2 = "";

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      username: Yup.string().email().required(),
      resetCode: Yup.string().required(),
      newPassword1: Yup.string().required(),
      newPassword2: Yup.string().required(),
    });
  }
}
