import { ModelDto } from "./base/ModelDto";
import { AddressModel } from "@/models/AddressModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class AddressDto extends ModelDto<AddressModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.AddressModel(x),
    },
  ];
}

class AddressListDto extends ListDto<AddressDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.AddressDto(y)),
    },
  ];
}

export { AddressDto, AddressListDto };
