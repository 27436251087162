import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73282550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "highlight-reel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDisplay = _resolveComponent("ProductDisplay")!
  const _component_FFProgressSpinner = _resolveComponent("FFProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoadingComplete)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ProductDisplay, {
              dto: _ctx.firstHighlightProduct,
              "is-scroll-animation": false
            }, null, 8, ["dto"]),
            _createVNode(_component_ProductDisplay, {
              dto: _ctx.secondHighlightProduct,
              "is-scroll-animation": false
            }, null, 8, ["dto"]),
            _createVNode(_component_ProductDisplay, {
              dto: _ctx.thirdHighlightProduct,
              "is-scroll-animation": false
            }, null, 8, ["dto"])
          ], 64))
        : (_openBlock(), _createBlock(_component_FFProgressSpinner, { key: 1 }))
    ])
  ]))
}