<template>
  <div class="title-panel">
    <h2>Slider erstellen</h2>
  </div>

  <SlidersCreateEdit :dto="dto"></SlidersCreateEdit>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { SliderDto } from "@/dtos/SliderDtos";
import { SliderModel } from "@/models/SliderModel";
import SlidersCreateEdit from "@/components/controlpanel/sliders/SlidersCreateEdit.vue";

/**
 * View that renders the the "create" form for sliders.
 */
export default defineComponent({
  name: "SlidersCreate",
  setup() {
    // create empty instance.
    const dto = ref(new SliderDto(new SliderModel()));

    // get entity from store.
    dto.value.model.id = -1;

    return {
      dto,
    };
  },
  components: { SlidersCreateEdit },
});
</script>

<style scoped lang="scss"></style>
