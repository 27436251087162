import { Constants } from "@/enums/Constants";

/**
 * Used for helping with FF color themes.
 */
export const ColorSetter = {
  /* -------------------------------------------
  Sets a random FF color theme in global HTML document.
  ------------------------------------------- */
  setGlobalFishermansFriendColor() {
    const randomColor = Constants.ColorThemes[Math.floor(Math.random() * Constants.ColorThemes.length)];
    document.documentElement.style.setProperty("--ff-random-package-color", `${randomColor.packageColor}`);
    document.documentElement.style.setProperty("--ff-random-label-color", `${randomColor.labelColor}`);

    console.log(`Selected random theme: '${randomColor.name}'.`);
  },
};
