export enum StatusCode {
  ////////////////////////////
  // Global (0)
  ////////////////////////////

  NONE = 0,
  OK = 1,
  ERROR = 2,
  MODEL_NOT_FOUND = 3,
  MODEL_UPDATE_FAILED = 4,
  MODEL_CREATE_FAILED = 5,
  MODEL_DELETE_FAILED = 6,
  POSITIVE_NUMBER_REQUIRED = 7,

  ////////////////////////////
  // User (100)
  ////////////////////////////

  USER_NOT_FOUND = 100,
  USER_OLD_PASSWORD_DOES_NOT_MATCH = 101,
  USER_NEW_PASSWORDS_DO_NOT_MATCH = 102,
  USER_NEW_PASSWORD_DOES_NOT_MEET_COMPLEXITY_RULES = 103,
  USER_IS_NOT_ACTIVE = 104,
  USER_PASSWORD_RESET_NOT_STARTED = 105,
  USER_PASSWORD_RESET_CODE_DOES_NOT_MATCH = 106,
  USER_NOT_LOGGED_IN = 107,
  USER_VERIFICATION_ALREADY_STARTED = 108,
  USER_VERIFICATION_NOT_STARTED = 109,
  USER_VERIFICATION_CODE_DOES_NOT_MATCH = 110,

  ////////////////////////////
  // Address (200)
  ////////////////////////////

  ADDRESS_NOT_FOUND = 200,
  ADDRESS_TYPE_NOT_SET = 201,
  ADDRESS_SHIPPING_ADDRESS_ALREADY_EXISTS = 202,
  ADDRESS_BILLING_ADDRESS_ALREADY_EXISTS = 203,
  ADDRESS_IS_NOT_THE_SHIPPING_ADDRESS = 204,
  ADDRESS_IS_NOT_THE_BILLING_ADDRESS = 205,

  ////////////////////////////
  // Translations (300)
  ////////////////////////////

  TRANSLATION_NOT_FOUND = 300,

  ////////////////////////////
  // Sliders (400)
  ////////////////////////////

  SLIDER_NOT_FOUND = 400,

  ////////////////////////////
  // Coupons (500)
  ////////////////////////////

  COUPON_NOT_FOUND = 500,
  COUPON_REDUCTION_IN_PACKS_REQUIRED = 501,
  COUPON_WITH_THIS_CODE_ALREADY_EXISTS = 502,

  ////////////////////////////
  // Contact Form Entries (600)
  ////////////////////////////

  CONTACT_FORM_ENTRY_NOT_FOUND = 600,

  ////////////////////////////
  // Product (700)
  ////////////////////////////

  PRODUCT_NOT_FOUND = 700,
  PRODUCT_ALREADY_HAS_THIS_PRODUCT_CATEGORY = 701,
  PRODUCT_DOES_NOT_HAVE_THIS_PRODUCT_CATEGORY = 702,
  PRODUCT_ARTICLE_NUMBER_ALREADY_EXISTS = 703,
  PRODUCT_NOT_ACTIVE = 704,
  PRODUCT_NOT_IN_CART = 705,
  PRODUCT_OUT_OF_STOCK = 706,
  PRODUCT_SIMILAR_PRODUCT_NOT_FOUND = 707,
  PRODUCT_CANNOT_BE_THE_SAME_AS_SIMILAR_PRODUCT = 708,
  PRODUCT_ALREADY_HAS_THIS_SIMILAR_PRODUCT = 709,
  PRODUCT_DOES_NOT_HAVE_THIS_SIMILAR_PRODUCT = 710,
  PRODUCT_MAX_HIGHLIGHTS_REACHED = 711,

  ////////////////////////////
  // Product Category (800)
  ////////////////////////////

  PRODUCT_CATEGORY_NOT_FOUND = 800,
  PRODUCT_CATEGORY_MAX_ACTIVE_PRODUCT_COUNT_REACHED = 801,

  ////////////////////////////
  // Product Images (900)
  ////////////////////////////

  PRODUCT_IMAGE_NOT_FOUND = 900,
  PRODUCT_IMAGE_DOES_NOT_BELONG_TO_THIS_PRODUCT = 901,
  PRODUCT_IMAGE_SORT_ORDER_MUST_BE_POSITIVE = 902,
  PRODUCT_IMAGE_BASE64_DATA_NOT_VALID = 903,

  ////////////////////////////
  // Order (1000)
  ////////////////////////////

  ORDER_NOT_FOUND = 1000,
  ORDER_AMOUNT_NOT_VALID = 1001,
  ORDER_CART_IS_EMPTY = 1002,
  ORDER_CREATION_FAILED = 1003,
  ORDER_IS_CANCELED = 1004,
  ORDER_COUPON_NOT_FOUND = 1005,

  ////////////////////////////
  // Order Product (1100)
  ////////////////////////////

  ORDER_PRODUCT_NOT_FOUND = 1100,
  ORDER_PRODUCT_DOES_NOT_BELONG_TO_THIS_ORDER = 1101,
  ORDER_PRODUCT_AMOUNT_DELIVERED_CANNOT_EXCEED_AMOUNT_ORDERED = 1102,

  ////////////////////////////
  // Country (1200)
  ////////////////////////////

  COUNTRY_NOT_FOUND = 1200,
}
