<template>
  <div class="inner">
    <div class="highlight-reel">
      <template v-if="isLoadingComplete">
        <ProductDisplay :dto="firstHighlightProduct" :is-scroll-animation="false"></ProductDisplay>
        <ProductDisplay :dto="secondHighlightProduct" :is-scroll-animation="false"></ProductDisplay>
        <ProductDisplay :dto="thirdHighlightProduct" :is-scroll-animation="false"></ProductDisplay>
      </template>
      <FFProgressSpinner v-else></FFProgressSpinner>
    </div>
  </div>
</template>

<script lang="ts">
import { ProductDto, ProductListDto } from "@/dtos/ProductDtos";
import { useProductStore } from "@/stores/productStore";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import ProductDisplay from "./products/ProductDisplay.vue";
import { ProductModel } from "@/models/ProductModel";
import FFProgressSpinner from "../FFProgressSpinner.vue";

/**
 * Component that renders the three highlight products on the Landing Page in the Store Front.
 */
export default defineComponent({
  name: "HighlightProductReel",
  components: {
    FFProgressSpinner,
    ProductDisplay,
  },
  setup() {
    const store = useProductStore();

    const listDto = reactive(new ProductListDto([]));
    const firstHighlightProduct = ref<InstanceType<typeof ProductDto>>(new ProductDto(new ProductModel()));
    const secondHighlightProduct = ref<InstanceType<typeof ProductDto>>(new ProductDto(new ProductModel()));
    const thirdHighlightProduct = ref<InstanceType<typeof ProductDto>>(new ProductDto(new ProductModel()));

    const isLoadingComplete = ref(false);

    // make initial fetch.
    onMounted(() => {
      store.getHighlightProducts(listDto).then(() => {
        if (listDto.list.length > 0) firstHighlightProduct.value = listDto.list[0];
        if (listDto.list.length > 1) secondHighlightProduct.value = listDto.list[1];
        if (listDto.list.length > 2) thirdHighlightProduct.value = listDto.list[2];
        isLoadingComplete.value = true;
      });
    });

    return {
      listDto,
      firstHighlightProduct,
      secondHighlightProduct,
      thirdHighlightProduct,
      isLoadingComplete,
    };
  },
});
</script>

<style scoped lang="scss">
.highlight-reel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 100px;
  column-gap: 50px;

  > div {
    width: calc(33% - 50px);

    @media (max-width: $breakpoint-tablet) {
      width: calc(50% - 50px);
    }
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}
</style>
