import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bf7582e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoreFrontNavigation = _resolveComponent("StoreFrontNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_StoreFrontFooter = _resolveComponent("StoreFrontFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StoreFrontNavigation, { "is-barebone": _ctx.isUnderConstruction }, null, 8, ["is-barebone"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ]),
    (!_ctx.isUnderConstruction)
      ? (_openBlock(), _createBlock(_component_StoreFrontFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}