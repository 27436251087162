import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { toRaw } from "vue";
import { IOrderEditModel } from "@/models/interfaces/IOrderEditModel";
import { OrderDto } from "../OrderDtos";
import { OrderModel } from "@/models/OrderModel";

export class OrderFormData extends BaseFormData<IOrderEditModel> implements IOrderEditModel {
  public receivedSachets: number;
  public trackingNumber?: string | null;
  public notes: string;

  public originalData: OrderModel;

  constructor(dto: OrderDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.receivedSachets = dto.model.receivedSachets;
    this.notes = dto.model.notes ?? "";
    this.trackingNumber = dto.model.trackingNumber;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      receivedSachets: Yup.number().required(),
      notes: Yup.string().optional(),
    });
  }
}
