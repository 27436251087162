<template>
  <h2><Translate text="STOREFRONT_FORMS_REGISTERCOMPLETE" /></h2>

  <form v-if="isRegistrationCompletedSuccessfully" @submit.prevent="" @change="validator.validateForm(form as CompleteRegisterFormData, dto)">
    <Message severity="success" :closable="false"><Translate text="STOREFRONT_MESSAGES_REGISTERCOMPLETE_SUCCESS" /></Message>

    <FFButton :label="Translator.translate('STOREFRONT_FORMS_REGISTER_TO_LOGIN')" @click="goToLogin()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>
  </form>

  <form v-else @submit.prevent="" @change="validator.validateForm(form as CompleteRegisterFormData, dto)">
    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <InputText
          type="text"
          id="username"
          name="username"
          autocomplete="username"
          :disabled="dto.isLoading"
          v-model="form.username"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'username') }"
        />
        <label for="username"><Translate text="STOREFRONT_FORMS_REGISTERCOMPLETE_USERNAME" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'username')"><Translate text="STOREFRONT_FORMS_REGISTERCOMPLETE_USERNAME_VALIDATION" /></small>
    </div>

    <div class="form-row">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-hashtag"></i>
        <InputText type="text" id="verificationCode" :disabled="dto.isLoading" v-model="form.verificationCode" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'verificationCode') }" />
        <label for="verificationCode"><Translate text="STOREFRONT_FORMS_REGISTERCOMPLETE_CODE" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'verificationCode')"><Translate text="STOREFRONT_FORMS_REGISTERCOMPLETE_CODE_VALIDATION" /></small>
    </div>

    <FFButton :label="Translator.translate('STOREFRONT_FORMS_REGISTERCOMPLETE_CONFIRM')" @click="completeRegistration()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>

    <Message v-if="dto.hasError" severity="error">{{ customError || Translator.translate("STOREFRONT_MESSAGES_GENERIC_ERROR") }}</Message>
  </form>
</template>

<script lang="ts">
import { UserDto } from "@/dtos/UserDtos";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Validator } from "@/helpers/Validator";
import { UserModel } from "@/models/UserModel";
import { defineComponent, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { CompleteRegisterData } from "@/dtos/data/CompleteRegisterData";
import { CompleteRegisterFormData } from "@/dtos/data/CompleteRegisterFormData";
import { StatusCode } from "@/enums/StatusCode";
import { UserViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { useRoute } from "vue-router";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

/**
 * The shared login forgot-password component.
 */
export default defineComponent({
  name: "RegisterComplete",
  components: { FFButton, Translate },
  methods: {
    async completeRegistration(): Promise<void> {
      const userProfileStore = useUserProfileStore();

      this.form.shouldValidate = true;

      this.customError = "";

      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as CompleteRegisterFormData, this.dto).then((success) => {
        userProfileStore
          .completeVerificationProcess(this.dto, this.form)
          .then(() => {
            this.isRegistrationCompletedSuccessfully = true;
          })
          .catch((error: AxiosResponse<UserViewModel>) => {
            switch (error.data.statusCode) {
              case StatusCode.USER_NOT_FOUND:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTERCOMPLETE_USER_NOT_FOUND");
                break;
              case StatusCode.USER_VERIFICATION_NOT_STARTED:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTERCOMPLETE_VERIFICATION_NOT_STARTED");
                break;
              case StatusCode.USER_VERIFICATION_CODE_DOES_NOT_MATCH:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_REGISTERCOMPLETE_VERIFICATION_CODE_DOES_NOT_MATCH");
                break;
            }
          });
      });
    },

    goToLogin(): void {
      this.$router.push("/login");
    },
  },
  setup() {
    const route = useRoute();
    const validator = new Validator<CompleteRegisterData>();
    const formHelper = new FormHelper("/login");

    const dto = ref<InstanceType<typeof UserDto>>(new UserDto(new UserModel()));
    const form = ref<InstanceType<typeof CompleteRegisterFormData>>(new CompleteRegisterFormData(dto.value));
    const isRegistrationCompletedSuccessfully = ref(false);

    const customError = ref("");

    const username = route.query.username;
    if (username) {
      form.value.username = username as string;
    }

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_REGISTERCOMPLETE")),
    });

    return {
      validator,
      formHelper,
      form,
      dto,
      customError,
      isRegistrationCompletedSuccessfully,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss"></style>
