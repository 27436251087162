import { StoreApiProxy } from "./StoreApiProxy";
import { ProductApiController } from "@/api/ProductApiController";
import { ProductListViewModel, ProductViewModel } from "@/viewModels/ViewModels";
import { ProductModel } from "@/models/ProductModel";
import { ProductImageModel } from "@/models/ProductImageModel";
import { ObjectHelper } from "./ObjectHelper";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";
import { ProductDto, ProductImageListDto, ProductListDto } from "@/dtos/ProductDtos";
import { AxiosResponse } from "axios";

/**
 * Special adapted StoreApiProxy to fit all the products needs.
 */
export class StoreProductApiProxy extends StoreApiProxy<ProductModel, ProductDto, ProductListDto, ProductViewModel, ProductListViewModel> {
  private productApiController: ProductApiController;

  constructor(apiController: ProductApiController) {
    super(apiController, ProductListDto.mappings, ProductDto.mappings);

    this.productApiController = apiController as ProductApiController;
  }

  /* -------------------------------------------
  Sends a GET request (multiple, highlight only) of the current api controller.
  ------------------------------------------- */
  public async apiGetMultipleHighlights(listDto: ProductListDto): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .getHighlightProducts()
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, this.listDtoMappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Sends a GET request (multiple, highlight only) of the current api controller.
  ------------------------------------------- */
  public async apiGetMultipleActiveProducts(listDto: ProductListDto, categoryId: number | null): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .getActiveProducts(categoryId)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, this.listDtoMappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request of the current api controller.
  ------------------------------------------- */
  public async apiUpdateAndGetPromise(dto: ProductDto): Promise<AxiosResponse<ProductViewModel>> {
    dto.isLoading = true;
    dto.hasError = false;

    return new Promise<AxiosResponse<ProductViewModel>>((resolve, reject) => {
      return this.apiController
        .update(dto.model.id, dto.model)
        .then((success: AxiosResponse<ProductViewModel>) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, dto, this.dtoMappings);
          resolve(success);
        })
        .catch((error) => {
          dto.hasError = true;
          reject(error);
        })
        .finally(() => {
          dto.isLoading = false;
        });
    });
  }
  /* -------------------------------------------
  Sends a GET request (multiple images) of the current api controller.
  ------------------------------------------- */
  public async apiGetMultipleImages(productId: number, listDto: ProductImageListDto): Promise<void> {
    listDto.isLoading = true;
    listDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .getImages(productId)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data, listDto, ProductImageListDto.mappings);
          resolve();
        })
        .catch((error) => {
          listDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          listDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to update the sort order of an image.
  ------------------------------------------- */
  public async apiUpdateImage(productDto: ProductDto, model: ProductImageModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .updateImage(productDto.model.id, model)
        .then((success) => {
          const ref = productDto.model.productImages.findIndex((i) => i.id === model.id);
          if (ref !== undefined) {
            ObjectHelper.copyExistingPropsFromTo(success.data.model, productDto.model.productImages[ref]);
          }
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a POST request to add an image to a product.
  ------------------------------------------- */
  public async apiCreateImage(productDto: ProductDto, model: ProductImageModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .createImage(productDto.model.id, model)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data.model, model);
          productDto.model.productImages.push(model);
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request to remove an image from a product.
  ------------------------------------------- */
  public async apiDeleteImage(productDto: ProductDto, model: ProductImageModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .deleteImage(productDto.model.id, model)
        .then((success) => {
          const ref = productDto.model.productImages.findIndex((i) => i.id === model.id);
          if (ref !== undefined) {
            productDto.model.productImages.splice(ref, 1);
          }
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to add a category to a product.
  ------------------------------------------- */
  public async apiCreateCategory(productDto: ProductDto, model: ProductCategoryModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .createCategory(productDto.model.id, model.id)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data.model, model);
          productDto.model.productCategories.push(model);
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request to remove a category from a product.
  ------------------------------------------- */
  public async apiDeleteCategory(productDto: ProductDto, model: ProductCategoryModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .deleteCategory(productDto.model.id, model.id)
        .then((success) => {
          const ref = productDto.model.productCategories.findIndex((i) => i.id === model.id);
          if (ref !== undefined) {
            productDto.model.productCategories.splice(ref, 1);
          }
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a PUT request to add a similar product to a product.
  ------------------------------------------- */
  public async apiCreateSimilarProduct(productDto: ProductDto, model: ProductModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .createSimilarProduct(productDto.model.id, model.id)
        .then((success) => {
          ObjectHelper.copyExistingPropsFromTo(success.data.model, model);
          productDto.model.similarProducts.push(model);
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }

  /* -------------------------------------------
  Send a DELETE request to remove a similar product from a product.
  ------------------------------------------- */
  public async apiDeleteSimilarProduct(productDto: ProductDto, model: ProductModel): Promise<void> {
    productDto.isLoading = true;
    productDto.hasError = false;

    return new Promise<void>((resolve, reject) => {
      return this.productApiController
        .deleteSimilarProduct(productDto.model.id, model.id)
        .then((success) => {
          const ref = productDto.model.similarProducts.findIndex((i) => i.id === model.id);
          if (ref !== undefined) {
            productDto.model.similarProducts.splice(ref, 1);
          }
          resolve();
        })
        .catch((error) => {
          productDto.hasError = true;
          reject(error);
        })
        .finally(() => {
          productDto.isLoading = false;
        });
    });
  }
}
