<template>
  <Panel :header="header">
    <template #icons>
      <button v-if="showEditButton" class="p-panel-header-icon p-link mr-2" @click="$emit('goToEdit')">
        <span class="pi pi-pencil"></span>
      </button>
    </template>
    <form @submit.prevent="" @change="$emit('change')" class="mb-0">
      <div v-if="model && !isNaN(model.id)">
        <div class="entry">
          <template v-if="isEditMode">
            <div class="form-row split">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-user"></i>
                  <InputText
                    type="text"
                    id="firstname"
                    name="firstname"
                    autocomplete="given-name"
                    :disabled="dto.isLoading"
                    v-model="model.firstname"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'firstname') }"
                  />
                  <label for="firstname"><Translate text="STOREFRONT_FORMS_ADDRESS_FIRSTNAME" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'firstname')"><Translate text="STOREFRONT_FORMS_ADDRESS_FIRSTNAME_VALIDATION" /></small>
              </div>
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-user"></i>
                  <InputText
                    type="text"
                    id="surname"
                    name="surname"
                    autocomplete="family-name"
                    :disabled="dto.isLoading"
                    v-model="model.surname"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'surname') }"
                  />
                  <label for="surname"><Translate text="STOREFRONT_FORMS_ADDRESS_SURNAME" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'surname')"><Translate text="STOREFRONT_FORMS_ADDRESS_SURNAME_VALIDATION" /></small>
              </div>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_FIRSTNAME" /> / <Translate text="STOREFRONT_FORMS_ADDRESS_SURNAME" /></span>
            <span>{{ model.firstname }} {{ model.surname }} </span>
          </template>
        </div>

        <div class="entry" v-if="model.companyName || isEditMode">
          <template v-if="isEditMode">
            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-briefcase"></i>
                <InputText type="text" id="companyName" name="companyName" autocomplete="organization" :disabled="dto.isLoading" v-model="model.companyName" />
                <label for="companyName"><Translate text="STOREFRONT_FORMS_ADDRESS_COMPANY" /></label>
              </span>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_COMPANY" /></span>
            <span>{{ model.companyName }}</span>
          </template>
        </div>

        <div class="entry">
          <template v-if="isEditMode">
            <div class="form-row split large-small">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-home"></i>
                  <InputText
                    type="text"
                    id="street"
                    name="street"
                    autocomplete="address-line1"
                    :disabled="dto.isLoading"
                    v-model="model.street"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'street') }"
                  />
                  <label for="street"><Translate text="STOREFRONT_FORMS_ADDRESS_STREET" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'street')"><Translate text="STOREFRONT_FORMS_ADDRESS_STREET_VALIDATION" /></small>
              </div>

              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-home"></i>
                  <InputText type="text" id="streetNumber" name="streetNumber" autocomplete="address-line2" :disabled="dto.isLoading" v-model="model.streetNumber" />
                  <label for="streetNumber"><Translate text="STOREFRONT_FORMS_ADDRESS_STREETNUMBER" /></label>
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_STREET" /> / <Translate text="STOREFRONT_FORMS_ADDRESS_STREETNUMBER" /></span>
            <span>{{ model.street }} {{ model.streetNumber }}</span>
          </template>
        </div>

        <div class="entry" v-if="model.additional || isEditMode">
          <template v-if="isEditMode">
            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-plus"></i>
                <InputText type="text" id="additional" name="additional" autocomplete="address-line3" :disabled="dto.isLoading" v-model="model.additional" />
                <label for="additional"><Translate text="STOREFRONT_FORMS_ADDRESS_ADDITIONAL" /></label>
              </span>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_ADDITIONAL" /></span>
            <span>{{ model.additional }}</span>
          </template>
        </div>

        <div class="entry">
          <template v-if="isEditMode">
            <div class="form-row split">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-map-marker"></i>
                  <InputNumber
                    id="zipCode"
                    name="zipCode"
                    autocomplete="postal-code"
                    :disabled="dto.isLoading"
                    v-model="model.zipCode"
                    :min="1"
                    :max="9999"
                    :use-grouping="false"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'zipCode') }"
                  />
                  <label for="zipCode"><Translate text="STOREFRONT_FORMS_ADDRESS_ZIPCODE" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'zipCode')"><Translate text="STOREFRONT_FORMS_ADDRESS_ZIPCODE_VALIDATION" /></small>
              </div>
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-map"></i>
                  <InputText
                    type="text"
                    id="city"
                    name="city"
                    autocomplete="address-level2"
                    :disabled="dto.isLoading"
                    v-model="model.city"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'city') }"
                  />
                  <label for="city"><Translate text="STOREFRONT_FORMS_ADDRESS_CITY" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'city')"><Translate text="STOREFRONT_FORMS_ADDRESS_CITY_VALIDATION" /></small>
              </div>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_ZIPCODE" /> / <Translate text="STOREFRONT_FORMS_ADDRESS_CITY" /></span>
            <span>{{ model.zipCode }} {{ model.city }}</span>
          </template>
        </div>

        <div class="entry">
          <template v-if="isEditMode">
            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-globe"></i>
                <Dropdown
                  id="countryId"
                  name="country"
                  v-model="model.countryId"
                  :options="countryListDto.list"
                  :disabled="dto.isLoading"
                  option-value="model.id"
                  placeholder="Wähle ein Land"
                  @update:modelValue="$emit('change')"
                  class="required"
                  :class="{ 'p-invalid': validator.hasValidationErrors(dto, prefix + 'countryId') }"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value">{{ countryListDto.list.find((c) => c.model.id === slotProps.value)?.model.name() || "&nbsp;" }}</div>
                    <div v-else>&nbsp;</div>
                  </template>
                  <template #option="slotProps">
                    <div v-if="slotProps.option">{{ slotProps.option.model.name() }}</div>
                    <div v-else>&nbsp;</div>
                  </template>
                </Dropdown>
                <label for="countryId"><Translate text="STOREFRONT_FORMS_ADDRESS_COUNTRY" /></label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, prefix + 'countryId')"><Translate text="STOREFRONT_FORMS_ADDRESS_COUNTRY_VALIDATION" /></small>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_COUNTRY" /></span>
            <span>{{ model.country.name() }}</span>
          </template>
        </div>

        <div class="entry">
          <template v-if="isEditMode">
            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-phone"></i>
                <InputText type="text" id="telephoneNumber" name="telephoneNumber" autocomplete="tel" :disabled="dto.isLoading" v-model="model.telephoneNumber" />
                <label for="telephoneNumber"><Translate text="STOREFRONT_FORMS_ADDRESS_PHONE" /></label>
              </span>
            </div>
          </template>
          <template v-else>
            <span><Translate text="STOREFRONT_FORMS_ADDRESS_PHONE" /></span>
            <span>{{ model.telephoneNumber }}</span>
          </template>
        </div>
      </div>
      <div v-else>
        <p class="m-0"><Translate text="STOREFRONT_FORMS_NO_SHIPPING_ADDRESS_DEFINED" /></p>
      </div>

      <small v-if="isEditMode" class="required-explanation">* <Translate text="STOREFRONT_FORMS_REQUIRED" /></small>
    </form>
  </Panel>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs } from "vue";
import { AddressModel } from "@/models/AddressModel";
import { UserDto } from "@/dtos/UserDtos";
import { Validator } from "@/helpers/Validator";
import { IAddressModel } from "@/models/interfaces/IAddressModel";
import { UserModel } from "@/models/UserModel";
import { useCountryStore } from "@/stores/countryStore";
import { CountryListDto } from "@/dtos/CountryDtos";
import stringMixins from "@/mixins/stringMixins";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";

/**
 * Component that renders the one address panel (read only).
 */
export default defineComponent({
  name: "AddressPanel",
  components: { Translate },
  props: {
    model: {
      type: AddressModel,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    showEditButton: {
      type: Boolean,
      required: false,
    },
    isEditMode: {
      type: Boolean,
      required: false,
    },
    editModeUserDto: {
      type: UserDto,
      required: false,
    },
    editModeValidationPrefix: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryStore = useCountryStore();
    const validator = new Validator<IAddressModel>();

    const { editModeUserDto, editModeValidationPrefix } = toRefs(props);

    const dto = computed<InstanceType<typeof UserDto>>(() => editModeUserDto.value || new UserDto(new UserModel()));
    const prefix = computed(() => editModeValidationPrefix.value);

    const countryListDto = ref(new CountryListDto([]));

    const capitalizeFirstLetter = stringMixins.capitalizeFirstLetter;

    onMounted(() => {
      countryStore.getCountries(countryListDto.value);
    });

    return {
      dto,
      countryListDto,
      capitalizeFirstLetter,
      validator,
      prefix,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-top: 20px;

  :deep(.p-panel-header) {
    padding: 0.7rem 1.25rem;
  }

  :deep(.p-panel-icons) {
    display: inline-flex;
    align-items: center;

    label {
      width: auto;
    }
  }
}
</style>
