import { OrderModel } from "@/models/OrderModel";
import orderMixins from "@/mixins/orderMixins";

/**
 * Helper methods for order-related calculations.
 */
export default {
  /* -------------------------------------------
  Calculate the total selling price of all products of an order (without coupon reduction).
  ------------------------------------------- */
  computeTotalCostWithoutReduction(model: OrderModel): number {
    return model.orderProducts?.length > 0 ? model.orderProducts.map((p) => p.sellingPrice * p.amountOrdered).reduce((a, b) => a + b) : 0;
  },

  /* -------------------------------------------
  Calculate the total selling price of all products of an order and deduct the coupon reduction from it.
  ------------------------------------------- */
  computeTotalCost(model: OrderModel): number {
    const costWithoutReduction = orderMixins.computeTotalCostWithoutReduction(model);
    if (model.couponId !== null && model.coupon !== null) {
      return Math.max(costWithoutReduction - model.coupon.reductionInPacks, 0);
    }
    return costWithoutReduction;
  },

  /* -------------------------------------------
  Determine if this order has a coupon.
  ------------------------------------------- */
  hasOrderCoupon(model: OrderModel): boolean {
    return model.coupon !== null && model.coupon.id !== null && !isNaN(model.coupon.id);
  },

  /* -------------------------------------------
  Calculate the total amount of products ordered in one order.
  ------------------------------------------- */
  computeTotalAmountOrdered(orderModel: OrderModel): number {
    return orderModel.orderProducts.reduce((sum, current) => sum + current.amountOrdered, 0);
  },

  /* -------------------------------------------
  Calculate the total amount of products already marked as delivered in one order.
  ------------------------------------------- */
  computeTotalAmountDelivered(orderModel: OrderModel): number {
    return orderModel.orderProducts.reduce((sum, current) => sum + current.amountDelivered, 0);
  },
};
