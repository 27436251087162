import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7acd610d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "productDisplayRef",
  class: "product-display animated"
}
const _hoisted_2 = { class: "display" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductPrice = _resolveComponent("ProductPrice")!
  const _component_FFButton = _resolveComponent("FFButton")!
  const _component_Translate = _resolveComponent("Translate")!
  const _directive_animateonscroll = _resolveDirective("animateonscroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetails()))
      }, [
        _createElementVNode("h2", null, [
          _createTextVNode(_toDisplayString(_ctx.model.name()) + " ", 1),
          _createElementVNode("em", null, _toDisplayString(_ctx.model.subname()), 1)
        ])
      ]),
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetails()))
      }, [
        _withDirectives(_createElementVNode("img", {
          class: "animated animation-delay-100",
          src: _ctx.imageListDto.list.length > 0 ? _ctx.imageListDto.list[0].model.imageData : require('@/assets/img/placeholder.jpg')
        }, null, 8, _hoisted_3), [
          [
            _directive_animateonscroll,
            { enterClass: 'custom-scale-out' },
            void 0,
            { once: true }
          ]
        ])
      ]),
      _createVNode(_component_ProductPrice, {
        value: _ctx.model.price
      }, null, 8, ["value"]),
      _createVNode(_component_FFButton, {
        label: _ctx.Translator.translate('STOREFRONT_PRODUCTS_ADD_TO_CART'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addToCart())),
        loading: _ctx.isAddingToCart
      }, null, 8, ["label", "loading"]),
      _createElementVNode("a", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToDetails())),
        class: "mt-4"
      }, [
        _createElementVNode("h2", null, [
          _createVNode(_component_Translate, { text: "STOREFRONT_PRODUCTS_WANT_TO_KNOW_MORE" })
        ])
      ])
    ])
  ])), [
    [
      _directive_animateonscroll,
      { enterClass: 'custom-fade-in-down' },
      void 0,
      { once: true }
    ]
  ])
}