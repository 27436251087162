import * as Yup from "yup";
import { ISliderModel } from "@/models/interfaces/ISliderModel";
import { SliderModel } from "@/models/SliderModel";
import { BaseFormData } from "./BaseFormData";
import { SliderDto } from "../SliderDtos";
import { toRaw } from "vue";

export class SliderFormData extends BaseFormData<ISliderModel> implements ISliderModel {
  public titleDe: string;
  public titleFr: string;
  public textDe: string;
  public textFr: string;
  public linkDe: string;
  public linkFr: string;
  public image: string;
  public isActive: boolean;

  public originalData: SliderModel;

  constructor(dto: SliderDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.titleDe = dto.model.titleDe;
    this.titleFr = dto.model.titleFr;
    this.textDe = dto.model.textDe;
    this.textFr = dto.model.textFr;
    this.linkDe = dto.model.linkDe;
    this.linkFr = dto.model.linkFr;
    this.image = dto.model.image;
    this.isActive = dto.model.isActive;

    this.originalData = structuredClone(toRaw(dto.model));
    
    this.formSchema = Yup.object({
      isActive: Yup.boolean().default(true),
      titleDe: Yup.string().required(),
      titleFr: Yup.string().required(),
      textDe: Yup.string().required(),
      textFr: Yup.string().required(),
      linkDe: Yup.string().required(),
      linkFr: Yup.string().required(),
      image: Yup.string().required(),
    });
  }
}
