<template>
  <div class="inner">
    <div class="cart">
      <h2><Translate text="STOREFRONT_CART" /></h2>
      <template v-if="!isLoading">
        <template v-for="(orderProduct, index) of orderProducts" :key="orderProduct.product.id">
          <ProductCartEntry :model="orderProduct"></ProductCartEntry>
        </template>

        <p v-show="orderProducts.length === 0"><Translate text="STOREFRONT_CART_EMPTY" /></p>
      </template>
      <template v-else>
        <div class="flex justify-content-between">
          <LoadingSkeleton style="width: 5%" height="5rem"></LoadingSkeleton>
          <LoadingSkeleton style="width: 85%" height="5rem"></LoadingSkeleton>
          <LoadingSkeleton style="width: 8%" height="5rem"></LoadingSkeleton>
        </div>
        <div class="flex justify-content-between">
          <LoadingSkeleton style="width: 5%" height="5rem"></LoadingSkeleton>
          <LoadingSkeleton style="width: 85%" height="5rem"></LoadingSkeleton>
          <LoadingSkeleton style="width: 8%" height="5rem"></LoadingSkeleton>
        </div>
      </template>

      <Message v-for="outOfStockProduct of outOfStockProducts" severity="error" :closable="false">{{
        Translator.translateFormat("STOREFRONT_CART_NOT_ENOUGH_STOCK", outOfStockProduct.product.fullName(), outOfStockProduct.product.stock)
      }}</Message>

      <Message severity="info" :closable="false" class="info"><Translate text="STOREFRONT_CART_FREE_SHIPPING" /></Message>

      <ProductPrice v-if="orderProducts.length > 0" :value="priceTotal" :show-total="true"></ProductPrice>

      <Translate text="STOREFRONT_CART_DESCRIPTION" />
    </div>
    <div class="cart-buttons" v-if="orderProducts.length > 0">
      <FFButton
        @click="goToCheckout()"
        :loading="isAnyProductLoading"
        :disabled="!isValidOrder"
        :label="Translator.translate('STOREFRONT_CART_TO_CHECKOUT')"
        color="var(--ff-primary-black)"
      ></FFButton>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import ProductCartEntry from "@/components/storefront/landingpage/products/ProductCartEntry.vue";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "@/components/storefront/FFButton.vue";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import stringMixins from "@/mixins/stringMixins";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "StoreFrontCart",
  components: {
    ProductCartEntry,
    ProductPrice,
    FFButton,
    LoadingSkeleton,
    Translate,
  },
  methods: {
    goToCheckout() {
      this.$router.push("/checkout");
    },
  },
  setup() {
    const userStatusStore = useUserStatusStore();

    // Html references.
    const porductCartEntryRefs = ref<InstanceType<typeof ProductCartEntry>[]>([]);

    // use local isLoading state so cart is not always displayed as loading when updating amount in cart.
    const isLoading = ref(false);

    // setup computed fields.
    const orderProducts = computed(() => userStatusStore.cartDto.model.orderProductList);
    const isAnyProductLoading = computed(() => porductCartEntryRefs.value.some((e) => porductCartEntryRefs.value.some((p) => p.isAddingToCart)));
    const priceTotal = computed(() => (orderProducts.value.length > 0 ? orderProducts.value.map((p) => p.amountOrdered * p.product.price).reduce((total, price) => total + price) : 0));

    const outOfStockProducts = computed(() => orderProducts.value.filter((o) => o.amountOrdered > o.product.stock));
    const isValidOrder = computed(() => outOfStockProducts.value.length === 0);

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_CART")),
    });

    // refresh user status at this point.
    onMounted(() => {
      isLoading.value = true;
      userStatusStore.getUserStatus().finally(() => {
        isLoading.value = false;
      });
    });

    return {
      isLoading,
      orderProducts,
      priceTotal,
      porductCartEntryRefs,
      isAnyProductLoading,
      outOfStockProducts,
      Translator,
      isValidOrder,
    };
  },
});
</script>

<style scoped lang="scss">
.cart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 40px;

  > * {
    width: 100%;
    margin: 0;
  }

  .price {
    justify-content: flex-start;
  }
}
.cart-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  .primary-button {
    background: $ff-primary-black;
  }
}

:deep(.p-message.info) {
  margin: 0;
  background: $ff-storefront-light;
  color: $ff-primary-black;
  border: solid $ff-primary-black;
  border-width: 0 0 0 6px;

  svg {
    color: $ff-primary-black;
  }

  .p-message-wrapper {
    padding: 10px 20px;
  }
}
</style>
