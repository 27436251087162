import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { RegisterData } from "./RegisterData";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";
import { Language } from "@/enums/Language";

export class RegisterFormData extends BaseFormData<RegisterData> implements RegisterData {
  public username: string;
  public password: string;
  public passwordRepeat: string;
  public displayName: string;
  public language: Language;
  public hasNewsletter: boolean;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.username = "";
    this.password = "";
    this.passwordRepeat = "";
    this.displayName = "";
    this.language = Language.NONE;
    this.hasNewsletter = true;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
      passwordRepeat: Yup.string().required(),
      displayName: Yup.string().required(),
      language: Yup.mixed<Language>().required(),
      hasNewsletter: Yup.boolean().defined()
    });
  }
}
