import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ICartModel } from "./interfaces/ICartModel";
import { OrderProductModel } from "./OrderProductModel";
import { TypeHelper } from "@/helpers/TypeHelper";

export class CartModel extends BaseModel implements ICartModel {
  orderProductList: OrderProductModel[];

  constructor() {
    super();

    this.orderProductList = [];
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "orderProductList",
      cast: (x) => x.map((y: OrderProductModel) => TypeHelper.mappers.OrderProductModel(y)),
    },
  ];
}
