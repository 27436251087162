<template>
  <div class="inner">
    <router-link to="/profile" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_PROFILE" /></span>
    </router-link>

    <template v-if="dto && !dto.isLoading">
      <h2>
        <Translate text="STOREFRONT_PROFILE_ORDER" /> <em>{{ dto.model.orderNumber }}</em>
      </h2>
      <div class="order-info">
        <div class="entry">
          <span><Translate text="STOREFRONT_PROFILE_ORDER_STATUS" /></span>
          <span>
            <Tag v-if="dto.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">
              <Translate text="STOREFRONT_ORDER_STATUS_CANCELED" />
            </Tag>
            <Tag v-else-if="dto.model.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">
              <Translate text="STOREFRONT_ORDER_STATUS_DELIVERED" />
            </Tag>
            <Tag v-else-if="dto.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">
              <Translate text="STOREFRONT_ORDER_STATUS_PARTIALLY_DELIVERED" />
            </Tag>
            <Tag v-else-if="dto.model.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">
              <Translate text="STOREFRONT_ORDER_STATUS_UNPROCESSED" />
            </Tag>
          </span>
        </div>
        <div class="entry">
          <span><Translate text="STOREFRONT_PROFILE_ORDER_DATE" /></span>
          <span><DateDisplay :date="dto.model.orderDate" :with-time="true"></DateDisplay> </span>
        </div>
        <div class="entry">
          <span><Translate :text="dto.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED ? 'STOREFRONT_PROFILE_SHIPPING_DATE_PARTIAL' : 'STOREFRONT_PROFILE_SHIPPING_DATE'" /></span>
          <span v-if="dto.model.shippingDate"><DateDisplay :date="dto.model.shippingDate" :with-time="true"></DateDisplay> </span>
          <span v-else>-</span>
        </div>
        <div class="entry" v-if="dto.model.cancelationDate">
          <span><Translate text="STOREFRONT_PROFILE_CANCELATION_DATE" /></span>
          <span><DateDisplay :date="dto.model.cancelationDate" :with-time="true"></DateDisplay> </span>
        </div>
        <div class="entry">
          <span><Translate text="STOREFRONT_PROFILE_ORDER_TRACKINGNUMBER" /></span>
          <span v-if="dto.model.trackingNumber">
            <a :href="'https://service.post.ch/EasyTrack/submitParcelData.do?formattedParcelCodes=' + dto.model.trackingNumber" target="_blank" class="post-link">{{ dto.model.trackingNumber }}</a>
          </span>
          <span v-else>-</span>
        </div>
        <div class="entry" v-if="dto.model.couponId !== null">
          <span><Translate text="STOREFRONT_PROFILE_ORDER_USED_COUPON" /></span>
          <span>{{ dto.model.coupon?.code }}</span>
        </div>
      </div>

      <div v-if="dto.model.orderProducts">
        <DataTable :value="dto.model.orderProducts" :loading="dto.isLoading" dataKey="id" filterDisplay="menu" size="small" stripedRows>
          <template #loading> <ProgressSpinner strokeWidth="2" /> </template>
          <Column sortable filter field="model.status" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_STATUS')" header-style="width: 200px">
            <template #body="slotProps">
              <Tag v-if="dto.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">
                <Translate text="STOREFRONT_ORDER_STATUS_CANCELED" />
              </Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">
                <Translate text="STOREFRONT_ORDER_STATUS_DELIVERED" />
              </Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">
                <Translate text="STOREFRONT_ORDER_STATUS_PARTIALLY_DELIVERED" />
              </Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">
                <Translate text="STOREFRONT_ORDER_STATUS_UNPROCESSED" />
              </Tag>
            </template>
          </Column>
          <Column v-if="!isMobile" sortable filter field="amountOrdered" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_AMOUNT')">
            <template #body="slotProps"> {{ slotProps.data.amountOrdered }}x </template>
          </Column>
          <Column sortable filter field="product.articleNumber" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_ARTICLE')">
            <template #body="slotProps">
              <span v-if="isMobile">{{ slotProps.data.amountOrdered }}x&nbsp;</span>
              <span class="clickable" @click="goToProductDetails(slotProps.data.product.id)">{{ slotProps.data.product.articleNumber }} {{ slotProps.data.product.fullName() }}</span>
            </template>
          </Column>
          <Column sortable filter field="product.price" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_PRICE')" header-class="header-align-right">
            <template #body="slotProps">
              <span class="align-right"
                >{{ slotProps.data.product.price }}<span v-if="!isMobile">&nbsp;<Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span
              ></span>
            </template>
          </Column>
          <Column sortable filter field="sellingPrice" :header="Translator.translate('STOREFRONT_PROFILE_ORDER_PRICE_TOTAL')" header-class="header-align-right">
            <template #body="slotProps">
              <span class="align-right">
                {{ slotProps.data.sellingPrice * slotProps.data.amountOrdered }}<span v-if="!isMobile">&nbsp;<Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span
              ></span>
            </template>
          </Column>
          <template #footer>
            <span v-if="hasOrderCoupon(dto.model)" class="flex justify-content-between sum">
              <span><Translate text="STOREFRONT_PROFILE_ORDER_SUBTOTAL" /></span>
              <span>
                {{ computeTotalCostWithoutReduction(dto.model) }}<span v-if="!isMobile">&nbsp;<Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
              </span>
            </span>
            <span v-if="hasOrderCoupon(dto.model)" class="flex justify-content-between sum midsum">
              <span><Translate text="STOREFRONT_PROFILE_ORDER_COUPON_CODE" /> '{{ dto.model.coupon?.code }}'</span>
              <span>
                -{{ dto.model.coupon?.reductionInPacks }}<span v-if="!isMobile">&nbsp;<Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span
              ></span>
            </span>
            <span class="flex justify-content-between sum">
              <span><Translate text="STOREFRONT_PROFILE_ORDER_TOTAL" /></span>
              <span>
                {{ computeTotalCost(dto.model) }}<span v-if="!isMobile">&nbsp;<Translate text="STOREFRONT_CURRENCIES_SACHETS" /></span>
              </span>
            </span>
          </template>
        </DataTable>

        <h3 class="first"><Translate text="STOREFRONT_PROFILE_ORDER_MANAGEMENT" /></h3>
        <ManagementButtonPanel header="Weitere Aktionen">
          <div class="form-row split">
            <label><Translate text="STOREFRONT_PROFILE_ORDER" /></label>
            <Button v-if="dto.model.deliveryStatus !== DeliveryStatus.CANCELED" :label="Translator.translate('STOREFRONT_PROFILE_ORDER_CANCEL')" :severity="'info'" @click="cancelOrder"> </Button>
            <span v-else><Translate text="STOREFRONT_PROFILE_ORDER_CANCELED" /></span>
          </div>
        </ManagementButtonPanel>
      </div>
    </template>
    <div v-else>
      <LoadingSkeleton :skeleton-type="blockType"></LoadingSkeleton>
    </div>
  </div>

  <ConfirmDialog class="store-front-confirm-dialog" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import AddressPanel from "@/components/storefront/users/AddressPanel.vue";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import ManagementButtonPanel from "@/components/shared/ManagementButtonPanel.vue";
import { SkeletonType } from "@/enums/SkeletonType";
import routerMixins from "@/mixins/routerMixins";
import { FormHelper } from "@/helpers/FormHelper";
import { useRoute } from "vue-router";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { OrderListDto, OrderDto } from "@/dtos/OrderDtos";
import orderMixins from "@/mixins/orderMixins";
import { Constants } from "@/enums/Constants";
import { useBreakpoints } from "@vueuse/core";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontUserProfileOrderDetail",
  components: {
    FFButton,
    LoadingSkeleton,
    AddressPanel,
    DateDisplay,
    ManagementButtonPanel,
    Translate,
  },
  methods: {
    goToProductDetails(productId: string): void {
      this.$router.push("/products/" + productId);
    },
    cancelOrder(event: Event): void {
      const userProfileStore = useUserProfileStore();

      this.formHelper.prompt(
        event,
        Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_PROMPT_DESCRIPTION"),
        Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_PROMPT_TITLE"),
        Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_PROMPT_ACTION"),
        Translator.translate("STOREFRONT_GENERAL_CANCEL"),
        "pi pi-stop-circle",
        () => {
          // send cancellation request.
          userProfileStore
            .cancelOrder(this.dto)
            .then(async () => {
              // show toast if successful.
              this.showToast({
                life: 5000,
                severity: "success",
                summary: Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_SUCCESS_TITLE"),
                detail: Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_SUCCESS_DESCRIPTION"),
              });
            })
            .catch((error) => {
              // show toast if failed.
              this.showToast({
                life: 6000,
                severity: "error",
                summary: Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_FAILED_TITLE"),
                detail: Translator.translate("STOREFRONT_MESSAGES_ORDER_CANCELATION_FAILED_DESCRIPTION"),
              });
            });
        },
        () => {}
      );
    },
  },
  setup() {
    const toast = useToast();
    const route = useRoute();
    const userProfileStore = useUserProfileStore();

    const formHelper = new FormHelper("/profile");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const orderListDto = ref<InstanceType<typeof OrderListDto>>(new OrderListDto([]));
    const dto = computed(() => orderListDto.value.list.find((o) => o.model.id === id) as OrderDto);

    const blockType = SkeletonType.DETAILS;
    const computeTotalCost = orderMixins.computeTotalCost;
    const hasOrderCoupon = orderMixins.hasOrderCoupon;
    const computeTotalCostWithoutReduction = orderMixins.computeTotalCostWithoutReduction;

    const breakpoints = useBreakpoints(Constants.Breakpoints);
    const isMobile = breakpoints.isSmallerOrEqual("mobile");

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_PROFILE_ORDER") + " " + (dto.value ? dto.value.model.orderNumber : "")),
    });

    // get orders at this point.
    onMounted(() => {
      userProfileStore.getOrders(orderListDto.value);
    });

    return {
      dto,
      formHelper,
      blockType,
      isMobile,
      showToast,
      hasOrderCoupon,
      computeTotalCost,
      computeTotalCostWithoutReduction,
      DeliveryStatus,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.order-info {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .entry {
    display: inline-flex;
    column-gap: 20px;
    height: 25px;

    span {
      &:first-child {
        width: 220px;
        font-weight: bold;
      }
    }
  }
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
:deep(.header-align-right) {
  div {
    display: flex;
    justify-content: flex-end;
  }
}

.midsum {
  font-weight: normal;
}

.sum {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  border-top: 1px solid $ff-controlpanel-lighter;
  padding: 5px 0;
}

.post-link {
  color: $ff-primary-red;
  text-decoration: underline;
}

@media (max-width: $breakpoint-mobile) {
  .order-info {
    .entry {
      column-gap: 10px;

      > span {
        &:first-child {
          width: 50%;
        }

        span:first-child {
          width: 100%;
        }
      }
    }
  }
}
</style>
