<template>
  <div class="inner">
    <i class="pi pi-question-circle big"></i>
    <p>
      <strong><Translate text="STOREFRONT_PAGENOTFOUND_TITLE" /></strong>
    </p>
    <p><Translate text="STOREFRONT_PAGENOTFOUND_DESCRIPTION" /></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import Translate from "@/components/shared/Translate.vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontPageNotFound",
  components: {
    Translate,
  },
  setup() {
    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle("404"),
    });

    return {};
  },
});
</script>

<style scoped lang="scss">
.inner {
  text-align: center;
}
.big {
  font-size: 2rem;
  color: $ff-primary-black;
}
</style>
