import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-326730ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DateDisplay = _resolveComponent("DateDisplay")!
  const _component_Translate = _resolveComponent("Translate")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.listDto.list,
    loading: _ctx.listDto.isLoading,
    dataKey: "model.id",
    filterDisplay: "menu",
    size: "small",
    stripedRows: "",
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToOrderDetails($event.data.model.id)))
  }, {
    loading: _withCtx(() => [
      _createVNode(_component_ProgressSpinner, { strokeWidth: "2" })
    ]),
    footer: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.footerText), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        sortable: "",
        filter: "",
        field: "model.orderNumber",
        header: _ctx.Translator.translate(_ctx.isMobile ? 'STOREFRONT_PROFILE_ORDER_NUMBER_SHORT' : 'STOREFRONT_PROFILE_ORDER_NUMBER')
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", {
            class: "clickable",
            onClick: ($event: any) => (_ctx.goToOrderDetails(slotProps.data.model.id))
          }, _toDisplayString(slotProps.data.model.orderNumber), 9, _hoisted_1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        sortable: "",
        filter: "",
        field: "model.orderDate",
        header: _ctx.Translator.translate('STOREFRONT_PROFILE_ORDER_DATE')
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_DateDisplay, {
            date: slotProps.data.model.orderDate,
            withTime: true
          }, null, 8, ["date"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        sortable: "",
        filter: "",
        field: "model.deliveryStatus",
        header: _ctx.Translator.translate('STOREFRONT_PROFILE_ORDER_STATUS'),
        "header-style": "width: 200px"
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.model.deliveryStatus === _ctx.DeliveryStatus.CANCELED)
            ? (_openBlock(), _createBlock(_component_Tag, {
                key: 0,
                severity: 'error'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Translate, { text: "STOREFRONT_ORDER_STATUS_CANCELED" })
                ]),
                _: 1
              }))
            : (slotProps.data.model.deliveryStatus === _ctx.DeliveryStatus.DELIVERED)
              ? (_openBlock(), _createBlock(_component_Tag, {
                  key: 1,
                  severity: 'success'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Translate, { text: "STOREFRONT_ORDER_STATUS_DELIVERED" })
                  ]),
                  _: 1
                }))
              : (slotProps.data.model.deliveryStatus === _ctx.DeliveryStatus.PARTIALLY_DELIVERED)
                ? (_openBlock(), _createBlock(_component_Tag, {
                    key: 2,
                    severity: 'info'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Translate, { text: "STOREFRONT_ORDER_STATUS_PARTIALLY_DELIVERED" })
                    ]),
                    _: 1
                  }))
                : (slotProps.data.model.deliveryStatus === _ctx.DeliveryStatus.UNPROCESSED)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 3,
                      severity: 'warning'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Translate, { text: "STOREFRONT_ORDER_STATUS_UNPROCESSED" })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "model.amountDelivered",
        header: _ctx.Translator.translate('STOREFRONT_PROFILE_ORDER_ALREADY_SENT')
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.computeTotalAmountDelivered(slotProps.data.model)) + " ", 1),
          _createVNode(_component_Translate, { text: "STOREFRONT_PROFILE_ORDER_OF" }),
          _createTextVNode(" " + _toDisplayString(_ctx.computeTotalAmountOrdered(slotProps.data.model)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        sortable: "",
        filter: "",
        field: "model.shippingDate",
        header: _ctx.Translator.translate('STOREFRONT_PROFILE_ORDER_SHIPPING_DATE')
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.model.shippingDate)
            ? (_openBlock(), _createBlock(_component_DateDisplay, {
                key: 0,
                date: slotProps.data.model.shippingDate,
                withTime: true
              }, null, 8, ["date"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
        ]),
        _: 1
      }, 8, ["header"]),
      (!_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            sortable: "",
            filter: "",
            header: _ctx.Translator.translate('STOREFRONT_PROFILE_ORDER_PRICE_TOTAL')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.computeTotalCost(slotProps.data.model)) + " ", 1),
              _createVNode(_component_Translate, { text: "STOREFRONT_CURRENCIES_SACHETS" })
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}