import { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { Constants } from "@/enums/Constants";
import { UserStatusViewModel } from "@/viewModels/UserStatusViewModel";
import { LoginViewModel } from "@/viewModels/LoginViewModel";
import { RegisterViewModel } from "@/viewModels/RegisterViewModel";
import { LoginData } from "@/dtos/data/LoginData";
import { RegisterData } from "@/dtos/data/RegisterData";

export class UserStatusApiController extends ApiControllerCore {
  public login(loginData: LoginData): Promise<AxiosResponse<LoginViewModel>> {
    return super._internalPost(Constants.ApiEndpoints.USER_STATUS, loginData);
  }

  public logout(): Promise<AxiosResponse<LoginViewModel>> {
    return super._internalDelete(Constants.ApiEndpoints.USER_STATUS);
  }

  public register(data: RegisterData): Promise<AxiosResponse<RegisterViewModel>> {
    return super._internalPost(Constants.ApiEndpoints.USERS, data);
  }

  public getUserStatus(): Promise<AxiosResponse<UserStatusViewModel>> {
    return super._internalGet(Constants.ApiEndpoints.USER_STATUS);
  }

  public updateProductCartAmount(productId: number, amount: number): Promise<AxiosResponse<UserStatusViewModel>> {
    return super._internalPut(`${Constants.ApiEndpoints.USER_STATUS_CART_PRODUCTS}/${productId}`, {
      amount: amount,
    });
  }

  public removeProductFromCart(productId: number): Promise<AxiosResponse<UserStatusViewModel>> {
    return super._internalDelete(`${Constants.ApiEndpoints.USER_STATUS_CART_PRODUCTS}/${productId}`);
  }
}
