<template>
  <div class="inner pb-0">
    <h2>
      <em><Translate text="STOREFRONT_HOWDOESITWORK_PAGETITLE" /></em>
    </h2>
    <Translate text="STOREFRONT_HOWDOESITWORK_PAGECONTENT" />
  </div>
  <template v-for="(step, index) of howDoesItWorkSteps">
    <div class="inner steps" :style="{ backgroundColor: step.bgColor, color: step.color }">
      <div class="step animated" v-animateonscroll.once="{ enterClass: 'fadein-left' }">
        <div class="icon"><i :class="step.icon" /></div>
        <div>
          <h2>{{ step.label }}</h2>
          <p>{{ step.description }}</p>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useHead } from "@unhead/vue";
import Translate from "@/components/shared/Translate.vue";
import { Constants } from "@/enums/Constants";
import { Translator } from "@/helpers/Translator";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontHowDoesItWork",
  components: {
    Translate,
  },
  setup() {
    const randomTheme = Constants.ColorThemes[Math.floor(Math.random() * Constants.ColorThemes.length)];

    const howDoesItWorkSteps = ref([
      {
        label: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP1_TITLE"),
        bgColor: "white",
        icon: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP1_ICON"),
        description: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP1_DESCRIPTION"),
      },
      {
        label: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP2_TITLE"),
        bgColor: "white",
        icon: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP2_ICON"),
        description: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP2_DESCRIPTION"),
      },
      {
        label: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP3_TITLE"),
        bgColor: "white",
        icon: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP3_ICON"),
        description: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP3_DESCRIPTION"),
      },
      {
        label: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP4_TITLE"),
        bgColor: randomTheme.packageColor,
        color: randomTheme.textColor,
        icon: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP4_ICON"),
        description: Translator.translate("STOREFRONT_HOWDOESITWORK_STEP4_DESCRIPTION"),
      },
    ]);

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_HOWDOESITWORK_PAGETITLE")),
    });

    return {
      howDoesItWorkSteps,
    };
  },
});
</script>

<style scoped lang="scss">
.inner.steps {
  padding: 50px 15%;

  &:hover {
    .step {
      padding-left: 20px;
    }
  }

  .step {
    transition: margin 300ms ease, padding 300ms ease;

    display: flex;

    &.fadein-left {
      animation: fadein-left 1s;
    }

    .icon {
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 10%;
      min-width: 100px;

      i {
        font-size: 3rem;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .inner.steps {
    &:hover {
      .step {
        padding-left: 0;
      }
    }

    .step {
      column-gap: 20px;

      .icon {
        width: auto;
        min-width: unset;
      }
    }
  }
}

@keyframes fadein-left {
  0% {
    opacity: 0;
    margin-left: -50px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
</style>
