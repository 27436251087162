import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ITranslationModel } from "./interfaces/ITranslationModel";
import { UserModel } from "./UserModel";

export class TranslationModel extends BaseModel implements ITranslationModel {
  public translationKey: string;
  public languageDe: string;
  public languageFr: string;
  public createdById: number;
  public createdBy: UserModel;
  public updatedById: number | null;
  public updatedBy: UserModel | null;
  public isHtml: boolean;

  constructor() {
    super();

    this.translationKey = "";
    this.languageDe = "";
    this.languageFr = "";
    this.createdById = -1;
    this.createdBy = new UserModel();
    this.updatedById = null;
    this.updatedBy = null;
    this.isHtml = false;
  }

  public static readonly mappings: TypeMapping[] = [];
}
