import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { OrderListViewModel, OrderViewModel } from "@/viewModels/ViewModels";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { OrderApiController } from "@/api/OrderApiController";
import { OrderListDto, OrderProductDto } from "@/dtos/OrderDtos";
import { OrderDto } from "@/dtos/OrderDtos";
import { OrderModel } from "@/models/OrderModel";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { OrderState } from "@/dtos/state/OrderState";

const apiController = new OrderApiController();
const storeApiProxy = new StoreApiProxy<OrderModel, OrderDto, OrderListDto, OrderViewModel, OrderListViewModel>(apiController, OrderListDto.mappings, OrderDto.mappings);

export const useOrderStore = defineStore("order", {
  state: (): OrderState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
    orderReference: null,
    orderId: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all orders from API.
     * -------------------------------------------- */
    async getOrders(listDto: OrderListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single order from API.
     * -------------------------------------------- */
    async getOrder(dto: OrderDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one order DTO.
     * -------------------------------------------- */
    async updateOrder(dto: OrderDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Saves one order product DTO amount delivered.
     * -------------------------------------------- */
    async updateOrderProduct(orderId: number, dto: OrderProductDto): Promise<void> {
      return apiController.updateOrderProduct(orderId, dto);
    },

    /* -------------------------------------------
     * Saves one order product DTO amount delivered.
     * -------------------------------------------- */
    async sendSachetsReceivedMail(dto: OrderDto): Promise<void> {
      return apiController.sendSachetsReceivedMail(dto);
    },

    /* -------------------------------------------
     * Creates a new order DTO.
     * -------------------------------------------- */
    async createOrder(dto: OrderDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Cancels an existing order DTO.
     * -------------------------------------------- */
    async cancelOrder(dto: OrderDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: OrderListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportReceipt(orderId: number): Promise<void> {
      return apiController.exportReceipt(orderId);
    },

    // ===== Mutations =====

    setSortColumn(listDto: OrderListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: OrderListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: OrderListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: OrderListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: OrderListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
