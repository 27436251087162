import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IProductCategoryModel } from "./interfaces/IProductCategoryModel";
import { useTranslationStore } from "@/stores/translationStore";
import { Language } from "@/enums/Language";

export class ProductCategoryModel extends BaseModel implements IProductCategoryModel {
  public nameDe: string;
  public nameFr: string;

  constructor() {
    super();

    this.nameDe = "";
    this.nameFr = "";
  }

  public name(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.nameDe : this.nameFr;
  };

  public static readonly mappings: TypeMapping[] = [];
}
