import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/ff_logo_wordmark_trawler_black_frame_rgb.png'


const _withScopeId = n => (_pushScopeId("data-v-4d1c357d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "social-media" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "links" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "copyright" }
const _hoisted_11 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: _ctx.ffSocialMediaLinks.facebook,
        class: "ff-icon facebook",
        target: "blank"
      }, null, 8, _hoisted_2),
      _createElementVNode("a", {
        href: _ctx.ffSocialMediaLinks.instagram,
        class: "ff-icon instagram",
        target: "blank"
      }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        href: _ctx.ffSocialMediaLinks.tiktok,
        class: "ff-icon tiktok",
        target: "blank"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: _ctx.Translator.translate('STOREFRONT_NAVIGATION_FOOTER_SUSTAINABILITY_LINK'),
        target: "_blank"
      }, [
        _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_SUSTAINABILITY" })
      ], 8, _hoisted_6),
      _createVNode(_component_router_link, { to: "/privacy-protection" }, {
        default: _withCtx(() => [
          _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_IMPRESSUM" })
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/contact" }, {
        default: _withCtx(() => [
          _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_CONTACT" })
        ]),
        _: 1
      }),
      _createElementVNode("a", {
        href: _ctx.Translator.translate('STOREFRONT_NAVIGATION_FOOTER_CERTIFICATES_LINK'),
        target: "_blank"
      }, [
        _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_CERTIFICATES" })
      ], 8, _hoisted_7),
      _createElementVNode("a", {
        href: _ctx.Translator.translate('STOREFRONT_NAVIGATION_FOOTER_INFORMATION_LINK'),
        target: "_blank"
      }, [
        _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_INFORMATION" })
      ], 8, _hoisted_8),
      _createElementVNode("a", {
        href: _ctx.Translator.translate('STOREFRONT_NAVIGATION_FOOTER_FF_MAIN_PAGE_LINK'),
        target: "_blank"
      }, [
        _createVNode(_component_Translate, { text: "STOREFRONT_NAVIGATION_FOOTER_FF_MAIN_PAGE" })
      ], 8, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: _ctx.Translator.translate('STOREFRONT_TITLE')
      }, null, 8, _hoisted_11),
      _createElementVNode("span", null, "© " + _toDisplayString(_ctx.legalLine), 1)
    ])
  ]))
}