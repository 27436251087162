import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_UnderConstruction = _resolveComponent("UnderConstruction")!
  const _component_FFProgressSpinner = _resolveComponent("FFProgressSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DebugDisplay = _resolveComponent("DebugDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 0 }))
      : (_ctx.isUnderConstruction)
        ? (_openBlock(), _createBlock(_component_UnderConstruction, { key: 1 }))
        : (!_ctx.isStateRestored)
          ? (_openBlock(), _createBlock(_component_FFProgressSpinner, {
              key: 2,
              style: {"margin-top":"20%"}
            }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 3 })),
    _createVNode(_component_DebugDisplay)
  ], 64))
}