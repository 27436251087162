import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import primeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Axios from "axios";
import VueGtag from "vue-gtag";

// import primevue.
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import ProgressSpinner from "primevue/progressspinner";
import Message from "primevue/message";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Tag from "primevue/tag";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import FileUpload from "primevue/fileupload";
import Image from "primevue/image";
import Panel from "primevue/panel";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import Skeleton from "primevue/skeleton";
import Menubar from "primevue/menubar";
import Calendar from "primevue/calendar";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Galleria from "primevue/galleria";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Tooltip from "primevue/tooltip";
import AnimateOnScroll from "primevue/animateonscroll";
import Chip from "primevue/chip";
import Steps from "primevue/steps";
import Carousel from "primevue/carousel";
import Card from "primevue/card";
import Divider from "primevue/divider";
import Chart from "primevue/chart";
import Editor from "primevue/editor";
import Avatar from "primevue/avatar";

// import global scss.
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/scss/main.scss";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import vueDebounce from "vue-debounce";

// setup defaults for project.
Axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
Axios.defaults.headers.put["Content-Type"] = "application/x-www-form-urlencoded";
Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_BASE_URL;
Axios.defaults.withCredentials = true;

// create state management system.
const pinia = createPinia();

// add meta plugin.
const head = createHead();

// create and setup app.
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(head);
app.use(primeVue, {
  locale: {
    firstDayOfWeek: 1, // change to Monday
  },
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    params: {
      anonymize_ip: true,
    },
  },
  enabled: true,
});

// add Primevue components.
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Message", Message);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Tag", Tag);
app.component("Checkbox", Checkbox);
app.component("Textarea", Textarea);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Toast", Toast);
app.component("FileUpload", FileUpload);
app.component("Image", Image);
app.component("Panel", Panel);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("Skeleton", Skeleton);
app.component("Menubar", Menubar);
app.component("Calendar", Calendar);
app.component("Badge", Badge);
app.component("Galleria", Galleria);
app.component("Dropdown", Dropdown);
app.component("MultiSelect", MultiSelect);
app.component("Chip", Chip);
app.component("Steps", Steps);
app.component("Carousel", Carousel);
app.component("Card", Card);
app.component("Divider", Divider);
app.component("Chart", Chart);
app.component("Editor", Editor);
app.component("Avatar", Avatar);

// add Primevue directives.
app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);
app.directive("animateonscroll", AnimateOnScroll);
app.directive("debounce", vueDebounce({ lock: true }));

// mount to root div.
app.mount("#app");
