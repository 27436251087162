import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ISliderModel } from "./interfaces/ISliderModel";
import { useTranslationStore } from "@/stores/translationStore";
import { Language } from "@/enums/Language";

export class SliderModel extends BaseModel implements ISliderModel {
  titleDe: string;
  titleFr: string;
  textDe: string;
  textFr: string;
  linkDe: string;
  linkFr: string;
  isActive: boolean;
  image: string;

  constructor() {
    super();

    this.titleDe = "";
    this.titleFr = "";
    this.textDe = "";
    this.textFr = "";
    this.linkDe = "";
    this.linkFr = "";
    this.isActive = true;
    this.image = "";
  }

  public title(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.titleDe : this.titleFr;
  };
  public text(): string | null | undefined {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.textDe : this.textFr;
  };
  public link(): string {
    const translationStore = useTranslationStore();
    return translationStore.currentLanguage === Language.DE ? this.linkDe : this.linkFr;
  };

  public static readonly mappings: TypeMapping[] = [];
}
