import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";

class ContactFormEntryDto extends ModelDto<ContactFormEntryModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.ContactFormEntryModel(x),
    },
  ];
}

class ContactFormEntryListDto extends ListDto<ContactFormEntryDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.ContactFormEntryDto(y)),
    },
  ];
}

export { ContactFormEntryDto, ContactFormEntryListDto };
