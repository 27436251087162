<template>
  <StoreFrontNavigation :isBarebone="true"></StoreFrontNavigation>
  <div class="content">
    <div class="inner">
      <i class="pi pi-wrench big"></i>
      <p>
        <strong>{{ pageUnderConstructionErrorTitle }}</strong>
      </p>
      <p>{{ pageUnderConstructionErrorDescription }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import { useTranslationStore } from "@/stores/translationStore";
import { Language } from "@/enums/Language";
import StoreFrontNavigation from "@/components/storefront/StoreFrontNavigation.vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontUnderConstruction",
  components: {
    StoreFrontNavigation,
  },
  setup() {
    const translationStore = useTranslationStore();

    // Those translations are hardcoded, because they are displayed when the actual translation request fails.
    const pageUnderConstructionErrorTitle = computed(() => (translationStore.currentLanguage == Language.FR ? "Temporairement indisponible" : "Vorübergehend nicht verfügbar"));
    const pageUnderConstructionErrorDescription = computed(() =>
      translationStore.currentLanguage == Language.FR
        ? "La page est actuellement en cours de maintenance. Veuillez réessayer plus tard."
        : "Die Seite wird aktuell gewartet. Bitte versuche es später erneut."
    );

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(translationStore.currentLanguage == Language.FR ? "Temporairement indisponible" : "Vorübergehend nicht verfügbar"),
    });

    return {
      pageUnderConstructionErrorTitle,
      pageUnderConstructionErrorDescription,
    };
  },
});
</script>

<style scoped lang="scss">
.inner {
  text-align: center;
}
.big {
  font-size: 2rem;
  color: $ff-primary-red;
}
</style>
