export enum RegisterReturnCode {
  NONE = 0,
  INVALID = 1,
  PASSWORDS_DO_NOT_MATCH = 2,
  INVALID_VALUES = 3,
  ALREADY_LOGGED_IN = 4,
  USERNAME_ALREADY_EXISTS = 5,
  USER_REGISTERED = 6,
  PASSWORD_DOES_NOT_MEET_COMPLEXITY_RULES = 7,
}
