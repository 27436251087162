import { TypeHelper } from "@/helpers/TypeHelper";
import { AddressModel } from "./AddressModel";
import { BaseModel } from "./BaseModel";
import { RoleModel } from "./RoleModel";
import { IUserModel } from "./interfaces/IUserModel";
import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { Language } from "@/enums/Language";

export class UserModel extends BaseModel implements IUserModel {
  public username: string;
  public password: string;
  public mfaEnabled: boolean;
  public notes?: string | null;
  public isActive: boolean;
  public hasNewsletter: boolean;
  public lastLoginDate?: Date | null;
  public displayName: string;
  public language: Language;
  public roleId: number;
  public role: RoleModel;
  public shippingAddress: AddressModel;
  public billingAddress?: AddressModel | null;
  public useSameAddress: boolean;
  public isMigrated: boolean;
  public migrationId?: number | null;
  public migrationDate?: Date | null;

  constructor() {
    super();

    this.username = "";
    this.password = "";
    this.mfaEnabled = false;
    this.notes = null;
    this.isActive = false;
    this.hasNewsletter = false;
    this.lastLoginDate = null;
    this.displayName = "";
    this.language = Language.NONE;
    this.roleId = -1;
    this.role = new RoleModel();
    this.shippingAddress = new AddressModel();
    this.billingAddress = null;
    this.useSameAddress = true;
    this.isMigrated = false;
    this.migrationDate = null;
    this.migrationId = null;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "lastLoginDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "migrationDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "role",
      cast: (x) => TypeHelper.mappers.RoleModel(x),
    },
    {
      key: "shippingAddress",
      cast: (x) => TypeHelper.mappers.AddressModel(x),
    },
    {
      key: "billingAddress",
      cast: (x) => TypeHelper.mappers.AddressModel(x),
    },
  ];
}
