<template>
  <div class="title-panel">
    <h2>Log-Eintrag einsehen</h2>
  </div>

  <ContactFormEntriesCreateEdit :id="id" :dto="dto"></ContactFormEntriesCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import ContactFormEntriesCreateEdit from "@/components/controlpanel/contactFormEntries/ContactFormEntriesCreateEdit.vue";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";
import { useContactFormEntryStore } from "@/stores/contactFormEntryStore";

/**
 * View that renders the the "edit" form for contactFormEntries.
 */
export default defineComponent({
  name: "ContactFormEntriesEdit",
  setup() {
    const store = useContactFormEntryStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/contactformentries");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new ContactFormEntryDto(new ContactFormEntryModel()));

    // get entity from store.
    onMounted(() => {
      store.getContactFormEntry(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { ContactFormEntriesCreateEdit },
});
</script>

<style scoped lang="scss"></style>
