import { ProductDto } from "@/dtos/ProductDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { ProductCategoryViewModel, ProductImageListViewModel, ProductImageViewModel, ProductListViewModel, ProductViewModel } from "@/viewModels/ViewModels";
import { ProductModel } from "@/models/ProductModel";
import { AxiosResponse } from "axios";
import { ProductImageModel } from "@/models/ProductImageModel";

export class ProductApiController extends BaseApiController<ProductModel, ProductDto, ProductViewModel, ProductListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.PRODUCTS);
  }

  public async getHighlightProducts(): Promise<AxiosResponse<ProductListViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/highlight`);
  }

  public async getActiveProducts(categoryId: number | null): Promise<AxiosResponse<ProductListViewModel>> {
    const urlQuery = categoryId !== null ? `?category=${categoryId}` : "";
    
    return super._internalGet(`${this.apiEndpoint}/active${urlQuery}`);
  }

  public async getImages(productId: number): Promise<AxiosResponse<ProductImageListViewModel>> {
    return super._internalGet(this.apiEndpoint + "/" + productId + "/images");
  }

  public async createImage(productId: number, model: ProductImageModel): Promise<AxiosResponse<ProductImageViewModel>> {
    return super._internalPost(this.apiEndpoint + "/" + productId + "/images", model);
  }

  public async updateImage(productId: number, model: ProductImageModel): Promise<AxiosResponse<ProductImageViewModel>> {
    return super._internalPut(this.apiEndpoint + "/" + productId + "/images/" + model.id, model);
  }

  public async deleteImage(productId: number, model: ProductImageModel): Promise<AxiosResponse<ProductImageViewModel>> {
    return super._internalDelete(this.apiEndpoint + "/" + productId + "/images/" + model.id);
  }

  public async createCategory(productId: number, categoryId: number): Promise<AxiosResponse<ProductCategoryViewModel>> {
    return super._internalPut(this.apiEndpoint + "/" + productId + "/categories/" + categoryId, {});
  }

  public async deleteCategory(productId: number, categoryId: number): Promise<AxiosResponse<ProductCategoryViewModel>> {
    return super._internalDelete(this.apiEndpoint + "/" + productId + "/categories/" + categoryId);
  }

  public async createSimilarProduct(productId: number, similarProductId: number): Promise<AxiosResponse<ProductViewModel>> {
    return super._internalPut(this.apiEndpoint + "/" + productId + "/similarproducts/" + similarProductId, {});
  }

  public async deleteSimilarProduct(productId: number, similarProductId: number): Promise<AxiosResponse<ProductViewModel>> {
    return super._internalDelete(this.apiEndpoint + "/" + productId + "/similarproducts/" + similarProductId);
  }
}
