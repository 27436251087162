<template>
  <fieldset v-if="form.isReady()" :disabled="isArchived(dto.model)">
    <Message v-if="isArchived(dto.model)" severity="warn" class="archived" :closable="false">Diese Bestellung ist archiviert.</Message>

    <form @submit.prevent="" @change="validator.validateForm(form as CouponFormData, dto)">
      <Panel :header="'Details'">
        <div class="active-panel">
          <InputSwitch id="active" v-model="form.isActive" :disabled="isArchived(dto.model)" />
          <label for="active" class="p-label">{{ form.isActive ? "Aktiv" : "Inaktiv" }}</label>
        </div>

        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-gift"></i>
              <InputText type="text" id="code" :disabled="dto.isLoading" v-model="form.code" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'code') }" />
              <label for="code">Code</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'code')">Code ist ein Pflichtfeld.</small>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-wallet"></i>
              <InputNumber
                id="reductionInPacks"
                :disabled="dto.isLoading"
                v-model="form.reductionInPacks"
                showButtons
                :min="0"
                :useGrouping="false"
                :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'reductionInPacks') }"
                @update:modelValue="validator.validateForm(form as CouponFormData, dto)"
              />
              <label for="reductionInPacks">Anzahl Sachets</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'reductionInPacks')">Anzahl Sachets ist ein Pflichtfeld.</small>
          </div>
        </div>

        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-calendar"></i>
              <Calendar id="validFromDate" :disabled="dto.isLoading" v-model="form.validFromDate" dateFormat="dd.mm.yy" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'validFromDate') }" />
              <label for="validFromDate">Gültig von</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'validFromDate')">Gültig von ist ein Pflichtfeld.</small>
          </div>
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-calendar-times"></i>
              <Calendar id="validToDate" :disabled="dto.isLoading" v-model="form.validToDate" dateFormat="dd.mm.yy" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'validToDate') }" />
              <label for="validToDate">Gültig bis</label>
            </span>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'validToDate')">Gültig bis ist ein Pflichtfeld.</small>
          </div>
        </div>

        <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>
      </Panel>

      <Panel :header="'Bestellungen mit diesem Coupon'" v-if="form.isExisting()">
        <OrdersDataTable
          :listDto="ordersListDto"
          :details-link="`/controlpanel/orders/`"
          :footer-text="`Es gibt ${ordersListDto.list.length} Bestellungen mit diesem Coupon.`"
          :showAdminButtons="false"
        ></OrdersDataTable>
      </Panel>

      <div class="button-panel">
        <Button type="button" label="Löschen" :severity="'danger'" icon="pi pi-trash" @click="onDelete($event)"></Button>

        <div>
          <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
        </div>
      </div>

      <MetadataPanel :dto="dto"></MetadataPanel>
    </form>
  </fieldset>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <ConfirmDialog></ConfirmDialog>
  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { CouponDto } from "@/dtos/CouponDtos";
import { CouponFormData } from "@/dtos/data/CouponFormData";
import { useCouponStore } from "@/stores/couponStore";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ICouponModel } from "@/models/interfaces/ICouponModel";
import { ToastMessageOptions } from "primevue/toast";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Translator } from "@/helpers/Translator";
import OrdersDataTable from "@/components/shared/OrdersDataTable.vue";
import { OrderDto, OrderListDto } from "@/dtos/OrderDtos";
import Translate from "@/components/shared/Translate.vue";
import MetadataPanel from "../MetadataPanel.vue";
import modelMixins from "@/mixins/modelMixins";

/**
 * A shared component used for editing and creating products.
 */
export default defineComponent({
  name: "CouponsCreateEdit",
  props: {
    id: Number,
    dto: {
      type: CouponDto,
      required: true,
    },
  },
  components: { LoadingSkeleton, OrdersDataTable, Translate, MetadataPanel },
  methods: {
    async onSave() {
      const store = useCouponStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as CouponFormData, this.dto).then(() => {
        if (this.form.isNew()) {
          // send POST request and redirect if success.
          store.createCoupon(this.dto as CouponDto).then(async () => {
            // refresh list and return to list.
            this.formHelper.returnToList();
          });
        } else {
          // send PUT request and show toast if successful.
          store.updateCoupon(this.dto as CouponDto).then(async () => {
            this.showToast({
              life: 5000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Der Coupon wurde erfolgreich aktualisiert.",
            });
          });
        }
      });
    },
    async onDelete(event: Event) {
      const store = useCouponStore();

      this.formHelper.promptForDeletion(
        event,
        () => {
          // send DELETE request and redirect if success.
          store.deleteCoupon(this.dto as CouponDto).then(async () => {
            // and return to list.
            this.formHelper.returnToList();
          });
        },
        () => {}
      );
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<ICouponModel>();
    const formHelper = new FormHelper("/controlpanel/coupons");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new CouponFormData(dto.value));

    const ordersListDto = computed(() => new OrderListDto(dto.value.model.orders.map((o) => new OrderDto(o))));

    const isArchived = modelMixins.isCouponArchived;

    // setup form watchers.
    const updateFormData = (newValue: CouponDto) => {
      if (newValue?.model !== null) form.value = new CouponFormData(newValue);
    };
    dto.value.model.id === -1 ? watch(dto, updateFormData) : watch(dto.value, updateFormData);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      form,
      isArchived,
      formHelper,
      validator,
      ordersListDto,
      showToast,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.button-panel,
.p-panel {
  margin-bottom: 40px;
}
</style>
