import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d5971b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "contentRef",
  class: "html-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.asHtml)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, null, 512))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(_toDisplayString(_ctx.content), 1)
      ], 64))
}