import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { CouponModel } from "@/models/CouponModel";
import { OrderModel } from "@/models/OrderModel";
import { ProductModel } from "@/models/ProductModel";

/**
 * Helper methods related to migration.
 */
export default {
  /* -------------------------------------------
  Decide if this entity is archived.
  ------------------------------------------- */
  isCouponArchived(model: CouponModel): boolean {
    return model.isMigrated && !model.isActive && model.updatedAt === null;
  },

  /* -------------------------------------------
  Decide if this entity is archived.
  ------------------------------------------- */
  isOrderArchived(model: OrderModel): boolean {
    return model.isMigrated && (model.deliveryStatus == DeliveryStatus.CANCELED || model.deliveryStatus == DeliveryStatus.DELIVERED);
  },

  /* -------------------------------------------
  Decide if this entity is archived.
  ------------------------------------------- */
  isProductArchived(model: ProductModel): boolean {
    return model.isMigrated && !model.isActive && model.updatedAt === null;
  },
};
