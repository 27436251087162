<template>
  <template v-if="asHtml">
    <span ref="contentRef" class="html-text"></span>
  </template>
  <template v-else>
    {{ content }}
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { Translator } from "@/helpers/Translator";
import { useTranslationStore } from "@/stores/translationStore";
import { storeToRefs } from "pinia";

/**
 * Simple text translator component that supports HTML strings.
 */
export default defineComponent({
  name: "Translate",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const translationStore = useTranslationStore();

    const { currentLanguage } = storeToRefs(translationStore);
    const contentRef = ref();
    const content = ref("");

    const asHtml = ref(false);

    const translate = () => {
      let translatedText = props.text;

      // translate text first.
      const translation = Translator.getTransaltionDtoByKey(props.text);
      if (translation) {
        translatedText = Translator.translateDto(translation);
        asHtml.value = translation.model.isHtml;
      }

      // set innerHTML to support HTML strings as well.
      if (asHtml.value) {
        if (contentRef.value) {
          contentRef.value.innerHTML = translatedText;
        } else {
          watch(contentRef, () => {
            if (contentRef.value) {
              contentRef.value.innerHTML = translatedText;
            }
          });
        }
      } else {
        content.value = translatedText;
      }
    };

    // translate now.
    translate();

    // translate when language is changed.
    watch(currentLanguage, translate);

    return {
      contentRef,
      content,
      asHtml,
    };
  },
});
</script>

<style scoped lang="scss">
.html-text {
  :deep(p) {
    margin: 0;
    word-wrap: break-word;
  }
}
</style>
