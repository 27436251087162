import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { BaseModel } from "./BaseModel";
import { ProductModel } from "./ProductModel";
import { IOrderProductModel } from "./interfaces/IOrderProductModel";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { OrderModel } from "./OrderModel";

export class OrderProductModel extends BaseModel implements IOrderProductModel {
  public orderNumber: string;
  public product: ProductModel;
  public order: OrderModel;
  public sellingPrice: number;
  public amountOrdered: number;
  public amountOrderedForm: number; // TODO remove
  public amountDelivered: number;
  public shippingDate?: Date | null;
  public deliveryStatus: DeliveryStatus;
  public isMigrated: boolean;
  public migrationDate?: Date | null;

  constructor() {
    super();

    this.orderNumber = "";
    this.product = new ProductModel();
    this.order = new OrderModel();
    this.sellingPrice = 0;
    this.amountOrdered = 0;
    this.amountOrderedForm = 0;
    this.amountDelivered = 0;
    this.amountDelivered = 0;
    this.shippingDate = null;
    this.deliveryStatus = DeliveryStatus.NONE;
    this.isMigrated = false;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "product",
      cast: (x) => TypeHelper.mappers.ProductModel(x),
    },
    {
      key: "order",
      cast: (x) => TypeHelper.mappers.OrderModel(x),
    },
    {
      key: "shippingDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
  ];
}
