import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9c199c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner pb-0" }
const _hoisted_2 = { class: "step animated" }
const _hoisted_3 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!
  const _directive_animateonscroll = _resolveDirective("animateonscroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, [
        _createElementVNode("em", null, [
          _createVNode(_component_Translate, { text: "STOREFRONT_HOWDOESITWORK_PAGETITLE" })
        ])
      ]),
      _createVNode(_component_Translate, { text: "STOREFRONT_HOWDOESITWORK_PAGECONTENT" })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.howDoesItWorkSteps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "inner steps",
        style: _normalizeStyle({ backgroundColor: step.bgColor, color: step.color })
      }, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(step.icon)
            }, null, 2)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("h2", null, _toDisplayString(step.label), 1),
            _createElementVNode("p", null, _toDisplayString(step.description), 1)
          ])
        ])), [
          [
            _directive_animateonscroll,
            { enterClass: 'fadein-left' },
            void 0,
            { once: true }
          ]
        ])
      ], 4))
    }), 256))
  ], 64))
}