<template>
  <StoreFrontNavigation :isBarebone="true"></StoreFrontNavigation>
  <div class="content">
    <div class="inner">
      <i class="pi pi-exclamation-triangle big"></i>
      <p>
        <strong>{{ pageCouldNotBeLoadedErrorTitle }}</strong>
      </p>
      <p>{{ pageCouldNotBeLoadedErrorDescription }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useTranslationStore } from "@/stores/translationStore";
import { useHead } from "@unhead/vue";
import { Language } from "@/enums/Language";
import StoreFrontNavigation from "@/components/storefront/StoreFrontNavigation.vue";
import { LanguageHelper } from "@/helpers/LanguageHelper";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontErrorPage",
  components: {
    StoreFrontNavigation,
  },
  setup() {
    const translationStore = useTranslationStore();

    // ensure that the language is set.
    LanguageHelper.updateStoreFrontLanguage();

    // Those translations are hardcoded, because they are displayed when the actual translation request fails.
    const pageCouldNotBeLoadedErrorTitle = computed(() => (translationStore.currentLanguage == Language.FR ? "Une erreur s'est produite" : "Ein Fehler ist aufgetreten."));
    const pageCouldNotBeLoadedErrorDescription = computed(() =>
      translationStore.currentLanguage == Language.FR ? "Un problème est survenu. Veuillez réessayer." : "Ein Problem ist aufgetreten. Bitte versuche es noch einmal."
    );

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(translationStore.currentLanguage == Language.FR ? "Erreur" : "Fehler"),
    });

    return {
      pageCouldNotBeLoadedErrorTitle,
      pageCouldNotBeLoadedErrorDescription,
    };
  },
});
</script>

<style scoped lang="scss">
.inner {
  text-align: center;
}
.big {
  font-size: 2rem;
  color: $ff-primary-red;
}
</style>
