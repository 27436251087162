<template>
  <h2><Translate text="STOREFRONT_NEWPASSWORD_TITLE" /></h2>

  <p><Translate text="STOREFRONT_NEWPASSWORD_DESCRIPTION" /></p>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";
import { Translator } from "@/helpers/Translator";

/**
 * The shared login forgot-password component.
 */
export default defineComponent({
  name: "NewPassword",
  components: { Translate },
  methods: {},
  setup() {
    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_NEWPASSWORD_TITLE")),
    });

    return {};
  },
});
</script>

<style scoped lang="scss"></style>
