import { ModelDto } from "./base/ModelDto";
import { CartModel } from "@/models/CartModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";

export class CartDto extends ModelDto<CartModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.CartModel(x),
    },
  ];
}
