import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { UserApiController } from "@/api/UserApiController";
import stringMixins from "@/mixins/stringMixins";
import { UserDto, UserListDto } from "@/dtos/UserDtos";
import { ExportType } from "@/enums/ExportType";
import { OrderListDto } from "@/dtos/OrderDtos";
import { StoreUserApiProxy } from "@/helpers/StoreUserApiProxy";
import { FilterMatchMode } from "primevue/api";
import { FilterableState } from "@/dtos/state/FilterableState";

const apiController = new UserApiController();
const storeApiProxy = new StoreUserApiProxy(apiController);

export const useUserStore = defineStore("user", {
  state: (): FilterableState => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    enumFilter: null,
  }),
  actions: {
    /* -------------------------------------------
     * Get all users from API.
     * -------------------------------------------- */
    async getUsers(listDto: UserListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get all active users from API.
     * -------------------------------------------- */
    async getActiveUsers(listDto: UserListDto): Promise<void> {
      return storeApiProxy.apiGetMultipleActive(listDto);
    },

    /* -------------------------------------------
     * Get single user from API.
     * -------------------------------------------- */
    async getUser(dto: UserDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one user DTO.
     * -------------------------------------------- */
    async updateUser(dto: UserDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new user DTO.
     * -------------------------------------------- */
    async createUser(dto: UserDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing user DTO.
     * -------------------------------------------- */
    async deleteUser(dto: UserDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Get all orders of a user.
     * -------------------------------------------- */
    async getOrdersByUserId(listDto: OrderListDto, userId: number): Promise<void> {
      return storeApiProxy.getOrdersByUserId(listDto, userId);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: UserListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: UserListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: UserListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: UserListDto, page: number): void {
      listDto.page = page;
    },

    setPageSize(listDto: UserListDto, pageSize: number): void {
      listDto.pageSize = pageSize;
    },

    setFilter(listDto: UserListDto, filter: string | null, enumFilter: number | null): void {
      listDto.filter = filter;
      listDto.enumFilter = enumFilter;
    },
  },
});
