<template>
  <div ref="productRef" style="--primary-theme-color: var(--ff-black-stripe-label-70)">
    <div class="inner">
      <div class="product-detail" v-if="!dto.hasError">
        <div class="promo-image">
          <div class="stripes background-stripes"></div>
          <div class="background"></div>

          <Carousel v-if="imageListDto.list.length > 0" ref="productImagesRef" :num-visible="1" :num-scroll="1" :value="imageListDto.list" :show-indicators="false" :show-navigators="false">
            <template #item="slotProps">
              <Image
                :src="slotProps.data.model.imageData"
                :alt="dto.model.fullName()"
                :title="dto.model.fullName()"
                class="animated"
                v-animateonscroll.once="{ enterClass: 'custom-scale-in' }"
                preview
              />
            </template>
          </Carousel>
          <img v-else :src="require('@/assets/img/placeholder.jpg')" class="animated placeholder-image" v-animateonscroll.once="{ enterClass: 'custom-scale-in' }" />

          <div class="tiny-buttons" v-if="dto.model.productImages.length > 1">
            <Button icon="pi pi-angle-left" @click="showPreviousImage" :disabled="currentImageIndex === 0"></Button>
            <div class="pages">
              <div v-for="(_, index) of dto.model.productImages" class="page" :class="{ active: index === currentImageIndex }" @click="showSpecificImage($event, index)"></div>
            </div>
            <Button icon="pi pi-angle-right" @click="showNextImage" :disabled="currentImageIndex === dto.model.productImages.length - 1"></Button>
          </div>
        </div>
        <div class="product-info">
          <h2>
            {{ dto.model.name() }} <em>{{ dto.model.subname() }}</em>
            <LoadingSkeleton v-if="dto.isLoading" height="3rem"></LoadingSkeleton>
          </h2>

          <small><Translate text="STOREFRONT_PRODUCTS_ARTICLE_NUMBER" />: {{ dto.model.articleNumber }}</small>

          <p class="description">
            <div v-html="dto.model.description()"></div>
            <LoadingSkeleton v-if="dto.isLoading" :skeleton-type="SkeletonType.DETAILS"></LoadingSkeleton>
          </p>

          <p v-if="isOutOfStock && !dto.isLoading" class="out-of-stock"><Translate text="STOREFRONT_PRODUCTS_OUT_OF_STOCK_DESCRIPTION" /></p>

          <div class="price-and-actions">
            <ProductPrice :value="dto.model.price"></ProductPrice>

            <FFButton
              :label="Translator.translate(isOutOfStock && !dto.isLoading ? 'STOREFRONT_PRODUCTS_OUT_OF_STOCK' : 'STOREFRONT_PRODUCTS_ADD_TO_CART')"
              @click="addToCart()"
              :loading="dto.isLoading || isAddingToCart"
              :disabled="isOutOfStock"
            ></FFButton>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ Translator.translateFormat("STOREFRONT_PRODUCTS_NOT_EXISTING", id) }}</p>
      </div>
    </div>
    <div class="product-recommendations">
      <div class="inner">
        <h2><Translate text="STOREFRONT_PRODUCTS_CUSTOMERS_ARE_ALSO_INTERESTED_IN" /></h2>
        <div v-if="dto.model.similarProducts.length > 0" class="similar-products">
          <template v-for="similarProduct of dto.model.similarProducts" :key="similarProduct.id">
            <SimilarProductDisplay v-if="similarProduct.stock > 0" :model="similarProduct" :color-theme="dto.model.colorTheme"></SimilarProductDisplay>
          </template>
        </div>
        <p v-else><Translate text="STOREFRONT_PRODUCTS_NO_RECOMMENDATIONS" /></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import { useHead } from "@unhead/vue";
import { useProductStore } from "@/stores/productStore";
import { ProductDto, ProductImageListDto } from "@/dtos/ProductDtos";
import { useRoute } from "vue-router";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import { ProductModel } from "@/models/ProductModel";
import colorMixins from "@/mixins/colorMixins";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useToast } from "primevue/usetoast";
import FFButton from "@/components/storefront/FFButton.vue";
import SimilarProductDisplay from "@/components/storefront/landingpage/products/SimilarProductDisplay.vue";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { SkeletonType } from "@/enums/SkeletonType";
import { CustomToastMessageOptions } from "@/dtos/data/CustomToastMessageOptions";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontProductDetail",
  components: { ProductPrice, LoadingSkeleton, FFButton, SimilarProductDisplay, Translate },
  methods: {
    addToCart() {
      const userStatusStore = useUserStatusStore();

      this.isAddingToCart = true;

      const currentAmount = userStatusStore.getAmountInCart(this.dto.model.id);
      userStatusStore
        .updateProductCartAmount(this.dto.model.id, currentAmount + 1)
        .then(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 3000,
            severity: "success",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_SUCCESS_DESCRIPTION"),
            hasButton: true,
            buttonAction: () => this.$router.push("/cart"),
            buttonLabel: Translator.translate("STOREFRONT_CHECKOUT_TO_CART"),
          });
        })
        .catch(() => {
          this.isAddingToCart = false;
          this.showToast({
            life: 6000,
            severity: "error",
            summary: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_TITLE"),
            detail: Translator.translate("STOREFRONT_MESSAGES_PRODUCT_ADD_TO_CART_FAILED_DESCRIPTION"),
          });
        });
    },
  },
  setup() {
    const productStore = useProductStore();
    const toast = useToast();
    const route = useRoute();

    const formHelper = new FormHelper("/products");

    // validate parameter.
    let id = -1;
    const getIdFromRoute = () =>
      routerMixins.tryGetParam(route.params.id, () => {
        formHelper.returnToList();
      });

    const dto = ref(new ProductDto(new ProductModel()));
    const imageListDto = ref(new ProductImageListDto([]));
    const isAddingToCart = ref(false);

    // set css colors based on selected theme.
    const productRef = ref();
    const productImagesRef = ref();
    const currentImageIndex = ref(0);

    // run DOM-ready operations only once, after component has rendered.
    let hasDOMLoaded = false;
    watchEffect(() => {
      if (productRef.value && dto.value.model.colorTheme && !hasDOMLoaded) {
        const productElement = productRef.value as HTMLElement;
        productElement.style.setProperty("--primary-theme-color", colorMixins.getPackageColorFromTheme(dto.value.model.colorTheme));
        productElement.style.setProperty("--secondary-theme-color", colorMixins.getLabelColorFromTheme(dto.value.model.colorTheme));
        hasDOMLoaded = true;
      }
    });

    // carousel image functions.
    const showNextImage = (event: Event) => {
      productImagesRef.value.navForward(event);
      currentImageIndex.value = Math.min(productImagesRef.value.lastIndex(), currentImageIndex.value + 1);
    };
    const showSpecificImage = (event: Event, index: number) => {
      if (index > currentImageIndex.value) {
        productImagesRef.value.navForward(event, index);
      } else {
        productImagesRef.value.navBackward(event, index);
      }
      currentImageIndex.value = Math.max(productImagesRef.value.firstIndex(), Math.min(productImagesRef.value.lastIndex(), index));
    };
    const showPreviousImage = (event: Event) => {
      productImagesRef.value.navBackward(event);
      currentImageIndex.value = Math.max(productImagesRef.value.firstIndex(), currentImageIndex.value - 1);
    };

    // other exposed functions.
    const showToast = (toastOptions: CustomToastMessageOptions) => {
      toast.add(toastOptions);
    };
    const loadProductAsync = () => {
      id = getIdFromRoute();

      // get entity from store.
      productStore.getActiveProduct(dto.value, id).then(() => {
        // then get all images of that product.
        productStore.getProductImages(dto.value.model.id, imageListDto.value);
      });
    };

    const isOutOfStock = computed(() => dto.value.model.stock <= 0);

    // load product on page load.
    onMounted(loadProductAsync);

    // load product when route changes on same page (clicking on a similar product).
    watch(route, (to) => {
      if (to.name === "StoreFront ProductDetail") {
        loadProductAsync();
      }
    });

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(dto.value.model.fullName()),
    });

    return {
      id,
      dto,
      imageListDto,
      isAddingToCart,
      productRef,
      productImagesRef,
      currentImageIndex,
      isOutOfStock,
      Translator,
      SkeletonType,
      showToast,
      showNextImage,
      showSpecificImage,
      showPreviousImage,
    };
  },
});
</script>

<style lang="scss">
.p-image-mask.p-component-overlay {
  --maskbg: rgba(255, 255, 255, 0.9);
}
.p-image-toolbar .p-image-action {
  color: black;
}
</style>

<style scoped lang="scss">
.product-detail {
  display: flex;
  column-gap: 40px;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    row-gap: 80px;
  }

  .promo-image {
    position: relative;
    width: 30%;
    height: fit-content;
    max-width: 300px;

    @media (max-width: $breakpoint-mobile) {
      max-width: unset;
      width: 100%;
    }

    .placeholder-image,
    .p-carousel {
      width: calc(100% - 60px);
      margin-left: 60px;
      margin-bottom: 60px;
    }

    .p-carousel {
      @media (max-width: $breakpoint-mobile) {
        pointer-events: none;
      }
      
      :deep(.p-image) {
        .p-image-preview-indicator {
          background: none;
        }

        img {
          width: 100%;
          object-fit: contain;
          background-color: white;
          aspect-ratio: 10 / 7;
        }
      }
    }

    .tiny-buttons {
      position: absolute;
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      column-gap: 20px;

      button {
        width: 15px;
        height: 15px;
        padding: 0;
        border: 0;
        color: var(--primary-theme-color);
        background: none;

        @media (max-width: $breakpoint-mobile) {
          width: 25px;
          height: 25px;
        }

        &:focus {
          box-shadow: 0 0 0 2px $ff-primary-white, 0 0 0 4px var(--primary-theme-color);
        }
      }

      .pages {
        display: flex;
        justify-content: center;
        column-gap: 10px;

        @media (max-width: $breakpoint-mobile) {
          column-gap: 20px;
        }

        .page {
          cursor: pointer;
          width: 15px;
          height: 15px;
          background: $ff-primary-white;
          border: 2px solid var(--primary-theme-color);

          @media (max-width: $breakpoint-mobile) {
            width: 25px;
            height: 25px;
          }

          &.active {
            background: var(--primary-theme-color);
          }
        }
      }

      @media (max-width: $breakpoint-mobile) {
        :deep(.p-button-icon-only) {
          span {
            font-size: 1.5rem;
          }
        }
      }
    }

    .background {
      position: absolute;

      width: 100%;
      aspect-ratio: 10 / 7;

      top: unset;
      left: 0px;
      bottom: 0px;
      margin-left: 20px;
      margin-bottom: 20px;
      z-index: -1;
      background-color: $ff-primary-white;

      @media (max-width: $breakpoint-mobile) {
        width: calc(100% - 30px);

        margin-left: 30px;
        margin-bottom: 30px;
      }
    }

    .background-stripes {
      width: 100%;

      background-color: var(--primary-theme-color);

      top: unset;
      left: 0px;
      bottom: 0px;
    }
  }

  .description {
    white-space: normal;
  }

  .product-info {
    width: 70%;

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }

    .price-and-actions {
      width: 100%;
      display: flex;
      column-gap: 40px;

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;

        .primary-button {
          width: 100%;
          text-align: center;
        }
      }

      .primary-button {
        background: var(--primary-theme-color);
      }
    }

    .out-of-stock {
      color: $ff-primary-red;
    }
  }
}

.product-recommendations {
  width: 100%;
  background: var(--primary-theme-color);
  min-height: 600px;
  color: $ff-primary-white;

  .similar-products {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
    padding-top: 40px;

    > * {
      width: calc(25% - 30px);
    }

    @media (max-width: $breakpoint-desktop) {
      > * {
        width: calc(33% - 26.6px);
      }
    }
    @media (max-width: $breakpoint-tablet) {
      > * {
        width: calc(50% - 20px);
      }
    }
    @media (max-width: $breakpoint-mobile) {
      > * {
        width: 100%;
      }
    }
  }
}
</style>
