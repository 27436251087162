import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { BaseModel } from "./BaseModel";
import { ICouponModel } from "./interfaces/ICouponModel";
import { OrderModel } from "./OrderModel";

export class CouponModel extends BaseModel implements ICouponModel {
  public code: string;
  public validFromDate: Date;
  public validToDate: Date;
  public reductionInPacks: number;
  public isActive: boolean;
  public orders: OrderModel[];
  public isMigrated: boolean;
  public migrationId?: number | null;
  public migrationDate?: Date | null;

  constructor() {
    super();

    this.code = "";
    this.validFromDate = new Date();
    this.validToDate = new Date();
    this.reductionInPacks = 0;
    this.isActive = true;
    this.orders = [];
    this.isMigrated = false;
    this.migrationDate = null;
    this.migrationId = null;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "validFromDate",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
    {
      key: "validToDate",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
    {
      key: "migrationDate",
      cast: (x) => TypeHelper.mappers.DateNullable(x),
    },
    {
      key: "orders",
      cast: (x) => x.map((y: OrderModel) => TypeHelper.mappers.OrderModel(y)),
    },
  ];
}
